import React, { createContext, useReducer, useContext, useEffect, useCallback } from 'react';
import { stockModuleVisRequest, stockModuleSearchVisByCapId } from '~/api/stockModule';
import { canLogVehicleData } from '~/features';
import { log } from '~/debug';

const VisLookupContext = createContext(null);

const initialState = (defaultValues) => ({
  page: 1,
  query: '',
  debouncedQuery: '',
  searchState: 'idle',
  searchResults: null,
  selectedVehicleId: null,
  facetFilters: [],
  capIds: [],
  touched: false,
  ...defaultValues
});

export const SET_PAGE = 'SET_PAGE';
export const SET_QUERY = 'SET_QUERY';
export const SET_SEARCH_STATE = 'SET_SEARCH_STATE';
export const SET_SELECTED_VEHICLE_ID = 'SET_SELECTED_VEHICLE_ID';
export const SET_FACET_FILTER = 'REMOVE_FACET_FILTER';
export const LOOKUP_SUCCESS = 'LOOKUP_SUCCESS';
export const SET_CAP_IDS = 'SET_CAP_IDS';

function reducer(state, action) {
  switch (action.type) {
    case SET_PAGE:
      return { ...state, page: action.payload };
    case SET_QUERY:
      return { ...state, query: action.payload, touched: true, page: 1 };
    case SET_SEARCH_STATE:
      return { ...state, searchState: action.payload };
    case SET_SELECTED_VEHICLE_ID:
      return { ...state, selectedVehicleId: action.payload, touched: true };
    case SET_FACET_FILTER:
      return { ...state, facetFilters: action.payload, touched: true };
    case LOOKUP_SUCCESS:
      return { ...state, searchState: 'idle', searchResults: action.payload };
    case SET_CAP_IDS:
      return { ...state, capIds: action.payload.capIds, class: action.payload.class, touched: true };

    default:
      return state;
  }
}

const VisLookUp = ({ children, defaultValues = {}, dealershipId }) => {
  const initialState1 = initialState(defaultValues);
  const [state, dispatch] = useReducer(reducer, initialState1);
  const makeRequest = useCallback(
    async ({ signal }) => {
      dispatch({ type: SET_SEARCH_STATE, payload: 'loading' });
      try {
        const response = state.capIds.length
          ? await stockModuleSearchVisByCapId({ capIds: state.capIds, dealershipId, class: state.class })
          : await stockModuleVisRequest(
              {
                query: state.query,
                pageSize: defaultValues.pageSize || 20,
                page: state.page,
                secondaryTaxonomyIds: state.facetFilters,
                class: state.facetFilters.length ? state.class : '',
                dealershipId
              },
              { signal }
            );

        if (canLogVehicleData()) {
          log({
            event: 'VehicleDebugLog-VisLookUp-VisRequest-Response',
            dealershipId,
            capIds: state.capIds,
            vehicle: response
          });
        }

        dispatch({ type: LOOKUP_SUCCESS, payload: response });
      } catch (e) {
        // we cancel request if we make a subsequent request but don't want to display an error msg.
        if (e.name !== 'AbortError') {
          dispatch({ type: SET_SEARCH_STATE, payload: 'error' });
        }
      }
    },
    [dealershipId, defaultValues.pageSize, state.class, state.facetFilters, state.page, state.query, state.capIds]
  );

  useEffect(() => {
    if (state.facetFilters.length || state.touched) {
      const controller = new AbortController();
      const { signal } = controller;
      makeRequest({ signal });

      return () => {
        controller.abort();
      };
    }
  }, [state.page, state.query, state.facetFilters, makeRequest, state.touched, state.capIds]);

  return <VisLookupContext.Provider value={{ state, dispatch }}>{children}</VisLookupContext.Provider>;
};

export default VisLookUp;

export const useVisLookupContext = () => {
  const visLookupContext = useContext(VisLookupContext);

  if (!visLookupContext) {
    throw new Error('visLookup context must be used inside the <VisLookup /> provider.');
  }

  return visLookupContext;
};
