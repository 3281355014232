import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { inject } from 'mobx-react';
import MediaQuery from 'react-responsive';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import Panel from '~Common/Panel';
import PanelContent from '~Common/PanelContent';
import CollapsiblePanel from '~Common/CollapsiblePanel';
import PanelHeader from '~Common/PanelHeader';
import { CountryCodeToCurrency } from '~/constants';
import { isCountryRHD } from '~/utils/countrySpecifics';
import objectValidation from '~/core/objectValidation';

import { CreateVehicleForm } from './components';
import { getValidationRules } from '../../../../../../shared/helpers/vehicleFormFields';

import {
  StockBreadcrumbs,
  StockContainerLayout,
  StockVehicleSidePanel,
  KeyInformationSection
} from '../../../../../../shared/components';

import './stockCreateContainer.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { isBike, transformVehicleClass } from '~/utils/vehicle';

const getCapData = (capDataResponse, capId, vehicleClass) => {
  if (capDataResponse == null || !Array.isArray(capDataResponse.CapData)) return undefined;
  return capDataResponse.CapData.find(({ CapId, VehicleClass }) => {
    return String(capId) === String(CapId) && vehicleClass.toUpperCase() === VehicleClass.toUpperCase();
  });
};

const isEmptyObject = (obj) => obj && !!Object.keys(obj).length;

const StockCreateContainer = (props) => {
  const [rules, setRules] = useState({});
  const navigate = useNavigate();
  const { dealershipId } = useParams();
  const { t } = useTranslation('Stock');
  const { capDataResponse = {}, vehicleTaxonomyData = {}, miscVehicleData } = props.appStore.stockCreateStore;
  const { isDe, isUk } = props.appStore.uiState;
  const { countryCode } = props.appStore.uiState;

  const capData = getCapData(capDataResponse, vehicleTaxonomyData.SecondaryTaxonomyId, vehicleTaxonomyData.Class) || {};
  const klass = transformVehicleClass(vehicleTaxonomyData.Class);
  const isMotorcycle = isBike(klass);

  const methods = useForm({
    defaultValues: {
      condition: miscVehicleData.condition,
      class: vehicleTaxonomyData.Class ? klass : 'car', // When creating a manual record, other fields depend this to be set to something
      vrm: isEmptyObject(capData)
        ? capDataResponse.Vrm || miscVehicleData.lookupTerm
        : isDe
        ? ''
        : miscVehicleData.lookupTerm,
      vin: isEmptyObject(capData)
        ? capDataResponse.Vin || miscVehicleData.lookupTerm
        : isDe
        ? miscVehicleData.lookupTerm
        : '',
      regDate: capData.Registered ? moment(capData.Registered).format('DD/MM/YYYY') : '',
      make: vehicleTaxonomyData.Make || '',
      model: vehicleTaxonomyData.Model || '',
      derivative: vehicleTaxonomyData.Derivative || '',
      bodyStyle: vehicleTaxonomyData.BodyStyle || '',
      mileage: '',
      mileageUnit: isUk ? 'MILE' : 'KM',
      doors: vehicleTaxonomyData.Doors ? String(vehicleTaxonomyData.Doors) : '',
      seats: isMotorcycle ? 1 : vehicleTaxonomyData.NoofSeats || '',
      keepers: '',
      price: '',
      dealerReference: '',
      //   TODO: business decision needed: electric motorcycles are a thing now
      fuelType: isMotorcycle ? 'Petrol' : vehicleTaxonomyData.FuelType || '',
      //   TODO: business decision needed: automatic motorcycles are a thing too
      transmission: isMotorcycle ? 'Manual' : vehicleTaxonomyData.Transmission || '',
      vatStatus: 'VAT inc',
      description: '',
      colour: '',
      attentionGrabber: '',
      options: '',
      cc: vehicleTaxonomyData.CC || '',
      bhp: vehicleTaxonomyData.EnginePowerBHP || '',
      currency: CountryCodeToCurrency[countryCode],
      drivingPosition: isCountryRHD(countryCode),
      dealershipId: dealershipId,
      primaryId: vehicleTaxonomyData.PrimaryTaxonomyId,
      secondaryId: vehicleTaxonomyData.SecondaryTaxonomyId
    },
    // validation happens on change and on submit
    mode: 'all',
    resolver: async (data) => {
      const errors = objectValidation(data, rules);

      return !errors ? { values: data, errors: {} } : { errors };
    }
  });

  const Klass = methods.watch('class');
  const primaryId = methods.watch('primaryId');
  const secondaryId = methods.watch('secondaryId');

  //   Update validation rules on class form field change
  React.useEffect(() => {
    const hasTaxonomyData = primaryId && secondaryId;
    const rules = getValidationRules({ vehicleClass: Klass, hasTaxonomyData, isDealerPlatformUpload: true });
    setRules(rules);
  }, [Klass, primaryId, secondaryId]);

  const handleChangeVehicleClick = () => {
    navigate(`d/${dealershipId}/stock/add/refine`);
  };
  const showDisabledFieldsPanel = !!vehicleTaxonomyData.Class;
  return (
    <StockContainerLayout>
      {/* StockContainerLayout expects an array of children (?)
         or at least an array like object.
      */}
      {[
        <FormProvider {...methods} key={0}>
          <StockBreadcrumbs
            items={[
              {
                name: t('Common.stock_list'),
                path: `/d/${dealershipId}/stock/list`
              },
              {
                name: t('Common.create_vehicle')
              }
            ]}
          />
          <div className="stockCreateContainer__wrapper">
            <MediaQuery minWidth="920px">
              <div className="stockCreateContainer__col0">
                <Panel className="stockCreateContainer__panel">
                  <StockVehicleSidePanel />
                </Panel>
              </div>
            </MediaQuery>
            <div className="stockCreateContainer__col1">
              <MediaQuery maxWidth="919px">
                <CollapsiblePanel id="vehiclePreview" title="Preview" showContent>
                  <StockVehicleSidePanel />
                </CollapsiblePanel>
              </MediaQuery>
              <Panel className="stockCreateContainer__panel">
                <PanelHeader>{t('StockEditVehicleDetails.vehicle_summary')}</PanelHeader>
                <PanelContent>
                  {showDisabledFieldsPanel && <KeyInformationSection onClick={handleChangeVehicleClick} />}
                  <CreateVehicleForm vehicleTaxonomyData={vehicleTaxonomyData} />
                </PanelContent>
              </Panel>
            </div>
          </div>
        </FormProvider>
      ]}
    </StockContainerLayout>
  );
};

export default inject('appStore')(StockCreateContainer);
