import { useTranslation } from 'react-i18next';
import {
  EligibilityOffered,
  QuoteCardVariantType,
  QuoteCardVariantTypeEnum,
  QuoteTypes,
  QuoteVariants
} from '~/components/QuoteCard/types';
import Button from '~/components/Common/Button/Button';
import DisabledButtonWithMessage from '~/components/Common/DisabledButtonWithMessage';
import { calculateCurrentApplicationNumber } from '~/core/helpers';
import { hasExtendedTermLength } from '~/utils/quoteUtils';
import { ProceedingStateType } from '~/redux/quote/types';
import { useParams } from 'react-router-dom';
import { QuoteCardConfig } from '../config/default';

const QuoteButton = ({
  variant,
  quote,
  canViewConsumerApplications,
  isCombinedQuote,
  isQuickQuote,
  vehicleClass,
  financeCheck,
  isCustomerQuote,
  canCreateApplications,
  canSeeLenderPanelLink,
  chosenQuoteId,
  consumerId,
  vehicleId,
  quoteId,
  proceedingState,
  multiQuoteRefId,
  financeType,
  financeApplications,
  canGenerateQuotes,
  lenderStatus,
  supportsPreApproval,
  isFnEligibilityCheckAvailable,
  eligibilityOffered,
  maxMonthlyBudget,
  config,
  handleProceed,
  handleSave,
  handleView,
  handleEdit,
  handleRequote
}: QuoteButtonProps) => {
  const { t } = useTranslation('QuoteCard');
  const { dealershipId } = useParams();
  const loadingState = chosenQuoteId === quoteId ? (proceedingState as ProceedingStateType) : '';

  if (variant === QuoteCardVariantTypeEnum.FinanceQuote || variant === QuoteCardVariantTypeEnum.Requote) {
    const isQuickQuoteAndExtendedTermVehicle = isQuickQuote && hasExtendedTermLength(vehicleClass);
    const canSeeLenderPanelBtn = quote && canSeeLenderPanelLink(quote.FunderCode);

    let primaryButtonText;
    if (supportsPreApproval && variant === QuoteCardVariantTypeEnum.FinanceQuote) {
      primaryButtonText = t('QuoteCardButtons.get_pre_approval');
    } else if (
      supportsPreApproval &&
      variant === QuoteCardVariantTypeEnum.Requote &&
      (isQuickQuote || isCustomerQuote || isQuickQuoteAndExtendedTermVehicle)
    ) {
      primaryButtonText = t('QuoteCardButtons.get_pre_approval');
    } else if (isFnEligibilityCheckAvailable) {
      primaryButtonText = t('QuoteCardButtons.check_eligibility');
    } else {
      primaryButtonText = t('QuoteCardButtons.proceed_to_application');
    }

    return (
      <>
        {canSeeLenderPanelBtn && (
          <Button
            isLoading={loadingState === 'loading'}
            hasError={loadingState === 'error'}
            href="https://www.ivendi.com/lenderbenefits"
            target="_blank"
            className="button--anchor"
            buttonStyle="primary"
            dataThook="quote-card-external-link"
          >
            {t('QuoteCardButtons.add_lender_to_panel')}
          </Button>
        )}

        {canCreateApplications && !canSeeLenderPanelBtn && (
          <Button
            isLoading={loadingState === 'loading'}
            hasError={loadingState === 'error'}
            onClick={handleProceed}
            buttonStyle="primary"
            dataThook="quote-card-button-proceed"
          >
            {primaryButtonText}
          </Button>
        )}

        {(isQuickQuote || isCustomerQuote) && !isCombinedQuote && !isQuickQuoteAndExtendedTermVehicle && (
          <Button
            isLoading={loadingState === 'loading'}
            hasError={loadingState === 'error'}
            onClick={handleSave}
            buttonStyle="secondary"
            dataThook="quote-card-button-save"
          >
            {t('QuoteCardButtons.save_quote')}
          </Button>
        )}
      </>
    );
  } else if (variant === QuoteCardVariantTypeEnum.FinanceNavigatorCheck) {
    return (
      <>
        {canCreateApplications && eligibilityOffered !== EligibilityOffered.UnableToAssist && (
          <Button
            isLoading={loadingState === 'loading'}
            hasError={loadingState === 'error'}
            onClick={handleProceed}
            buttonStyle="primary"
            dataThook="quote-card-button-proceed"
          >
            {t('QuoteCardButtons.proceed_to_application')}
          </Button>
        )}
        {eligibilityOffered === EligibilityOffered.UnableToAssist && handleRequote && (
          <Button
            isLoading={loadingState === 'loading'}
            hasError={loadingState === 'error'}
            onClick={handleRequote}
            buttonStyle="primary"
            dataThook="quote-card-button-requote"
          >
            {t('QuoteCardButtons.re_quote')}
          </Button>
        )}
      </>
    );
  } else if (variant === QuoteCardVariantTypeEnum.SavedFinanceNavigatorCheck) {
    const isUnableToAssist = eligibilityOffered === EligibilityOffered.UnableToAssist;
    const reQuoteText = isUnableToAssist ? t('QuoteCardButtons.try_other_lenders') : t('QuoteCardButtons.edit_quote');
    const canProceedWithApplication = !config.showExpired && !isUnableToAssist;
    const canReQuote = !canProceedWithApplication || maxMonthlyBudget !== undefined;
    const reQuoteBtnStyle = canProceedWithApplication ? 'secondary' : 'primary';
    return (
      <>
        {canCreateApplications && canProceedWithApplication && (
          <Button
            isLoading={proceedingState === 'loading'}
            hasError={proceedingState === 'error'}
            onClick={handleProceed}
            buttonStyle="primary"
            dataThook="quote-card-button-proceed"
          >
            {t('QuoteCardButtons.proceed_to_application')}
          </Button>
        )}
        {canReQuote && handleRequote !== undefined && (
          <Button
            isLoading={proceedingState === 'loading'}
            hasError={proceedingState === 'error'}
            onClick={handleRequote}
            buttonStyle={reQuoteBtnStyle}
            dataThook="quote-card-button-requote"
          >
            {reQuoteText}
          </Button>
        )}
      </>
    );
  } else if (variant === QuoteCardVariantTypeEnum.FinanceApplication && canViewConsumerApplications) {
    if (multiQuoteRefId) {
      if (financeType === 'FS' && calculateCurrentApplicationNumber(multiQuoteRefId, financeApplications) === 1) {
        return (
          <DisabledButtonWithMessage
            message={t('QuoteCardButtons.you_must_submit_the_relevant_vehicle_loan_before_any_additional_loans')}
            dataTestId="quote-card-button-view"
          >
            {t('QuoteCardButtons.view_application')}
          </DisabledButtonWithMessage>
        );
      } else {
        return (
          <Button
            onClick={() => {
              handleView && handleView(true);
            }}
            buttonStyle="primary"
            dataThook="quote-card-button-view"
          >
            {t('QuoteCardButtons.view_application')}
          </Button>
        );
      }
    } else {
      const canEditApplications = canGenerateQuotes && canCreateApplications;
      const isLenderStatusNotSubmitted = lenderStatus === 'Not Submitted';
      const canEditQuote = canEditApplications && isLenderStatusNotSubmitted;

      return (
        <>
          <Button onClick={handleView} buttonStyle="primary" dataThook="quote-card-button-view">
            {t('QuoteCardButtons.view_application')}
          </Button>

          <Button
            onClick={canEditQuote ? handleEdit : undefined}
            buttonStyle="secondary"
            dataThook="quote-card-button-edit"
            disabled={!canEditQuote}
          >
            {t('QuoteCardButtons.edit_quote')}
          </Button>
        </>
      );
    }
  } else if (variant === QuoteCardVariantTypeEnum.CarFinanceCheck && canViewConsumerApplications) {
    return financeCheck?.Viewable ? (
      <Button onClick={handleView} dataThook="quote-card-button-view">
        {t('QuoteCardButtons.view')}
      </Button>
    ) : (
      <DisabledButtonWithMessage
        message={t(
          'QuoteCardButtons.to_comply_with_i_vendis_data_retention_policy_under_gdpr_old_application_data_and_eligibility_check_detail_is_only_available_for_paid_out_applications'
        )}
        dataTestId="quote-card-button-view"
      >
        {t('QuoteCardButtons.view')}
      </DisabledButtonWithMessage>
    );
  } else if (variant === QuoteCardVariantTypeEnum.SavedQuote) {
    return (
      <Button onClick={handleView} dataThook="quote-card-button-view">
        {t('QuoteCardButtons.view_quote')}
      </Button>
    );

    // TODO: Get the below params from URL PARAMS
  } else if (
    variant === QuoteCardVariantTypeEnum.SelfServiceDeals &&
    dealershipId &&
    consumerId &&
    vehicleId &&
    quoteId
  ) {
    return (
      <Button
        to={`/d/${dealershipId}/consumers/${consumerId}/vehicle/${vehicleId}/selfservicequote/${quoteId}`}
        type="button"
      >
        {t('QuoteCardButtons.view')}
      </Button>
    );
  } else {
    return null;
  }
};

export type QuoteButtonProps = {
  variant: QuoteCardVariantType;
  quote?: QuoteTypes;
  canViewConsumerApplications: boolean;
  financeCheck?: any;
  isCombinedQuote: boolean;
  isQuickQuote: boolean;
  vehicleClass: string;
  isCustomerQuote: boolean;
  canCreateApplications: boolean;
  chosenQuoteId: string;
  consumerId?: string;
  vehicleId?: string;
  quoteId: string;
  proceedingState: string;
  multiQuoteRefId?: string;
  financeType?: string;
  financeApplications?: any;
  canGenerateQuotes?: boolean;
  lenderStatus?: string;
  supportsPreApproval?: boolean;
  isFnEligibilityCheckAvailable?: boolean;
  eligibilityOffered?: EligibilityOffered;
  maxMonthlyBudget?: number;
  canSeeLenderPanelLink: (funderCode: string | undefined) => boolean;
  config: QuoteCardConfig;
  handleProceed?: () => void;
  handleSave?: () => void;
  handleView?: (...args: any) => void;
  handleEdit?: () => void;
  handleEligibilityDetails?: () => void;
  handleRequote?: () => void;
};

export default QuoteButton;
