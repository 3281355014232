import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PasswordStrength from '../Common/PasswordStrength';
import Button from '../Common/Button/Button';
import Alert from '../Common/Alert';
import MobxForm from '../MobxForm/MobxForm';
import MobxFormFieldGroup from '../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../Common/Form/TextInputWrappingInput';
import './resetPassword.scss';
import { observable, action, reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import Validator from '../../validators/Validator';
import { compose } from 'redux';
import { withNavigate, withQuery } from 'hocs/router';

class ResetPassword extends React.Component {
  static propTypes = {
    query: PropTypes.object,
    appStore: PropTypes.object,
    authStore: PropTypes.object
  };
  @observable
  formData = {
    newPassword: '',
    confirmPassword: ''
  };

  constructor() {
    super();
    const validationRules = {
      newPassword: 'required, minlength:12, passwordInputValidator',
      confirmPassword: 'required, equals:newPassword'
    };
    this.setUpValidation(validationRules);
  }

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.formData }), this.validator.validate, {
      fireImmediately: true
    });
  }

  UNSAFE_componentWillMount() {
    const {
      appStore: { authStore }
    } = this.props;
    authStore.checkPasswordResetGuid(this.props.query.resetGuid);
  }

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  handleSubmit = () => {
    const {
      appStore: { authStore }
    } = this.props;

    if (!this.validator.errorCount) {
      authStore.resetPassword(this.props.query.resetGuid, this.formData.newPassword).then(this.onSubmitSuccess);
    }
  };
  onSubmitSuccess = () => {
    this.props.navigate('/auth/passwordchangesuccess');
  };
  @action
  setNewPassword = (newPassword) => {
    this.formData.newPassword = newPassword;
  };
  @action
  setConfirmPassword = (confirmPassword) => {
    this.formData.confirmPassword = confirmPassword;
  };

  render() {
    const errors = this.validator.getErrors();
    const {
      appStore: { authStore }
    } = this.props;

    if (authStore.checkPasswordResetGuidRequest.isLoading) {
      return <div />;
    }

    return (
      <MobxForm onSubmit={this.handleSubmit} focusOnFirstElement>
        <div className="resetPassword">
          {authStore.checkPasswordResetGuidRequest.hasError ? (
            <div>
              <h1 className="resetPassword__header">{this.props.t('ResetPassword.link_has_expired')}</h1>
              <p key="text1" className="resetPassword__text">
                {this.props.t('ResetPassword.this_link_has_expired_and_is_no_longer_valid')}
              </p>
              <p key="text2" className="resetPassword__text">
                {this.props.t('ResetPassword.please_request_a')}{' '}
                <Link to={'/auth/forgotpassword'}>{this.props.t('ResetPassword.new_password')}</Link>
              </p>
            </div>
          ) : (
            <div>
              <h1 className="resetPassword__header">{this.props.t('ResetPassword.reset_my_password')}</h1>
              <p key="text1" className="resetPassword__text">
                {this.props.t('ResetPassword.strong_password_is_important_to_protect_account')}
              </p>
              <p key="text2" className="resetPassword__text">
                {this.props.t('ResetPassword.strong_password_criteria_help_text')}
                {this.props.t('ResetPassword.the_following_special_characters_are_allowed')}
              </p>

              <MobxFormFieldGroup className="resetPassword__passwordField" error={errors.newPassword}>
                <MobxFormLabel htmlFor="newPassword">{this.props.t('ResetPassword.new_password')}</MobxFormLabel>
                <TextInputWrappingInput
                  id="newPassword"
                  value={this.formData.newPassword}
                  onChange={this.setNewPassword}
                  type="password"
                />
              </MobxFormFieldGroup>

              <div className="resetPassword__passwordStrength">
                <p className="resetPassword__passwordStrengthText">{this.props.t('ResetPassword.strength')}</p>
                <PasswordStrength password={this.formData.newPassword} isInvalid={errors.newPassword} />
              </div>

              <MobxFormFieldGroup className="resetPassword__passwordField" error={errors.confirmPassword}>
                <MobxFormLabel htmlFor="confirmPassword">
                  {this.props.t('ResetPassword.confirm_password')}
                </MobxFormLabel>
                <TextInputWrappingInput
                  id="confirmPassword"
                  value={this.formData.confirmPassword}
                  onChange={this.setConfirmPassword}
                  type="password"
                />
              </MobxFormFieldGroup>

              {authStore.resetPasswordRequest.hasError ? (
                <Alert>{this.props.t('ResetPassword.there_has_been_an_error_with_your_request')}</Alert>
              ) : (
                ''
              )}

              <div className="resetPassword__actions">
                <Button
                  isLoading={authStore.resetPasswordRequest.isLoading}
                  hasError={authStore.resetPasswordRequest.hasError}
                  type="submit"
                >
                  {this.props.t('ResetPassword.change_password')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </MobxForm>
    );
  }
}

export default compose(
  withNavigate,
  withQuery,
  withTranslation('ForgotPassword'),
  inject(['appStore']),
  observer
)(ResetPassword);
