import { withTranslation } from 'react-i18next';
// @ts-ignore
import { trackSelfDescribingEvent } from '@ivendi/snowplow-js';
import _ from 'lodash';
import { action, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import { QuoteRequest } from '../../../../core/quoteRequest';
import * as debug from '../../../../debug';
import Validator from '../../../../validators/Validator';
import AttentionPanel from '../../../Common/AttentionPanel';
import Button from '../../../Common/Button/Button';
import Checkbox from '../../../Common/Form/Checkbox';
import CheckboxLabel from '../../../Common/Form/CheckboxLabel';
import CurrencyInput from '../../../Common/Form/CurrencyInput';
import Input from '~/components/Common/Form/Input';
import FieldToggle from '../../../Common/Form/FieldToggle';
import FormFooter from '../../../Common/Form/FormFooter';
import PercentageInput from '../../../Common/Form/PercentageInput';
import RadioButtonGroup from '../../../Common/Form/RadioButtonGroup';
import SelectInput from '../../../Common/Form/SelectInput';
import Divider from '../../../Common/QuoteCostsFormDivider';
import MobxButtonFieldGroup from '../../../MobxForm/MobxButtonFieldGroup';
import MobxFieldGroup from '../../../MobxForm/MobxFieldGroup';
import MobxForm from '../../../MobxForm/MobxForm';
import MobxFormLabel from '../../../MobxForm/MobxFormLabel';
import SettlementModal from '../../modals/SettlementModal.js';
import AddVapModal from '../AddVapModal';
import EditVapModal from '../EditVapModal';
import ValueAddedProduct from '../ValueAddedProduct';
import { isItBike } from '../../../../core/helpers';
import { connect, Dispatch } from 'react-redux';
import BDKLenderVaps from '../LenderVaps';
import ImageButton from '~Common/ImageButton';
import CustomerCreditModal from '../../modals/CustomerCreditModal';
import { canSeeCashback, canSeePartExchangeAndSettlementInDE } from 'features';
import AlertCard from 'components/Common/AlertCard/AlertCard';
import DateInput from '../../../Common/Form/DateInput';
import {
  formatToNumDecimalPlaces,
  generateDefaultQuoteRequestValues,
  shouldShowCoverVatWarning,
  shouldShowNegativeEquityWarning
} from '../../components/utils';
import './quoteCostsForm.scss';
import { hasExtendedTermLength } from '~/utils/quoteUtils';
import * as quoteActions from '../../../../redux/quote/quoteActions';
import * as quickQuoteActions from '~/redux/quickQuote/quickQuoteActions.js';
import { FixLaterQuoteVehicle, VehicleClass, VehicleClassJs, VehicleCondition } from '~/types/vehicle';
import { CustomerType } from '~/api/contentService/utils';
import { AppStore } from '../../../../modules/Stock/types/Types';
import { ValueAddedProductType } from '../../../QuoteCard/types';
import { FixLater } from '~/types/basic';

interface QuoteCostsFormProps {
  vehicle: FixLaterQuoteVehicle;
  customerType: CustomerType;
  defaultValues: Record<string, any>;
  appStore: AppStore;
  vehicleClass: VehicleClass;
  vehicleCondition: VehicleCondition;
  customerId: string;
  onSubmit: (quoteObject: SubmitQuoteType) => void;
  onCancel: () => void;
  // isLoading unknown proper type
  isLoading: boolean | 'error';
  quotesForCompare: FixLater[];
  isQuickQuote: boolean;
  dealershipId: string;
  optionsList: any;

  quote: FixLater;
  changeQuickQuoteVehicle: (vehicle: FixLaterQuoteVehicle) => void;
  t: (translationKey: string) => string;
  checkingEligibilityState: 'error' | 'loading';
}

interface SubmitQuoteType {
  isBnpp: boolean;
  CountryCode: string;
  VehiclePrice: number;
  ActualCashDeposit: number;
  Advance: number;
  BasicPrice: number; // isLcv || VatQualifying
  TotalPrice: number;
  ResidualValue: number;
  Cashback: number;
  CashDeposit: number;
  PartExchange: string;
  OutstandingSettlement: string;
  SettlementSource: string | 'MOT';
  Term: number;
  AnnualDistance: number;
  VatAmount: number;
  VatQualifying: boolean;
  VatPercent: number;
  VatAddedToDeposit: number;
  Accessories: number;
  Insurance: number;
  Warranty: number;
  Other: number;
  NonVatableItems: number;
  BalanceToChange: number;
  NetDeposit: number;
  ValueAddedProducts: ValueAddedProductType[];
  LenderVaps: ValueAddedProductType[];
  CustomerCreditScore?: string;
}
type CurrencyInputReturnType = string | number;

interface QuoteCostsFormState {
  showAccessoriesFields: boolean;
  customerType: CustomerType;
  indexOfVapToEdit: number | null;
  addVapModalOpen: boolean;
  isEditVapModalOpen: boolean;
  settlementModalOpen: boolean;
  customerCreditModalOpen: boolean;
  hasLookedupSettlement: boolean;
  optionsList: Record<string, any>;
  vehicle: FixLaterQuoteVehicle;
}

class QuoteCostsForm extends React.Component<QuoteCostsFormProps, QuoteCostsFormState> {
  // TS is unaware of initialization of quoteRequest object.
  quoteRequest: any = null;
  withoutVapsCreation: boolean = false;
  validationRules: Record<string, any>;
  validator: Validator | null = null;
  // @ts-ignore - Property 'validationReactionDisposer' has no initializer and is not definitely assigned in the constructor.
  validationReactionDisposer: () => void;

  constructor(props: QuoteCostsFormProps) {
    super(props);
    this.state = {
      showAccessoriesFields: false,
      customerType: props.defaultValues.CustomerType || this.props.customerType,
      indexOfVapToEdit: null,
      addVapModalOpen: false,
      isEditVapModalOpen: false,
      settlementModalOpen: false,
      customerCreditModalOpen: false,
      hasLookedupSettlement: false,
      optionsList: {},
      vehicle: {
        ...this.props.vehicle,
        Vin: props.vehicle.Vin,
        Class: props.vehicle.Class,
        Condition: props.vehicle.Condition,
        Mileage: props.vehicle.Mileage,
        RegistrationDate: props.vehicle.RegistrationDate,
        VehicleId: props.vehicle.VehicleId
      }
    };
    this.withoutVapsCreation = false;
    this.validationRules = {
      VehiclePrice: 'required, currency',
      BasicPrice: 'required, currency',
      VatPercent: 'currency',
      VatAmount: 'currency',
      NonVatableItems: 'currency',
      Accessories: 'currency',
      Insurance: 'currency',
      Warranty: 'currency',
      Other: 'currency',
      CashDeposit: 'required, currency',
      ResidualValue: 'currency',
      Cashback: 'currency',
      PartExchange: 'currency',
      OutstandingSettlement: 'currency',
      AnnualDistance: 'required',
      Term: 'required',
      ValueAddedProducts: [
        {
          ProductTypeCode: 'required',
          Id: 'required',
          Price: 'required, currency'
        }
      ],
      Vehicle: {
        Mileage:
          this.state.vehicle.Class !== VehicleClassJs.touringCaravan
            ? 'required:ifoneof:Condition:["used"]'
            : 'optional',
        RegistrationDate: 'required:ifoneof:Condition:["used"], date'
      }
    };
    this.initQuoteRequest();
    this.setUpValidation();
  }

  UNSAFE_componentWillMount() {
    this.setState({
      hasLookedupSettlement: this.quoteRequest.SettlementSource && this.quoteRequest.SettlementSource.includes('MNF')
    });
  }

  setCustomerType = (_: string, customerType: CustomerType) => {
    if (customerType.toLowerCase() === 'corporate') {
      this.quoteRequest.set('CustomerCreditScore', '');
    }
    this.quoteRequest.set('CustomerType', customerType?.toLowerCase());

    this.setState(
      {
        customerType: customerType
      },
      () => {
        this.setUpValidation();
      }
    );
  };

  @action
  setVehicleField = (fieldName: string, value: string) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          vehicle: {
            ...prevState.vehicle,
            [fieldName]: value,
            ...(fieldName === 'Condition' && value === 'new' ? { Mileage: undefined } : {})
          }
        };
      },
      () => {
        const formData = { ...this.quoteRequest.toObject(), Vehicle: { ...this.state.vehicle } };
        // @ts-ignore - this.validator is possibly null
        this.validator.validate(formData);
        this.props.changeQuickQuoteVehicle({
          ...this.state.vehicle
        });
      }
    );
  };

  setBasicPrice = (BasicPrice: CurrencyInputReturnType) => this.quoteRequest.set('BasicPrice', BasicPrice);
  setVehiclePrice = (VehiclePrice: CurrencyInputReturnType) => {
    this.quoteRequest.set('VehiclePrice', VehiclePrice);
    // @ts-ignore - FixLater. are all CurrencyInputReturnType string, or setVehicleField should accept number
    this.setVehicleField('Price', VehiclePrice);
  };
  setVatPercent = (_: string, VatPercent: string) => this.quoteRequest.set('VatPercent', VatPercent);
  setVatAmount = (VatAmount: CurrencyInputReturnType) => this.quoteRequest.set('VatAmount', VatAmount);
  setVatAddedToDeposit = (VatAddedToDeposit: boolean) => this.quoteRequest.set('VatAddedToDeposit', VatAddedToDeposit);
  setNonVatableItems = (NonVatableItems: CurrencyInputReturnType) =>
    this.quoteRequest.set('NonVatableItems', NonVatableItems);
  setInsurance = (Insurance: CurrencyInputReturnType) => this.quoteRequest.set('Insurance', Insurance);
  setWarranty = (Warranty: CurrencyInputReturnType) => this.quoteRequest.set('Warranty', Warranty);
  setOther = (Other: CurrencyInputReturnType) => this.quoteRequest.set('Other', Other);
  setCashDeposit = (CashDeposit: CurrencyInputReturnType) => this.quoteRequest.set('CashDeposit', CashDeposit);
  setResidualValue = (ResidualValue: CurrencyInputReturnType) => this.quoteRequest.set('ResidualValue', ResidualValue);
  setPartExchange = (PartExchange: CurrencyInputReturnType) => this.quoteRequest.set('PartExchange', PartExchange);
  setOutstandingSettlement = (OutstandingSettlement: number | string, SettlementSource?: string) => {
    this.quoteRequest.set('OutstandingSettlement', OutstandingSettlement);
    this.quoteRequest.set('SettlementSource', SettlementSource);
  };
  setCashback = (Cashback: number) => this.quoteRequest.set('Cashback', Cashback);

  setAnnualDistance = (_: string, AnnualDistance: number) => this.quoteRequest.set('AnnualDistance', AnnualDistance);
  setTerm = (_: string, Term: number) => this.quoteRequest.set('Term', Term);
  setCreditScore = (_: string, CreditScore: number) => {
    this.quoteRequest.set('CustomerCreditScore', CreditScore);
  };
  setVatQualifying = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isVatQualifying = value === 'VatQualifying';
    this.quoteRequest.set('VatQualifying', isVatQualifying);
  };

  setLenderVaps = (_: string | null, value: string | number) => {
    this.quoteRequest.set('LenderVaps', value);
  };

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  setUpValidation() {
    let validationRules = this.validationRules;
    const formattedCustomerType = this.state.customerType?.toLowerCase();

    if (this.props.appStore.uiState.canUseRbp && formattedCustomerType !== 'corporate') {
      Object.assign(
        validationRules,
        this.props.appStore.uiState.canUseRbp && {
          CustomerCreditScore: 'required'
        }
      );
    } else if (this.props.appStore.uiState.canUseRbp && formattedCustomerType === 'corporate') {
      validationRules = _.omit(this.validationRules, ['CustomerCreditScore']);
    }

    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(
      () => ({ ...this.quoteRequest.toObject(), Vehicle: { ...this.state.vehicle } }),
      this.validator.validate,
      {
        fireImmediately: true
      }
    );
  }

  @action
  initQuoteRequest = () => {
    const { vehicle, defaultValues, vehicleClass, vehicleCondition } = this.props;
    const defaultQuoteRequestValues = generateDefaultQuoteRequestValues({
      defaultValues,
      countryCode: this.props.appStore.uiState.countryCode,
      vehicleClass,
      vehicleCondition,
      vehicle,
      isBnpp: this.props.appStore.uiState.isBnpp
    });

    this.withoutVapsCreation =
      this.props.quote && this.props.quote.FunderCode === 'CRE' && !this.props.quote.ValueAddedProducts.length;
    this.quoteRequest = new QuoteRequest(defaultQuoteRequestValues);

    // These two price setters seem to be required to trigger a re-calculation
    // and remove validation errors. They are already called in the generateDefaultQuoteRequestValues()
    // but removing them from after the quoteRequest is initialized, seems to cause validation errors.
    if (this.props.vehicle.Price && this.props.vehicleClass?.toLowerCase() === 'lcv') {
      this.quoteRequest.set('BasicPrice', this.props.vehicle.Price);
    } else if (this.props.vehicle.Price) {
      // Coming from the stock page
      this.quoteRequest.set('VehiclePrice', this.props.vehicle.Price);
    }
  };

  toggleField(field: 'showAccessoriesFields') {
    this.setState({
      [field]: !this.state[field]
    });
  }

  handleSubmit = () => {
    this.props.changeQuickQuoteVehicle(this.state.vehicle);

    // @ts-ignore - this.validator is possibly null
    if (!this.validator.errorCount) {
      this.quoteRequest.ValueAddedProducts.forEach((vap: ValueAddedProductType) => {
        if (vap.TaxTypeCode < 1 || vap.TaxTypeCode === undefined) {
          debug.error(
            'taxTypeZero ' +
              JSON.stringify({
                productTypes: this.props.appStore.vapStore.visibleProducts,
                vap: vap
              })
          );
        }
      });

      const quoteObject = this.quoteRequest.toObject();

      this.props.onSubmit(
        Object.assign({}, quoteObject, {
          CustomerType: this.state.customerType?.toLowerCase()
        })
      );
      trackSelfDescribingEvent(
        'QuoteCostsFormSubmit',
        'ClickGenerateQuotesButton',
        JSON.stringify({
          vehicleClass: this.props.vehicleClass.toLowerCase(),
          VatAddedToDeposit: this.quoteRequest.VatAddedToDeposit ? 'true' : 'false'
        })
      );
    } else {
      const formData = { ...this.quoteRequest.toObject(), Vehicle: { ...this.state.vehicle } };
      // @ts-ignore - this.validator is possibly null
      this.validator.validate(formData);
    }
  };

  addVap = () => {
    this.setState({
      addVapModalOpen: true
    });
  };

  editVap = (indexOfVapToEdit: number) => {
    this.setState({
      isEditVapModalOpen: true,
      indexOfVapToEdit: indexOfVapToEdit
    });
  };

  closeAddVapModal = () => {
    this.setState({
      addVapModalOpen: false,
      isEditVapModalOpen: false,
      indexOfVapToEdit: null
    });
  };

  handleSubmitAddVapModal = (
    productId: string,
    price: number,
    productTypeCode: string,
    shouldRemoveExistingDuplicate: boolean
  ) => {
    const product = this.props.appStore.vapStore.visibleProducts.find((v) => v.Id.toString() === productId);

    if (shouldRemoveExistingDuplicate) {
      let toRemove = _.find(this.quoteRequest.ValueAddedProducts, (vap) => vap.ProductTypeCode === productTypeCode);

      this.quoteRequest.removeVap(_.indexOf(this.quoteRequest.ValueAddedProducts, toRemove));
    }

    this.quoteRequest.addVap({ ...product, Price: price });
    this.closeAddVapModal();
  };

  handleSubmitEditVapModal = (
    productId: string,
    price: number,
    productTypeCode: string,
    shouldRemoveExistingDuplicate: boolean
  ) => {
    const product = this.props.appStore.vapStore.visibleProducts.find((v) => v.Id.toString() === productId);

    if (shouldRemoveExistingDuplicate) {
      let toRemove = _.find(this.quoteRequest.ValueAddedProducts, (vap) => vap.ProductTypeCode === productTypeCode);

      this.quoteRequest.removeVap(_.indexOf(this.quoteRequest.ValueAddedProducts, toRemove));
    }

    this.quoteRequest.changeVap(this.state.indexOfVapToEdit, { ...product, Price: price });
    this.closeAddVapModal();
  };

  openSettlementModal = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    this.setState({
      settlementModalOpen: true
    });
  };

  closeSettlementModal = () =>
    this.setState({
      settlementModalOpen: false
    });

  setSettlementFigure = (figure: number, agreementId: string) => {
    if (this.props.appStore.quotingStore.statusCode === 200 || this.props.appStore.quotingStore.statusCode === 201) {
      this.setOutstandingSettlement(figure, `MNF:${agreementId}`);
      this.setState({
        hasLookedupSettlement: true
      });
    }
  };

  removeSettlementFigure = () => {
    this.setOutstandingSettlement('');
    this.setState({
      hasLookedupSettlement: false
    });

    this.props.appStore.quotingStore.settlementRequest.reset();
    this.props.appStore.quotingStore.resetSettlement();
  };

  openCustomerCreditModal = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({
      customerCreditModalOpen: true
    });
  };

  closeCustomerCreditModal = () =>
    this.setState({
      customerCreditModalOpen: false
    });

  calculateSubmittingState = () => {
    if (this.props.isLoading === 'error' || this.props.checkingEligibilityState === 'error') {
      return 'error';
    }
    if (this.props.isLoading || this.props.checkingEligibilityState === 'loading') {
      return 'loading';
    }
    return 'done';
  };

  render() {
    const isLcv = this.props.vehicleClass.toLowerCase() === 'lcv';
    const isNew = this.props.vehicleCondition.toLowerCase() === 'new';
    const isUsed = this.props.vehicleCondition.toLowerCase() === 'used';
    const submittingState = this.calculateSubmittingState();
    // @ts-ignore - this.validator is possibly null
    const errors: Record<string | any> = this.validator.getErrors();
    // @ts-ignore - Operator '>' cannot be applied to types 'boolean' and 'number'.
    const canUseVaps = !this.quoteRequest.Accessories > 0;
    const showNonVatableItems = this.quoteRequest.NonVatableItems > 0;
    const hasNegativeEquity = this.quoteRequest.hasNegativeEquity;
    const canAddNegativeEquity = !(this.withoutVapsCreation && hasNegativeEquity);
    const pdfLink = this.props.appStore.quotingStore.settlementDocumentPdfLink;
    const canUseLenderVaps = this.props.appStore.uiState.canUseLenderVaps;
    const availableCashback = this.quoteRequest.AvailableCashback;
    const showCoverVatWarning = shouldShowCoverVatWarning(this.quoteRequest);
    const isVehicleReadOnly = !this.props.isQuickQuote;
    const showNegativeEquityWarning = shouldShowNegativeEquityWarning(this.quoteRequest);
    const formattedCustomerType = this.state.customerType?.toLowerCase();
    /*
     * Cashback is allowed when there is Equity available (PartExchange - OutstandingSettlement)
     * and can only be withdrawn from that Equity pot.
     *
     * For LCVs with VatAddedToDeposit, VatAmount can be covered by Equity and/or Cash Deposit.
     * But if the customer also wants to withdraw Cashback, they must:
     * 1. have Equity remaining after VatAmount is deducted
     * 2. have covered VatAmount entirely via Equity
     * This is to prevent customers withdrawing Cashback from the Cash Deposit
     */

    return (
      <div>
        <MobxForm
          focusOnNthElement={this.props.isQuickQuote ? 3 : 2}
          onSubmit={this.handleSubmit}
          className="quoteCostsForm"
        >
          <div className="quoteCostsForm__section">
            {this.props.isQuickQuote ? (
              <MobxFieldGroup isInline error={errors.CustomerType}>
                <MobxFormLabel>{this.props.t('QuoteCostsForm.customer_type')}</MobxFormLabel>
                {/* @ts-ignore - Property isGroupDisabled is required */}
                <RadioButtonGroup
                  options={this.props.optionsList['QuickQuoteCustomerType']}
                  name="CustomerType"
                  onChange={(e) => this.setCustomerType(e.target.name, e.target.value as CustomerType)}
                  checkedValue={formattedCustomerType}
                  variant="inline"
                />
              </MobxFieldGroup>
            ) : (
              ''
            )}
            {/* Mercury Feature under development */}
            <IfFeatureEnabled feature="rtl-findavehicle-page">
              {/* START section: Vehicle Information */}
              <MobxFieldGroup isInline error={errors.Vehicle && errors.Vehicle.Condition}>
                <MobxFormLabel>{this.props.t('QuoteCostsForm.condition')}</MobxFormLabel>
                <RadioButtonGroup
                  options={[
                    { label: this.props.t('QuoteCostsForm.used'), value: 'used' },
                    { label: this.props.t('QuoteCostsForm.new'), value: 'new' }
                  ]}
                  name="Condition"
                  onChange={(e) => this.setVehicleField('Condition', e.target.value)}
                  checkedValue={this.state.vehicle.Condition?.toLowerCase()}
                  variant="inline"
                  isGroupDisabled={isVehicleReadOnly}
                />
              </MobxFieldGroup>
              <MobxFieldGroup isInline error={errors.Vehicle && errors.Vehicle.Class}>
                <MobxFormLabel>{this.props.t('QuoteCostsForm.class')}</MobxFormLabel>
                <RadioButtonGroup
                  options={[
                    { label: this.props.t('QuoteCostsForm.car'), value: 'car' },
                    { label: this.props.t('QuoteCostsForm.bike'), value: 'bike' },
                    { label: this.props.t('QuoteCostsForm.lcv'), value: 'lcv' }
                  ]}
                  name="Class"
                  isGroupDisabled={true}
                  onChange={(e) => this.setVehicleField('Class', e.target.value)}
                  checkedValue={this.state.vehicle.Class?.toLowerCase()}
                  variant="inline"
                />
              </MobxFieldGroup>
              <MobxFieldGroup isInline error={errors.Vehicle && errors.Vehicle.Vin}>
                <MobxFormLabel htmlFor="Vin">{this.props.t('QuoteCostsForm.vin')}</MobxFormLabel>
                <Input
                  trackingPage="QuoteCosts"
                  value={this.state.vehicle.Vin}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setVehicleField('Vin', e.target.value)}
                  id="Vin"
                  dataThook="Vin"
                  disabled={isVehicleReadOnly}
                />
              </MobxFieldGroup>
              <MobxFieldGroup isInline error={errors.Vehicle && errors.Vehicle.RegistrationDate}>
                <MobxFormLabel htmlFor="RegistrationDate">
                  {this.props.t('QuoteCostsForm.registration_date')}
                </MobxFormLabel>
                <DateInput
                  id="RegistrationDate"
                  value={this.state.vehicle.RegistrationDate}
                  onChange={this.setVehicleField}
                  disabled={isVehicleReadOnly}
                />
              </MobxFieldGroup>
              {this.state.vehicle.Condition === 'used' && (
                <MobxFieldGroup isInline error={errors.Vehicle && errors.Vehicle.Mileage}>
                  <MobxFormLabel htmlFor="Mileage">{this.props.t('QuoteCostsForm.current_mileage')}</MobxFormLabel>
                  <Input
                    trackingPage="Mileage"
                    value={this.state.vehicle.Mileage}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.setVehicleField('Mileage', e.target.value)
                    }
                    type="text"
                    id="Mileage"
                    dataThook="Mileage"
                  />
                </MobxFieldGroup>
              )}
              {/* START section: End Vehicle Information */}
            </IfFeatureEnabled>
            {/* START section: VAT */}
            {isLcv && this.quoteRequest.VatQualifying && this.props.appStore.uiState.canQuoteVAT ? (
              <MobxFieldGroup isInline error={errors.BasicPrice}>
                <MobxFormLabel htmlFor="BasicPrice">{this.props.t('QuoteCostsForm.basic_price')}</MobxFormLabel>
                <CurrencyInput
                  trackingPage="QuoteCosts"
                  value={this.quoteRequest.BasicPrice}
                  onChange={this.setBasicPrice}
                  id="BasicPrice"
                  dataThook="BasicPrice"
                />
              </MobxFieldGroup>
            ) : (
              <MobxFieldGroup isInline error={errors.VehiclePrice}>
                <MobxFormLabel htmlFor="VehiclePrice">{this.props.t('QuoteCostsForm.vehicle_price')}</MobxFormLabel>
                <CurrencyInput
                  trackingPage="QuoteCosts"
                  value={this.quoteRequest.VehiclePrice}
                  onChange={this.setVehiclePrice}
                  id="VehiclePrice"
                  dataThook="VehiclePrice"
                />
              </MobxFieldGroup>
            )}

            {isUsed && this.props.appStore.uiState.canQuoteVAT && (
              <MobxFieldGroup isInline error={null}>
                <RadioButtonGroup
                  variant="inline"
                  options={[
                    { label: this.props.t('QuoteCostsForm.vat_margin_scheme'), value: 'VatMarginScheme' },
                    { label: this.props.t('QuoteCostsForm.vat_qualifying'), value: 'VatQualifying' }
                  ]}
                  name="VatQualifying"
                  // @ts-ignore - Property 'disabled' does not exist on type 'IntrinsicAttributes & RadioButtonGroupProps'.
                  disabled={isNew}
                  checkedValue={this.quoteRequest.VatQualifying ? 'VatQualifying' : 'VatMarginScheme'}
                  onChange={this.setVatQualifying}
                />
              </MobxFieldGroup>
            )}
            {this.quoteRequest.VatQualifying && this.props.appStore.uiState.canQuoteVAT && (
              <div>
                {isLcv ? (
                  <MobxFieldGroup isInline error={errors.VehiclePrice}>
                    <MobxFormLabel htmlFor="VehiclePrice">{this.props.t('QuoteCostsForm.vehicle_price')}</MobxFormLabel>
                    <CurrencyInput
                      trackingPage="QuoteCosts"
                      value={this.quoteRequest.VehiclePrice}
                      onChange={this.setVehiclePrice}
                      id="VehiclePrice"
                      dataThook="VehiclePrice"
                    />
                  </MobxFieldGroup>
                ) : (
                  <MobxFieldGroup isInline error={errors.BasicPrice}>
                    <MobxFormLabel htmlFor="BasicPrice">{this.props.t('QuoteCostsForm.basic_price')}</MobxFormLabel>
                    <CurrencyInput
                      trackingPage="QuoteCosts"
                      value={this.quoteRequest.BasicPrice}
                      onChange={this.setBasicPrice}
                      id="BasicPrice"
                      dataThook="BasicPrice"
                    />
                  </MobxFieldGroup>
                )}

                <MobxFieldGroup isInline error={errors.VatPercent}>
                  <MobxFormLabel htmlFor="VatPercent">{this.props.t('QuoteCostsForm.vat')}</MobxFormLabel>
                  <PercentageInput
                    value={this.quoteRequest.VatPercent}
                    onChange={this.setVatPercent}
                    id="VatPercent"
                    dataThook="VatPercent"
                  />
                </MobxFieldGroup>

                <MobxFieldGroup isInline error={errors.VatAmount}>
                  <MobxFormLabel htmlFor="VatAmount">{this.props.t('QuoteCostsForm.vat_amount')}</MobxFormLabel>
                  <CurrencyInput
                    trackingPage="QuoteCosts"
                    value={this.quoteRequest.VatAmount}
                    onChange={this.setVatAmount}
                    id="VatAmount"
                    dataThook="VatAmount"
                  />
                </MobxFieldGroup>

                <MobxFieldGroup isInline>
                  <div />
                  <div className="quoteCostsForm__vatCheckbox">
                    <CheckboxLabel>
                      <Checkbox
                        value={this.quoteRequest.VatAddedToDeposit}
                        onChange={this.setVatAddedToDeposit}
                        trackingPage="QuoteCosts"
                        id="AddVatToDeposit"
                        dataThook="AddVatToDeposit"
                      />
                      {this.props.t('QuoteCostsForm.include_vat_with_deposit')}
                    </CheckboxLabel>
                  </div>
                </MobxFieldGroup>

                {showNonVatableItems && (
                  <MobxFieldGroup isInline error={errors.NonVatableItems}>
                    <MobxFormLabel htmlFor="NonVatableItems">
                      {this.props.t('QuoteCostsForm.non_vatable_items')}
                    </MobxFormLabel>
                    <CurrencyInput
                      trackingPage="QuoteCosts"
                      value={this.quoteRequest.NonVatableItems}
                      onChange={this.setNonVatableItems}
                      id="NonVatableItems"
                      dataThook="NonVatableItems"
                    />
                  </MobxFieldGroup>
                )}
              </div>
            )}
          </div>
          {/* END section: VAT */}

          {/* START section: AnnualMileage, Term Final Payment & Credit Rating */}
          <div className="quoteCostsForm__section">
            <Divider />
            <MobxFieldGroup isInline error={errors.AnnualDistance}>
              <MobxFormLabel htmlFor="AnnualDistance">{this.props.t('QuoteCostsForm.annual_mileage')}</MobxFormLabel>
              {/* @ts-ignore - is missing the following properties: onBlur, appStore, size, showError, and 6 more. */}
              <SelectInput
                trackingPage="QuoteCosts"
                value={this.quoteRequest.AnnualDistance}
                onChange={this.setAnnualDistance}
                options={isItBike(this.props.vehicleClass) ? 'MotorBikeMileageOptions' : 'MileageOptions'}
                id="AnnualMileage"
                dataThook="AnnualMileage"
              />
            </MobxFieldGroup>
            <MobxFieldGroup isInline error={errors.Term}>
              <MobxFormLabel htmlFor="Term">{this.props.t('QuoteCostsForm.term_months')}</MobxFormLabel>
              {/* @ts-ignore - is missing the following properties: onBlur, appStore, size, showError, and 6 more. */}
              <SelectInput
                trackingPage="QuoteCosts"
                value={this.quoteRequest.Term}
                onChange={this.setTerm}
                options={hasExtendedTermLength(this.props.vehicleClass) ? 'TermsExtended' : 'Terms'}
                id="Term"
                dataThook="Term"
              />
            </MobxFieldGroup>

            {this.props.appStore.uiState.isBdk && (
              <MobxFieldGroup isInline error={errors.ResidualValue}>
                <MobxFormLabel htmlFor="ResidualValue">{this.props.t('QuoteCostsForm.residual_value')}</MobxFormLabel>
                <CurrencyInput
                  trackingPage="QuoteCosts"
                  value={this.quoteRequest.ResidualValue}
                  onChange={this.setResidualValue}
                  id="ResidualValue"
                  dataThook="ResidualValue"
                />
              </MobxFieldGroup>
            )}
            {this.props.appStore.uiState.canUseRbp && formattedCustomerType !== 'corporate' && (
              <MobxFieldGroup isInline error={errors.CustomerCreditScore}>
                <MobxFormLabel htmlFor="Term">{this.props.t('QuoteCostsForm.customer_credit_rating')}</MobxFormLabel>
                {/* @ts-ignore - is missing the following properties: onBlur, appStore, size, showError, and 6 more. */}
                <SelectInput
                  trackingPage="QuoteCosts"
                  value={this.quoteRequest.CustomerCreditScore}
                  onChange={this.setCreditScore}
                  options="CreditScoreOptions"
                  id="CustomerCreditScore"
                  dataThook="CustomerCreditScore"
                />
                <div className="quoteCostsForm__customerCreditTooltip">
                  <ImageButton image="information" width="25px" height="25px" onClick={this.openCustomerCreditModal} />
                  <button onClick={this.openCustomerCreditModal}>
                    {this.props.t('QuoteCostsForm.customer_credit_rating_explained')}
                  </button>
                </div>
              </MobxFieldGroup>
            )}
          </div>
          {/* END section: AnnualMileage, Term, Credit Rating */}
          {/* START section: Deposit Fields */}
          <Divider />
          <div className="quoteCostsForm__section">
            {!canAddNegativeEquity && (
              <AttentionPanel
                header={this.props.t('QuoteCostsForm.cannot_introduce_negative_equity_into_an_existing_application')}
                main={this.props.t(
                  'QuoteCostsForm.if_funding_for_negative_equity_is_required_please_create_a_new_proposal'
                )}
                linkTo={`/d/${this.props.dealershipId}/${this.props.customerType}s/${this.props.customerId}`}
                linkText={this.props.t('QuoteCostsForm.go_to_customer_record')}
              />
            )}

            {/* TECH DEBT: Temporary until support is added to QW */}
            {(this.props.appStore.uiState.isUk || canSeePartExchangeAndSettlementInDE()) && (
              <>
                <div>
                  <MobxFieldGroup isInline error={errors.PartExchange}>
                    <MobxFormLabel htmlFor="PartExchange">
                      {this.props.t('QuoteCostsForm.part_exchange_value')}
                    </MobxFormLabel>
                    <CurrencyInput
                      trackingPage="QuoteCosts"
                      value={this.quoteRequest.PartExchange}
                      onChange={this.setPartExchange}
                      id="PartExchange"
                      dataThook="PartExchange"
                    />
                  </MobxFieldGroup>

                  {formattedCustomerType === 'consumer' && this.props.appStore.uiState.canRetrieveSettlement ? (
                    <div>
                      {this.state.hasLookedupSettlement ? (
                        <MobxFieldGroup error={errors.OutstandingSettlement} isInline>
                          <MobxFormLabel htmlFor="OutstandingSettlement">
                            {this.props.t('QuoteCostsForm.settlement_figure')}
                          </MobxFormLabel>
                          <CurrencyInput
                            trackingPage="QuoteCosts"
                            value={this.quoteRequest.OutstandingSettlement}
                            onChange={this.setOutstandingSettlement}
                            id="SettlementFigure"
                            disabled
                            onRemove={this.removeSettlementFigure}
                            dataThook="SettlementFigure"
                          />
                          {pdfLink && (
                            <MobxFieldGroup isInline>
                              <a
                                type="application/pdf"
                                href={`/platform/v1/settlement/document?DealershipId=${this.props.dealershipId}&AgreementId=${this.props.appStore.quotingStore.agreementId}`}
                                download={`${this.props.appStore.quotingStore.agreementId}.pdf`}
                                className="quoteCostsForm__settlementPdf"
                              >
                                {this.props.t('QuoteCostsForm.view_settlement_letter')}
                              </a>
                            </MobxFieldGroup>
                          )}
                        </MobxFieldGroup>
                      ) : (
                        <MobxButtonFieldGroup error={errors.OutstandingSettlement}>
                          <MobxFormLabel htmlFor="OutstandingSettlement">
                            {this.props.t('QuoteCostsForm.settlement_figure')}
                          </MobxFormLabel>
                          <CurrencyInput
                            trackingPage="QuoteCosts"
                            value={this.quoteRequest.OutstandingSettlement}
                            onChange={this.setOutstandingSettlement}
                            id="SettlementFigure"
                            dataThook="SettlementFigure"
                          />
                          <Button
                            onClick={this.openSettlementModal}
                            buttonStyle="secondary"
                            id="SettlementFigureLookupButton"
                            type="button"
                            trackingPage="QuoteCosts"
                          >
                            {this.props.t('QuoteCostsForm.lookup')}
                          </Button>
                        </MobxButtonFieldGroup>
                      )}
                    </div>
                  ) : (
                    <MobxFieldGroup error={errors.OutstandingSettlement} isInline>
                      <MobxFormLabel htmlFor="OutstandingSettlement">
                        {this.props.t('QuoteCostsForm.settlement_figure')}
                      </MobxFormLabel>
                      <CurrencyInput
                        trackingPage="QuoteCosts"
                        value={this.quoteRequest.OutstandingSettlement}
                        onChange={this.setOutstandingSettlement}
                        id="SettlementFigure"
                        dataThook="SettlementFigure"
                      />
                    </MobxFieldGroup>
                  )}
                </div>
              </>
            )}

            {canSeeCashback() && !this.props.appStore.uiState.isBnpp && (
              <MobxFieldGroup isInline error={errors.Cashback}>
                <MobxFormLabel htmlFor="Cashback">{this.props.t('QuoteCostsForm.cashback')}</MobxFormLabel>
                <CurrencyInput
                  trackingPage="Cashback"
                  value={this.quoteRequest.Cashback}
                  onChange={this.setCashback}
                  id="Cashback"
                  type="valueAndTotal"
                  disabled={
                    hasNegativeEquity ||
                    !this.quoteRequest.PartExchange ||
                    (availableCashback === 0 && this.quoteRequest.Cashback === 0)
                  }
                  total={availableCashback}
                />
              </MobxFieldGroup>
            )}
            <MobxFieldGroup isInline error={errors.CashDeposit}>
              <MobxFormLabel htmlFor="CashDeposit">{this.props.t('QuoteCostsForm.cash_deposit')}</MobxFormLabel>
              <CurrencyInput
                trackingPage="QuoteCosts"
                value={this.quoteRequest.CashDeposit}
                onChange={this.setCashDeposit}
                id="CashDeposit"
                dataThook="CashDeposit"
              />
            </MobxFieldGroup>
            <MobxFieldGroup isInline>
              <MobxFormLabel>{this.props.t('QuoteCostsForm.total_deposit')}</MobxFormLabel>
              <CurrencyInput
                trackingPage="QuoteCosts"
                value={formatToNumDecimalPlaces(this.quoteRequest.getNetDeposit(), 2, '')}
                disabled={true}
                id="NetDeposit"
                dataThook="NetDeposit"
              />
              {/* Displays 'negative equity' AlertCard or 'cover VAT' AlertCard or both */}
              {(showNegativeEquityWarning ? !showCoverVatWarning : showCoverVatWarning) && (
                <AlertCard
                  iconName="information"
                  className="quoteCostsForm__alert"
                  paragraph={
                    showNegativeEquityWarning
                      ? this.props.t('QuoteCostsForm.this_deal_contains_negative_equity')
                      : this.props.t('QuoteCostsForm.vat_amount_not_covered')
                  }
                />
              )}
              {showNegativeEquityWarning && showCoverVatWarning && (
                <AlertCard
                  iconName="information"
                  className="quoteCostsForm__alert"
                  paragraph={`${this.props.t('QuoteCostsForm.this_deal_contains_negative_equity')}`}
                >
                  {this.props.t('QuoteCostsForm.vat_amount_not_covered')}
                </AlertCard>
              )}
            </MobxFieldGroup>
            {/* END section: Deposit Fields */}

            {/* START section: Dealer VAPs */}
            <div className="quoteCostsForm__section">
              {!this.withoutVapsCreation && canUseVaps && (
                <>
                  <Divider />
                  <div className="quoteCostsForm__valueAddedProducts">
                    {this.props.appStore.vapStore.visibleProducts.length > 0 && (
                      <div className="quoteCostsForm__addVapButton">
                        {this.props.appStore.uiState.canEditQuotingVaps && (
                          <MobxFieldGroup isInline>
                            <Button
                              type="button"
                              buttonStyle="secondary"
                              onClick={this.addVap}
                              trackingPage="QuoteCosts"
                            >
                              {this.quoteRequest.ValueAddedProducts.length > 0
                                ? this.props.t('QuoteCostsForm.add_another_vap')
                                : this.props.t('QuoteCostsForm.add_a_vap')}
                            </Button>
                          </MobxFieldGroup>
                        )}
                      </div>
                    )}
                    {/* @ts-ignore - Operator '>' cannot be applied to types 'boolean' and 'number'. */}
                    {!this.props.appStore.vapStore.visibleProducts.length > 0 &&
                      !this.props.appStore.vapStore.fetchVapsRequest.hasError && (
                        <div className="quoteCostsForm__noVaps">
                          {this.props.t(
                            'QuoteCostsForm.you_currently_dont_have_any_value_added_products_va_ps_set_up_for_your_dealership'
                          )}
                          <br />
                          {this.props.t(
                            'QuoteCostsForm.products_can_be_configured_in_the_settings_area_by_your_dealership_administrator'
                          )}
                        </div>
                      )}
                    {this.props.appStore.vapStore.fetchVapsRequest.hasError && (
                      <div className="quoteCostsForm__noVaps">
                        {this.props.t('QuoteCostsForm.sorry_there_was_a_problem_loading_your_va_ps')}
                      </div>
                    )}

                    {this.quoteRequest.ValueAddedProducts.map((vap: ValueAddedProductType, index: number) => (
                      <div data-name={vap.Name} key={`valueAddedProduct-${index}`}>
                        <ValueAddedProduct
                          index={index}
                          label={vap.ProductTypeText}
                          value={vap.Price}
                          onChange={this.quoteRequest.changeVapPrice}
                          onRemove={this.quoteRequest.removeVap}
                          onEdit={this.editVap}
                          error={errors.ValueAddedProducts && errors.ValueAddedProducts[index]}
                          trackingPage="QuoteCosts"
                          disabled={!this.props.appStore.uiState.canEditQuotingVaps}
                          dataThook={vap.Name}
                        />
                      </div>
                    ))}

                    <MobxFieldGroup isInline>
                      <MobxFormLabel>{this.props.t('QuoteCostsForm.total_value_added_products')}</MobxFormLabel>
                      <CurrencyInput
                        trackingPage="QuoteCosts"
                        value={this.quoteRequest.valueAddedProductsTotal}
                        disabled={true}
                        id="ValueAddedProducts"
                        dataThook="ValueAddedProductsTotal"
                      />
                    </MobxFieldGroup>
                  </div>
                </>
              )}
              {/* Accessories may have been superseded by Value Added Products, needs more investigation to remove */}
              {!canUseVaps && this.props.appStore.uiState.canAddAccessories && (
                <div>
                  <MobxFieldGroup isInline error={errors.Accessories}>
                    <MobxFormLabel htmlFor="Accessories">{this.props.t('QuoteCostsForm.accessories')}</MobxFormLabel>
                    <CurrencyInput
                      trackingPage="QuoteCosts"
                      value={this.quoteRequest.Accessories}
                      // @ts-ignore - Property 'setAccessories' does not exist on type 'QuoteCostsForm'.
                      onChange={this.setAccessories}
                      disabled={true}
                      id="Accessories"
                      dataThook="Accessories"
                    />
                  </MobxFieldGroup>
                  <div className="quoteCostsForm__buttonWrap">
                    <FieldToggle
                      text={this.props.t('QuoteCostsForm.accessories_options')}
                      onClick={() => this.toggleField('showAccessoriesFields')}
                      showHide={this.state.showAccessoriesFields}
                    />
                  </div>

                  {this.state.showAccessoriesFields ? (
                    <div>
                      <MobxFieldGroup isInline error={errors.Insurance}>
                        <MobxFormLabel htmlFor="Insurance">{this.props.t('QuoteCostsForm.insurance')}</MobxFormLabel>
                        <CurrencyInput
                          trackingPage="QuoteCosts"
                          value={this.quoteRequest.Insurance}
                          onChange={this.setInsurance}
                          id="Insurance"
                          dataThook="Insurance"
                        />
                      </MobxFieldGroup>

                      <MobxFieldGroup isInline error={errors.Warranty}>
                        <MobxFormLabel htmlFor="Warranty">{this.props.t('QuoteCostsForm.warranty')}</MobxFormLabel>
                        <CurrencyInput
                          trackingPage="QuoteCosts"
                          value={this.quoteRequest.Warranty}
                          onChange={this.setWarranty}
                          id="Warranty"
                          dataThook="Warranty"
                        />
                      </MobxFieldGroup>

                      <MobxFieldGroup isInline error={errors.Other}>
                        <MobxFormLabel htmlFor="Other">{this.props.t('QuoteCostsForm.other')}</MobxFormLabel>
                        <CurrencyInput
                          trackingPage="QuoteCosts"
                          value={this.quoteRequest.Other}
                          onChange={this.setOther}
                          id="Other"
                          dataThook="Other"
                        />
                      </MobxFieldGroup>

                      <Divider />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
            {/* END section: Dealer VAPs */}

            {/* START section: Lender VAPs */}
            <div className="quoteCostsForm__section">
              {canUseLenderVaps && (
                <div className="quoteCostsForm__lenderValueAddedProducts">
                  <Divider />
                  <h4
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    BDK {this.props.t('QuoteCostsForm.value_added_products')}
                  </h4>

                  <BDKLenderVaps
                    LenderVaps={this.quoteRequest.LenderVaps}
                    onChange={(value: number | string) => {
                      this.setLenderVaps(null, value);
                    }}
                  />
                </div>
              )}
            </div>
            {/* END section: Lender VAPs */}

            {/* START section: TotalPrice, BalanceToChange */}
            <div className="quoteCostsForm__section">
              <Divider />
              <MobxFieldGroup isInline error={errors.TotalPrice}>
                <MobxFormLabel htmlFor="TotalPrice">{this.props.t('QuoteCostsForm.total_price')}</MobxFormLabel>
                <CurrencyInput
                  trackingPage="QuoteCosts"
                  value={this.quoteRequest.TotalPrice}
                  disabled
                  id="TotalPrice"
                />
              </MobxFieldGroup>
              <MobxFieldGroup isInline>
                <MobxFormLabel>{this.props.t('QuoteCostsForm.balance_to_finance')}</MobxFormLabel>
                <CurrencyInput
                  trackingPage="QuoteCosts"
                  value={this.quoteRequest.BalanceToChange}
                  disabled={true}
                  id="BalanceToChange"
                />
              </MobxFieldGroup>
            </div>
            {/* END section: TotalPrice, BalanceToChange */}
          </div>
          <FormFooter
            submitLabel={this.props.t('QuoteCostsForm.generate_quotes')}
            onCancel={this.props.onCancel}
            submittingState={submittingState}
            trackingPage="QuoteCosts"
            submitDisabled={!canAddNegativeEquity}
          />
          <AddVapModal
            isOpen={this.state.addVapModalOpen}
            onClose={this.closeAddVapModal}
            onSubmit={this.handleSubmitAddVapModal}
            currentVaps={this.quoteRequest.ValueAddedProducts}
            vehicleClass={this.props.vehicleClass}
          />
          <EditVapModal
            isOpen={this.state.isEditVapModalOpen}
            onClose={this.closeAddVapModal}
            onSubmit={this.handleSubmitEditVapModal}
            vapToEdit={this.quoteRequest.ValueAddedProducts[this.state.indexOfVapToEdit as number]}
            currentVaps={this.quoteRequest.ValueAddedProducts}
            vehicleClass={this.props.vehicleClass}
          />
        </MobxForm>

        <SettlementModal
          isOpen={this.state.settlementModalOpen}
          onClose={this.closeSettlementModal}
          setSettlementFigure={this.setSettlementFigure}
          dealershipId={this.props.dealershipId}
          customerId={this.props.customerId}
        />
        <CustomerCreditModal
          isOpen={this.state.customerCreditModalOpen}
          onClose={this.closeCustomerCreditModal}
          // @ts-ignore - title does not exist on CustomerCreditModal
          title="Customer's credit rating explained"
        />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    optionsList: state.options
  };
}

function mapDispatchToProps<T>(dispatch: Dispatch<T>) {
  return {
    changeQuickQuoteVehicle: (vehicle: FixLaterQuoteVehicle) => {
      dispatch(quoteActions.changeQuotingVehicle(vehicle));
      dispatch(quickQuoteActions.changeQuickQuoteVehicle(vehicle));
    }
  };
}

export default withTranslation('Quoting')(
  // @ts-ignore
  inject(['appStore'])(connect(mapStateToProps, mapDispatchToProps)(observer(QuoteCostsForm)))
);
