import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import Modal from '../Common/Modal/Modal';
import HelpVideoModal from '../HelpVideos/components/HelpVideoModal';
import WhatsNewSidebarLink from './WhatsNewSidebarLink';
import classnames from 'classnames';
import Rounder from '../../styles/icons/sidebar/Rounder';
import './sidebar.scss';
import SidebarItem from './SidebarItem';
import Icon from '../Common/Icon/Icon';
import { inject } from 'mobx-react';
import TrackEvent from '../Common/Tracking/TrackEvent';
import { canLoad as canLoadWebshopModule } from '../Webshop/Webshop';
import { WebshopIcon } from '../../styles/icons/webshop';
import LanguageSelectTrigger from '../LanguageSelect/LanguageSelectTrigger';
import HelpNoVideoBDK from '../HelpVideos/components/HelpNoVideoBDK';
import LoadingSpinner from '../Common/Loading/LoadingSpinner';
import LanguageFlag from '../LanguageSelect/LanguageFlag';
import { AppStore } from '~/modules/Stock/types/Types';
import { useParams } from 'react-router-dom';
import { canSeePrivacyPolicy } from '~/features';

export interface SidebarProps {
  appStore?: AppStore;
  isOpen: boolean;
  sidebarIsHovered: boolean;
  hasPlatformUpdates: boolean;
  currentPage: string;
  currentQuery: object;
  closeSidebar: () => void;
  handleStateChange: () => void;
  onSignOut: () => void;
  handleSidebarHoverOpen: () => void;
  handleSidebarHoverClose: () => void;
  innerRef: () => void;
}

const Sidebar = (props: SidebarProps) => {
  const {
    appStore,
    closeSidebar,
    handleStateChange,
    onSignOut,
    isOpen,
    sidebarIsHovered,
    innerRef,
    handleSidebarHoverClose,
    handleSidebarHoverOpen
  } = props;
  const {
    canViewMetricsOnSidebar,
    hasStockEnabled,
    canUseReporting,
    hasOrders,
    hasWebshopEnabled,
    canUseWebshop,
    canViewReservations,
    isBdk,
    canViewDashboard,
    isDealershipAdmin,
    canViewHomePage,
    canUseInsights,
    canSeeFinanceInsights
  } = appStore?.uiState || {};
  const reserveNowStore = appStore?.reserveNowStore;

  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [bdkHelpModalOpen, setBDKHelpModalOpen] = useState(false);
  const { dealershipId } = useParams();
  const { t } = useTranslation('Sidebar');

  useEffect(() => {
    reserveNowStore?.getReservationSettings(dealershipId);
  }, [reserveNowStore, dealershipId]);

  const handleModalOpen = () => {
    isBdk ? setBDKHelpModalOpen(true) : setVideoModalOpen(true);
    closeSidebar();
    handleStateChange();
  };

  const handleLanguageModalOpen = () => {
    closeSidebar();
    handleStateChange();
  };

  const handleModalClose = () => {
    isBdk ? setBDKHelpModalOpen(false) : setVideoModalOpen(false);
  };

  const handleClick = () => {
    handleStateChange();
  };

  const signOut = (evt: Event) => {
    evt.stopPropagation();
    onSignOut();
  };

  const handlePushDealClick = () => {
    window.ga && window.ga('send', 'event', 'PushDealSidebar', 'ButtonPress', `PushDealSidebar__link`);
    handleStateChange();
  };

  const getReservationProps = () => {
    const canSetupReservations = !reserveNowStore && isDealershipAdmin;
    return {
      path: canSetupReservations ? 'settings/reserve' : 'reservenow',
      interactionName: canSetupReservations ? 'ReservationsSettingsSidebarClick' : 'ReservationsSidebarClick',
      text: canSetupReservations ? t('Sidebar.setup_reservations') : t('Sidebar.reservations')
    };
  };

  const { isPageLoading: reservationsLoading } = reserveNowStore || {};
  const reservationProps = getReservationProps();
  const sidebarClassNames = classnames('sidebar', isOpen ? 'sidebar--open' : 'sidebar--closed');
  const sidebarRounderClassNames = classnames('sidebar__rounder', sidebarIsHovered && 'sidebar__rounder--open');
  const sidebarInnerClassNames = classnames('sidebar__inner', sidebarIsHovered && 'sidebar__hover');

  return (
    <div
      ref={innerRef}
      onMouseEnter={handleSidebarHoverOpen}
      onMouseLeave={handleSidebarHoverClose}
      className={sidebarClassNames}
      data-th="Sidebar"
    >
      <button className="sidebar__button--open" onClick={handleClick} data-th="Sidebar-closeButton">
        <Icon name="validation-cross" />
      </button>
      <button className="sidebar__button--closed" onClick={handleClick} data-th="Sidebar-openButton">
        <Icon name="burger" />
      </button>

      <div className={sidebarRounderClassNames}>
        <Rounder />
      </div>

      <div className={sidebarInnerClassNames}>
        <ul className="sidebar__buttonContainer">
          <LanguageSelectTrigger>
            {({ currentLanguage, handleModalOpen }: any) => (
              <SidebarItem
                icon={<LanguageFlag imgSrc={currentLanguage.imageSrc} />}
                sidebarIsHovered={sidebarIsHovered}
                onClick={() => {
                  handleLanguageModalOpen();
                  handleModalOpen();
                }}
              >
                {currentLanguage.label}
              </SidebarItem>
            )}
          </LanguageSelectTrigger>
          {canViewHomePage && (
            <SidebarItem
              to={`/d/${dealershipId}/home`}
              onClick={handleStateChange}
              image="home"
              dataThook="home-v2"
              sidebarIsHovered={sidebarIsHovered}
            >
              {t('Sidebar.home')}
            </SidebarItem>
          )}
          {canViewDashboard && (
            <SidebarItem
              to={`/d/${dealershipId}`}
              onClick={handleStateChange}
              image="homeDashboard"
              dataThook="home"
              sidebarIsHovered={sidebarIsHovered}
            >
              {t('Sidebar.dashboard')}
            </SidebarItem>
          )}
          {canViewMetricsOnSidebar && (
            <SidebarItem
              to={`/d/${dealershipId}/stock/dealership-metrics/average-days-in-stock`}
              onClick={handleStateChange}
              image="homeDashboard"
              dataThook="home"
              sidebarIsHovered={sidebarIsHovered}
            >
              {t('Sidebar.metrics')}
            </SidebarItem>
          )}
          {hasStockEnabled && (
            <SidebarItem
              to={`/d/${dealershipId}/stock/list`}
              onClick={handleStateChange}
              image="stock"
              dataThook="stock"
              sidebarIsHovered={sidebarIsHovered}
            >
              {t('Sidebar.stock')}
            </SidebarItem>
          )}
          <SidebarItem
            to={`/d/${dealershipId}/customer/add`}
            onClick={handleStateChange}
            image="addCustomer"
            dataThook="add_customer"
            sidebarIsHovered={sidebarIsHovered}
          >
            {t('Sidebar.add_customer')}
          </SidebarItem>
          <SidebarItem
            to={{
              pathname: `/d/${dealershipId}/consumers`,
              query: {
                sortField: 'lastModified',
                sortDirection: 'desc',
                page: '1',
                pageSize: '25',
                q: undefined,
                from: undefined,
                to: undefined,
                timePeriod: '',
                filter: ''
              }
            }}
            onClick={handleStateChange}
            image="viewCustomers"
            dataThook="view_customers"
            sidebarIsHovered={sidebarIsHovered}
          >
            {t('Sidebar.view_customers')}
          </SidebarItem>

          {canViewReservations && (
            <TrackEvent
              featureName="sidebar"
              interactionName={reservationProps.interactionName}
              linkTo={`/d/${dealershipId}/${reservationProps.path}`}
            >
              <SidebarItem
                to={`/d/${dealershipId}/${reservationProps.path}`}
                image={'payments'}
                sidebarIsHovered={sidebarIsHovered}
                dataThook="reservations"
              >
                {reservationsLoading ? <LoadingSpinner size="small" /> : reservationProps.text}
              </SidebarItem>
            </TrackEvent>
          )}

          {hasOrders && (
            <TrackEvent
              featureName="sidebar"
              interactionName="PushDealSidebarClick"
              linkTo={`/d/${dealershipId}/orders-and-deals`}
            >
              <SidebarItem
                to={`/d/${dealershipId}/orders-and-deals`}
                onClick={handlePushDealClick}
                image={'digitalDeals'}
                sidebarIsHovered={sidebarIsHovered}
                dataThook="send_deal"
              >
                {t('Sidebar.orders_deals')}
              </SidebarItem>
            </TrackEvent>
          )}

          {canUseInsights && (
            <SidebarItem
              to={`/d/${dealershipId}/insights/${
                canSeeFinanceInsights ? 'application-volumes' : 'average-vehicles-stock'
              }`}
              image="insights"
              sidebarIsHovered={sidebarIsHovered}
              dataThook="insights"
            >
              {t('Sidebar.insights')}
            </SidebarItem>
          )}

          {canUseReporting && (
            <SidebarItem
              to={`/d/${dealershipId}/reporting`}
              onClick={handleStateChange}
              image="reporting"
              dataThook="reporting"
              sidebarIsHovered={sidebarIsHovered}
            >
              {t('Sidebar.reporting')}
            </SidebarItem>
          )}
          {isBdk && (
            <SidebarItem
              to="https://ald-leasefinanz.de/ald/impressum"
              image="impressum"
              dataThook="impressum"
              sidebarIsHovered={sidebarIsHovered}
              newTab
            >
              Impressum
            </SidebarItem>
          )}
          {hasWebshopEnabled && canUseWebshop && canLoadWebshopModule() && (
            <SidebarItem
              to={`/d/${dealershipId}/webshop`}
              onClick={handleStateChange}
              icon={<WebshopIcon />}
              dataThook="webshop"
              sidebarIsHovered={sidebarIsHovered}
            >
              Webshop
            </SidebarItem>
          )}
          <SidebarItem
            to={`/d/${dealershipId}/settings`}
            onClick={handleStateChange}
            image="settings"
            dataThook="settings"
            sidebarIsHovered={sidebarIsHovered}
          >
            {t('Sidebar.settings')}
          </SidebarItem>
          <SidebarItem onClick={handleModalOpen} image="help" sidebarIsHovered={sidebarIsHovered} dataThook="help">
            {t('Sidebar.help')}
          </SidebarItem>
          {canSeePrivacyPolicy() && (
            <SidebarItem
              to={`/d/${dealershipId}/privacypolicy`}
              onClick={handleStateChange}
              image="privacyPolicy"
              dataThook="privacyPolicy"
              sidebarIsHovered={sidebarIsHovered}
            >
              {t('Sidebar.privacy_policy')}
            </SidebarItem>
          )}
          <SidebarItem onClick={signOut} image="signOut" sidebarIsHovered={sidebarIsHovered} dataThook="signOut">
            {t('Sidebar.sign_out')}
          </SidebarItem>
        </ul>
        <WhatsNewSidebarLink
          link={`/d/${dealershipId}/platformupdates`}
          onClick={handleClick}
          hasPlatformUpdates={props.hasPlatformUpdates}
        />
      </div>
      <Modal isOpen={videoModalOpen} onClose={handleModalClose} title="Tutorial Videos">
        <HelpVideoModal currentPage={props.currentPage} currentQuery={props.currentQuery} />
      </Modal>
      <Modal isOpen={bdkHelpModalOpen} onClose={handleModalClose} title="Kontaktieren Sie das Support-Team">
        <HelpNoVideoBDK />
      </Modal>
    </div>
  );
};

export default inject('appStore')(Sidebar);
