import { useTranslation } from 'react-i18next';
import { ApplicationQuote } from '~/types/application';
import { isFnTypeQuote, isInterceptorPreApprovalQuote } from '../QuoteCard/utils/quoteFeatures';

export const useGetSavedQuoteCtaLabel = (quote: ApplicationQuote) => {
  const { t } = useTranslation('Reporting');
  switch (true) {
    case isFnTypeQuote(quote) === true:
      return t('SavedQuoteDetailsPage.check_eligibility');
    case isInterceptorPreApprovalQuote(quote) === true:
      return t('SavedQuoteDetailsPage.get_pre_approval');
    default:
      return t('SavedQuoteDetailsPage.apply');
  }
};
