import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Page from '../Common/Page';
import Breadcrumbs from '../Common/Breadcrumbs';
import Panel from '../Common/Panel';
import PanelHeader from '../Common/PanelHeader';
import PanelContent from '../Common/PanelContent';
import FormFooter from '../Common/Form/FormFooter';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import appStore from '~/mobx-stores/appStore';
import LoadingDots from '../Common/Loading/LoadingDots';
import { useGetPrivacyPolicyDocs } from '~/api/legaldocs/hooks';
import s from './PrivacyPolicyContainer.module.scss';
import { canSeePrivacyPolicy } from '~/features';

type PrivacyPolicyMetadata = {
  Date: string;
  Version: string;
  Title: string;
};

type LegalDocument = {
  metadata: PrivacyPolicyMetadata | null;
  html: any;
};

const PrivacyPolicyContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('PrivacyPolicy');
  const params = useParams<{ dealershipId: string }>();

  const onCancel = () => {
    navigate(`/d/${params.dealershipId}`);
  };

  const { uiState } = appStore;

  const { data: privacyPolicyData, isLoading, isError, error } = useGetPrivacyPolicyDocs(uiState.countryCode);

  const privacyPolicyDate = privacyPolicyData?.metadata?.Date || null;

  return (
    <Page>
      <Breadcrumbs
        items={[
          { name: t('PrivacyPolicyContainer.home'), path: `/d/${params.dealershipId}` },
          { name: t('PrivacyPolicyContainer.PrivacyPolicy') }
        ]}
      />
      {canSeePrivacyPolicy() && (
        <div data-th="privacyPolicy">
          <Panel>
            <PanelHeader dataHook="privacyPolicy">
              {t('PrivacyPolicyContainer.PrivacyPolicy')}
              {privacyPolicyDate && <div className={s['date']}>Last Updated: {privacyPolicyDate}</div>}
            </PanelHeader>
            <PanelContent>
              {isError && <div>{(error as Error).message}</div>}
              {isLoading && <LoadingDots />}
              {privacyPolicyData && (
                <div className={s['panelOuter']}>
                  <div className={s['title']}>{t('PrivacyPolicyContent.PrivacyPolicyTitle')}</div>
                  <div
                    className={s['content']}
                    data-th="privacyPolicyContent"
                    dangerouslySetInnerHTML={{ __html: privacyPolicyData.html }}
                  ></div>
                </div>
              )}
              {!isLoading && (
                <FormFooter cancelLabel={t('PrivacyPolicyContainer.back')} onCancel={onCancel} hideSubmit />
              )}
            </PanelContent>
          </Panel>
        </div>
      )}
    </Page>
  );
};

export default PrivacyPolicyContainer;
