import produce from 'immer';
import defaultConfig, { QuoteCardConfig, QuoteCardConfigProps } from '../default';
import { isFinanceNavigatorEligibilityQuote } from '../../utils/quoteFeatures';
import { EligibilityOffered } from '../../types';

export default ({ quote, application }: QuoteCardConfigProps) => {
  return produce(defaultConfig, (draft: QuoteCardConfig) => {
    if (isFinanceNavigatorEligibilityQuote(quote)) {
      draft.title =
        quote.Eligibility.Data?.Eligibility === EligibilityOffered.PreApproved
          ? 'QuoteCardTable.preapproved_quote'
          : defaultConfig.title;
    }
  });
};
