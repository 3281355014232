export const startsWithHttpOrHttps = (s: string) => {
  const urlRegexTest = /^(https:\/\/|http:\/\/)/g;

  return urlRegexTest.test(s);
};

export const formatCountryCurrency = (countryCode: string) => {
  const lowercase = countryCode?.toLowerCase();
  switch (lowercase) {
    case 'gb':
      return 'misc.formatCurrencyGBP';
    default:
      return 'misc.formatCurrencyEUR';
  }
};
