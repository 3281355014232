import { Trans, withTranslation } from 'react-i18next';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import VehicleDetailsSidebar from './VehicleDetailsSidebar';
import PushDealVapsDetails from './PushDealVapsDetails';
import PushDealPartExDetails from './PushDealPartExDetails';
import PushDealPricingDetails from './PushDealPricingDetails';
import PushDealQuote from '../../mobx-models/PushDealQuote';
import PushDealSentDetails from './PushDealSentDetails';
import ActivityTimelineItem from './ActivityTimelineItem';
import Page from '../Common/Page';
import Breadcrumbs from '../Common/Breadcrumbs';
import Panel from '../Common/Panel';
import PanelHeader from '../Common/PanelHeader';
import CollapsiblePanel from '../Common/CollapsiblePanel';
import Button from '../Common/Button/Button';
import LoadingSpinner from '../Common/Loading/LoadingSpinner';
import TrackPageView from '../Common/Tracking/TrackPageView';
import TrackEvent from '../Common/Tracking/TrackEvent';
import MoneyFormat from '../Common/MoneyFormat';
import ActivityTimelineContent from './ActivityTimelineContent';
import ActivityTimeline from './ActivityTimeline';
import moment from 'moment';
import { compose } from 'redux';
import { withNavigate, withOutletContextProps, withParams } from 'hocs/router';
import { canUseSendDealViaQuoteResultsPage } from '~/features';
import './pushDealActivityContainer.scss';

@inject('appStore')
@observer
class PushDealActivityContainer extends Component {
  componentDidMount() {
    if (!this.props.appStore.pushDealStore.getDealLoading) {
      const currentDeal = this.getCurrentDeal();
      this.props.appStore.userStore.fetchUser(this.props.params.dealershipId, currentDeal.responsibleUserId);
    }

    this.props.appStore.quotingStore.getNewCarOffers(this.props.dealership.WebQuotewareId);
  }

  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  onSendDeal = (deal) => {
    const vehicle = {
      CapId: deal.vehicle.capId,
      Class: deal.vehicle.class.toLowerCase(),
      Condition: deal.quotes[0].condition,
      Vrm: deal.vehicle.vrm,
      Make: deal.vehicle.make,
      Model: deal.vehicle.model,
      Derivative: deal.vehicle.derivative,
      Mileage: deal.vehicle.mileage,
      CdnVehicleImageUrl: deal.vehicleImageUrls[0] || null,
      RegistrationDate: deal.vehicle.regDate ? moment(deal.vehicle.regDate).format('DD/MM/YYYY') : '',
      VehicleId: deal.dealerPlatformVehicleOfInterestId
    };

    const vapsTotal = this.getValueAddedProductsTotal();
    this.props.appStore.pushDealStore.setVehicle(vehicle, deal.vehicleCashPrice - vapsTotal);
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/send-deal`,
      query: {
        consumerId: this.props.params.consumerId,
        entry: 'with-vehicle'
      }
    });
    this.sendGoogleAnalyticsEvent();
  };
  goToCustomerApplication = (currentDeal, lenderStatus) => {
    let page = lenderStatus === 'Not Submitted' ? 'applicationsummary' : 'status';
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/application/${currentDeal.application.applicationId}/${page}`
    });
  };

  sendGoogleAnalyticsEvent() {
    window.ga &&
      window.ga(
        'send',
        'event',
        'PushDealSummary',
        'ButtonPress',
        `SendAnotherDealFromCustomer_${this.props.params.consumerId}`
      );
  }

  get breadcrumbs() {
    return [
      {
        name: this.props.t('PushDealActivityContainer.home'),
        path: `/d/${this.props.params.dealershipId}`
      },
      {
        name: this.props.t('PushDealActivityContainer.digital_deals'),
        path: `/d/${this.props.params.dealershipId}/orders-and-deals`,
        query: {
          page: 1,
          pageSize: 5,
          dealStatus: 'all',
          sortBy: 'sentAt',
          activeTab: 'DigitalDeals'
        }
      },
      {
        name: this.props.t('PushDealActivityContainer.consumer'),
        path: `/d/${this.props.params.dealershipId}/consumers/${this.props.consumer.Id}`,
        query: {
          vehicleId: this.getCurrentDeal().dealerPlatformVehicleOfInterestId
        }
      },
      {
        name: this.props.t('PushDealActivityContainer.deal_summary')
      }
    ];
  }

  isLoading() {
    return this.props.appStore.pushDealStore.getDealLoading;
  }

  getCurrentDeal() {
    return this.props.appStore.pushDealStore.customerPushDeals.deals.filter(
      (deal) => deal.id === this.props.params.dealId
    )[0];
  }

  getQuotes() {
    const currentDeal = this.getCurrentDeal();
    return currentDeal.quotes.map((quote) => {
      let formattedQuote = new PushDealQuote();
      formattedQuote.updateQuote(quote);
      return formattedQuote;
    });
  }

  getVaps() {
    const currentDeal = this.getCurrentDeal();
    return currentDeal.quotes[0].valueAddedProducts.map((vap) => vap);
  }

  getValueAddedProductsTotal() {
    const vaps = this.getVaps();
    const value = vaps
      .map((vap) => {
        return vap.price || vap.Price || 0;
      })
      .reduce((accumulator, current) => accumulator + parseFloat(current), 0);
    return typeof value === 'number' && !isNaN(value) ? value.toFixed(2) : null;
  }

  shouldShowVapsComponent() {
    const vaps = this.getVaps();
    return vaps && vaps.length > 0;
  }

  getQuotesHeight() {
    const quotes = this.getQuotes();
    const maxQuoteHeight = 1050;
    return quotes ? quotes.length * maxQuoteHeight : 0;
  }

  getVehiclePrice() {
    const { vehicleDiscountFromListPrice } = this.getCurrentDeal();
    const quotes = this.getQuotes();
    return vehicleDiscountFromListPrice
      ? quotes[0].PlatformMeta.VehiclePrice + vehicleDiscountFromListPrice
      : quotes[0].PlatformMeta.VehiclePrice;
  }

  getOfferPrice() {
    const { vehicleDiscountFromListPrice } = this.getCurrentDeal();
    const quotes = this.getQuotes();
    return vehicleDiscountFromListPrice ? quotes[0].PlatformMeta.VehiclePrice : null;
  }

  checkForLenderStatus = (matchedVehiclesOfInterestArr, currentDeal) => {
    let matchedApplication = '';

    if (currentDeal.application && currentDeal.application.applicationId) {
      matchedApplication = matchedVehiclesOfInterestArr.map((VoiItem) =>
        VoiItem.FinanceApplications.find(
          (applicationItem) => applicationItem.ApplicantId === currentDeal.application.applicationId
        )
      );
    }

    return matchedApplication[0];
  };

  getLenderUserName(currentDeal) {
    if (this.props.appStore.userStore.fetchUserRequest.isLoading) {
      return '';
    }

    if (
      this.props.appStore.userStore.fetchUserRequest.hasError &&
      currentDeal.responsibleUserName !== 'sysadmin@ivendi.com'
    ) {
      return this.props.t('PushDealActivityContainer.dealership_user');
    }

    if (this.props.appStore.userStore.fetchUserRequest.isSuccessful) {
      return `${this.props.appStore.userStore.user.firstName} ${this.props.appStore.userStore.user.surname}`;
    }

    if (
      this.props.appStore.userStore.fetchUserRequest.hasError &&
      currentDeal.responsibleUserName === 'sysadmin@ivendi.com'
    ) {
      return this.props.t('PushDealActivityContainer.i_vendi_admin_user');
    }
  }

  render() {
    if (this.isLoading()) {
      return (
        <div className="pushDealActivityContainer__loading">
          <LoadingSpinner size="large" />
        </div>
      );
    }

    const { consumer } = this.props;
    const currentDeal = this.getCurrentDeal();
    const { canGenerateQuotes, canUsePushDeal, isBdk } = this.props.appStore.uiState;
    const quotes = this.getQuotes();
    const vaps = this.getVaps();
    const vapsTotal = this.getValueAddedProductsTotal();
    const showVapsComponent = this.shouldShowVapsComponent();
    const quotesHeight = this.getQuotesHeight();
    const vehiclePrice = this.getVehiclePrice();
    const offerPrice = this.getOfferPrice();
    const responsibleUserName = this.getLenderUserName(currentDeal);
    const { partExchange, totalDeposit } = currentDeal.quotes[0];
    const isPartExOpenDefault = partExchange > 0 ? true : false;
    const funderName = currentDeal.application && currentDeal.application.funderName;
    const matchedVehiclesOfInterest = consumer.VehiclesOfInterest.filter(
      (vehicle) => vehicle.Vrm === currentDeal.vehicle.vrm
    );
    const currentDealFinanceApplication =
      currentDeal.application && matchedVehiclesOfInterest.length > 0
        ? this.checkForLenderStatus(matchedVehiclesOfInterest, currentDeal)
        : false;
    const showCustomerApplicationItem =
      currentDealFinanceApplication && currentDealFinanceApplication.LenderStatus && currentDeal.application
        ? true
        : false;
    let newCarOffers = null;

    if (this.props.appStore.quotingStore.newCarOffers) {
      newCarOffers = this.props.appStore.quotingStore.newCarOffers;
    }

    return (
      <Page>
        <TrackPageView dealershipId={this.props.params.dealershipId} />
        <Breadcrumbs items={this.breadcrumbs} consumer={this.props.consumer} />
        <div className="pushDealActivityContainer">
          <div className="pushDealActivityContainer__dealSummary">
            <Panel>
              <VehicleDetailsSidebar currentDeal={currentDeal} vehiclePrice={vehiclePrice} offerPrice={offerPrice} />
            </Panel>
            {showVapsComponent && (
              <CollapsiblePanel
                id="vaps"
                title={this.props.t('PushDealActivityContainer.value_added_products')}
                showContent
              >
                <PushDealVapsDetails vaps={vaps} vapsTotal={vapsTotal} />
              </CollapsiblePanel>
            )}
            {!isBdk && (
              <CollapsiblePanel
                id="partEx"
                title={this.props.t('PushDealActivityContainer.part_exchange')}
                showContent={isPartExOpenDefault}
              >
                <PushDealPartExDetails currentDeal={currentDeal} />
              </CollapsiblePanel>
            )}
            <CollapsiblePanel
              id="pricingInfo"
              title={this.props.t('PushDealActivityContainer.finance')}
              showContent
              childrenHeight={quotesHeight}
            >
              <PushDealPricingDetails
                currentDeal={currentDeal}
                quotes={quotes}
                offerPrice={offerPrice}
                vehiclePrice={vehiclePrice}
                vapsTotal={vapsTotal}
                newCarOffers={newCarOffers}
                appStore={this.props.appStore}
              />
            </CollapsiblePanel>
          </div>
          <div className="pushDealActivityContainer__dealActivity">
            <Panel>
              <div className="pushDealActivityContainer__activity">
                <PanelHeader>
                  <div className="pushDealActivityContainer__header">
                    <span>{this.props.t('PushDealActivityContainer.deal_activity')}</span>

                    {canUsePushDeal && canGenerateQuotes && !canUseSendDealViaQuoteResultsPage() && (
                      <div className="pushDealActivityContainer__headerButton">
                        <TrackEvent
                          featureName="pushDeal"
                          interactionName="pushDeal__linkFromDealBreakdown"
                          linkTo={`/d/${this.props.params.dealershipId}/send-deal?consumerId=${this.props.params.consumerId}`}
                        >
                          <Button onClick={() => this.onSendDeal(currentDeal)}>
                            {this.props.t('PushDealActivityContainer.send_another_deal')}
                          </Button>
                        </TrackEvent>
                      </div>
                    )}
                  </div>
                </PanelHeader>
                <PushDealSentDetails
                  consumer={consumer}
                  currentDeal={currentDeal}
                  responsibleUserName={responsibleUserName}
                  customerId={this.props.params.consumerId}
                  vehicleId={currentDeal.dealerPlatformVehicleOfInterestId}
                />
                <div className="pushDealActivityContainer__activityTimeline">
                  <ActivityTimeline>
                    {showCustomerApplicationItem && (
                      <ActivityTimelineItem
                        id="applicationSubmitted"
                        title={this.props.t('PushDealActivityContainer.application_submitted')}
                      >
                        <ActivityTimelineContent
                          date={currentDeal.applicationSubmittedAt}
                          dataTh={'pushDealActivitySubmittedItemText'}
                        >
                          {this.props.t(
                            'PushDealActivityContainer.customer_completed_application_for_funder_name_via_the_consumer_hub',
                            {
                              funderName
                            }
                          )}{' '}
                          <div className="pushDealActivityContainer__ButtonOuter">
                            <Button
                              className="pushDealActivityContainer__ViewApplicationButton"
                              buttonStyle="secondary"
                              onClick={() =>
                                this.goToCustomerApplication(currentDeal, currentDealFinanceApplication.LenderStatus)
                              }
                            >
                              {currentDealFinanceApplication.LenderStatus === 'Not Submitted'
                                ? this.props.t('PushDealActivityContainer.view_application_and_submit')
                                : this.props.t('PushDealActivityContainer.view_application_status')}
                            </Button>
                          </div>
                        </ActivityTimelineContent>
                      </ActivityTimelineItem>
                    )}
                    {currentDeal.dealViewed && (
                      <ActivityTimelineItem
                        id="dealOpened"
                        title={this.props.t('PushDealActivityContainer.deal_opened')}
                      >
                        <ActivityTimelineContent
                          date={currentDeal.dealViewedAt}
                          dataTh={'pushDealActivityDealOpenedItemText'}
                        >
                          {this.props.t('PushDealActivityContainer.customer_viewed_the_deal_in_the_consumer_hub')}
                        </ActivityTimelineContent>
                      </ActivityTimelineItem>
                    )}
                    <ActivityTimelineItem id="dealSent" title={this.props.t('PushDealActivityContainer.deal_sent')}>
                      <ActivityTimelineContent date={currentDeal.addedAt} dataTh={'pushDealActivityDealSentItemText'}>
                        <Trans
                          ns="PushDeal"
                          i18nKey="PushDealActivityContainer.deal_sent_to_customer_based_on_requested_deposit_over_current_deal_term_quoted_months"
                          components={{
                            deposit: <MoneyFormat value={totalDeposit} />
                          }}
                          values={{
                            termQuoted: currentDeal.termQuoted
                          }}
                        />
                      </ActivityTimelineContent>
                    </ActivityTimelineItem>
                  </ActivityTimeline>
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </Page>
    );
  }
}

PushDealActivityContainer.propTypes = {
  appStore: PropTypes.object
};

export { PushDealActivityContainer };
export default compose(
  withTranslation('PushDeal'),
  withParams,
  withNavigate,
  withOutletContextProps
)(PushDealActivityContainer);
