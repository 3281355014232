import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import QuoteCard from '~/components/QuoteCard/QuoteCard';
import {
  FinanceNavigatorResultDataType,
  LoanType,
  PreApprovalType,
  QuoteCardVariantTypeEnum,
  QuoteTypes,
  StandardLoanDataType
} from '~/components/QuoteCard/types';
import { findCompanionQuoteByType } from '~/components/QuoteCard/utils/getQuoteDisplaySchema';
import { isFinanceNavigatorEligibilityQuote } from '~/components/QuoteCard/utils/quoteFeatures';
import { canSeeCashback } from '~/features';
import { AppStoreType } from '~/modules/Stock/types/Types';
import {
  formatExpandMultiScheduleQuote,
  formatFinanceQuotesApplySelected,
  trackExpandMultiScheduleQuote,
  trackFinanceQuotesApplySelected
} from '~/tracking/avoTracking';
import { FixLater } from '~/types/basic';
import AlertCard from '~Common/AlertCard/AlertCard';
import AltSelectWithLabel from '../../Common/Form/AltSelectWithLabel';
import QuotesHeader from '../../QuotesList/components/QuotesHeader';
import './quotesListTableItem.scss';

interface QuotesListTableProps {
  quotes: (StandardLoanDataType | FinanceNavigatorResultDataType)[];
  hasAdjustedBalloon: boolean;
  hasCheckedEligibility?: boolean;
  onQuoteSelect: () => void;
  onProductNameClick: () => void;
  schemesVisible: boolean;
  onProceed: (data: FixLater) => void;
  onSave: () => void;
  isQuickQuote: boolean;
  isCustomerQuote: boolean;
  sortDirection: string;
  sortField: string;
  onSortQuotes: (sortField: string) => void;
  onEligibilityMatchClick?: () => void;
  proceedingState: string;
  chosenQuoteId: string;
  vehicleClass: string;
  showCombinedQuoteClick?: () => void;
  proceedToBreakDown: () => void;
  showFullQuote: boolean;
  appStore?: AppStoreType;
  onProceedToFinanceNavigator?: () => void;
  onRequote: () => void;
  newCarOffers?: FixLater;
}

const QuotesListTable = (props: QuotesListTableProps) => {
  const { isBdk, isBnpp } = props.appStore?.uiState || {};
  const { dealershipId } = useParams();
  const { t } = useTranslation('Quoting');

  const hasRequestedCashback = props.quotes.some((quote) => (quote.RequestedCashback ?? 0) > 0);

  const hasCREQuoteResults = props.quotes.some((quote) => quote.FunderCode === 'CRE');
  const isRequotingPage = props.quotes.some((quote) => quote.isRequote);
  const isFinanceNavigatorScanResultsPage = props.quotes.some(isFinanceNavigatorEligibilityQuote);
  const hasPreApprovalQuoteResults =
    !isFinanceNavigatorScanResultsPage &&
    props.quotes.some(
      (quote) =>
        quote.PreApprovalType &&
        [PreApprovalType.InterceptorPreApproval, PreApprovalType.PreApproval].includes(quote.PreApprovalType)
    );

  const handleSortQuotes = (event: FixLater) => {
    props.onSortQuotes(event.currentTarget.value);
  };

  const handleExpandedMultiScheduleQuote = (quote: QuoteTypes, position: number, isExpanded: boolean) => {
    if (isExpanded) {
      const vehicleLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanType.vehicleLoan);
      if (vehicleLoan) {
        trackExpandMultiScheduleQuote(
          formatExpandMultiScheduleQuote({
            dealershipId: dealershipId!,
            financeQuotePosition: position,
            financeQuotesCount: props.quotes.length,
            financeQuotesSortField: props.sortField,
            QuoteId: vehicleLoan.QuoteId,
            FunderCode: vehicleLoan.FunderCode,
            FinanceType: vehicleLoan.FinanceType,
            Term: vehicleLoan.Term,
            AnnualDistanceQuoted: vehicleLoan.AnnualDistanceQuoted || 0,
            ProductId: vehicleLoan.ProductId,
            PaymentSchedule: vehicleLoan.FollowingPayments,
            TotalDeposit: vehicleLoan.TotalDeposit
          })
        );
      }
    }
  };

  const handleProceed = (data: FixLater /* This is a combination of quote and vehicle data */, index: number) => {
    if (props.onProceed) {
      props.onProceed(data);
    }
    trackFinanceQuotesApplySelected(
      formatFinanceQuotesApplySelected(
        { dealershipId: dealershipId!, ...data },
        {
          financeQuotesSortField: props.sortField,
          financeQuotesCount: props.quotes.length,
          financeQuotePosition: index + 1,
          financeQuoteJourney: data.isRequote ? 'intercepted' : 'quick_quote',
          eventTrigger: 'onProceed',
          eventTriggerText: 'Proceed'
        }
      )
    );
  };

  // TODO: Write tests - See if we can use the URL to determine the quote card variant
  const getQuoteCardVariant = () => {
    if (isRequotingPage) {
      return QuoteCardVariantTypeEnum.Requote;
    }
    if (isFinanceNavigatorScanResultsPage) {
      return QuoteCardVariantTypeEnum.FinanceNavigatorCheck;
    }
    return QuoteCardVariantTypeEnum.FinanceQuote;
  };
  const quoteVariant = getQuoteCardVariant();
  return (
    <div>
      {!isRequotingPage && (
        <>
          <QuotesHeader>
            <AltSelectWithLabel
              label={t('QuotesListTable.sort_by')}
              value={props.sortField}
              options={props.hasCheckedEligibility ? 'CarFinanceChecksSortOptions' : 'QuotesListSortOptions'}
              handleSort={handleSortQuotes}
            />
          </QuotesHeader>
          <div className="quotesListTableItem__alerts">
            {props.hasAdjustedBalloon && isBdk && (
              <AlertCard styles="alert" iconName="alert" paragraph={t('QuotesListTable.balloon_adjusted')} />
            )}
            {hasRequestedCashback && hasCREQuoteResults && canSeeCashback() && !isBnpp && (
              <AlertCard
                styles="information"
                iconName="information"
                paragraph={t('QuotesListTable.cashback_unavailable')}
              />
            )}
            {hasPreApprovalQuoteResults && (
              <AlertCard
                styles="information"
                iconName="information"
                title={t('QuotesListTable.pre_approval_available')}
                paragraph={t('QuotesListTable.pre_approval_available_details')}
              />
            )}
          </div>
        </>
      )}
      <div className="quotesListTableItem__outer">
        {props.quotes.map((quote, index) => {
          const newCarOffer =
            props.newCarOffers &&
            Array.isArray(props.newCarOffers) &&
            props.newCarOffers.find((offer) => offer.ProductUid === quote.ProductId);

          return (
            <QuoteCard
              variant={quoteVariant}
              quote={quote}
              showFullQuote={props.showFullQuote}
              schemesVisible={props.schemesVisible}
              actions={{
                onSave: props.onSave,
                onProceed: (data: QuoteTypes) => handleProceed(data, index),
                onProceedToBreakDown: props.proceedToBreakDown,
                onProductNameClick: props.onProductNameClick,
                onQuoteSelect: props.onQuoteSelect,
                onCombinedQuoteClick: props.showCombinedQuoteClick,
                onProceedToFinanceNavigator: props.onProceedToFinanceNavigator,
                onRequote: props.onRequote
              }}
              isQuickQuote={props.isQuickQuote}
              vehicleClass={props.vehicleClass}
              isCustomerQuote={props.isCustomerQuote}
              chosenQuoteId={props.chosenQuoteId}
              proceedingState={props.proceedingState}
              newCarOffer={newCarOffer}
              hasCheckedEligibility={props.hasCheckedEligibility}
              key={`${quoteVariant}-${quote.QuoteId}`}
              toggleMultiScheduleComponents={(isExpanded) => {
                const quoteListPosition = index + 1;
                handleExpandedMultiScheduleQuote(quote, quoteListPosition, isExpanded);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default inject('appStore')(QuotesListTable);
