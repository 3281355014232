import PropTypes from 'prop-types';
import cn from 'classnames';
import './panelHeader.scss';

const PanelHeader = ({ children, className, noBorder = false, dataHook = '' }) => (
  <div className={cn('panelHeader__wrapper', className)}>
    <div className={cn('panelHeader__title', { 'no-border': noBorder })}>
      <div className="panelHeader__inner" data-th={`${dataHook}PanelHeader`}>
        {children}
      </div>
    </div>
  </div>
);

PanelHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default PanelHeader;
