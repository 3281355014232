import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import moment from 'moment';
import { inject } from 'mobx-react';

import PanelSection from '~Common/PanelSection';
import Button from '~Common/Button/Button';
import Modal from '~Common/Modal/Modal';

import { VehicleInfoList } from './components';

import './keyInformationSection.scss';
import { useFormContext } from 'react-hook-form';
import { isBike, transformVehicleClass } from '~/utils/vehicle';

const KeyInformationSection = ({ onClick, dealerPlatformUpload, appStore }) => {
  const { dateFormat } = appStore.uiState;
  const { t } = useTranslation('Stock');
  const { watch } = useFormContext();
  const [isTaxonomyModalOpen, setIsTaxonomyModalOpen] = useState(false);

  const showTaxonomyModal = () => {
    setIsTaxonomyModalOpen(true);
  };

  const closeTaxonomyModal = () => {
    setIsTaxonomyModalOpen(false);
  };

  const handleTaxonomyChange = () => {
    onClick();
  };

  const klass = transformVehicleClass(watch('class'));
  const hideTaxonomy = isBike(klass);
  const taxonomyInformation = [
    {
      label: t('KeyInformationSection.class'),
      value: klass.toUpperCase()
    },
    {
      label: t('KeyInformationSection.make'),
      value: watch('make')
    },
    {
      label: t('KeyInformationSection.model'),
      value: watch('model')
    },
    {
      label: t('KeyInformationSection.derivative'),
      value: watch('derivative')
    },
    {
      label: t('KeyInformationSection.body_style'),
      value: watch('bodyStyle'),
      disabled: hideTaxonomy
    },
    {
      label: t('KeyInformationSection.transmission'),
      value: watch('transmission')
    },
    {
      label: t('KeyInformationSection.fuel_type'),
      value: watch('fuelType'),
      disabled: hideTaxonomy
    },
    {
      label: t('KeyInformationSection.seats'),
      value: watch('seats'),
      disabled: hideTaxonomy
    },
    {
      label: t('KeyInformationSection.doors'),
      value: watch('doors') || '-',
      disabled: hideTaxonomy
    }
  ];

  const vehicleMiscInfo = [
    { label: t('KeyInformationSection.modified_on'), value: moment(watch('lastModifiedEpoch')).format(dateFormat) },
    { label: t('KeyInformationSection.created_on'), value: moment(watch('dateCreatedEpoch')).format(dateFormat) },
    { label: t('KeyInformationSection.source'), value: watch('mediaCompanyName'), disabled: dealerPlatformUpload }
  ];
  return (
    <>
      <PanelSection
        title={t('KeyInformationSection.key_information')}
        grayStyle
        className="keyInformationSection_panel"
      >
        {dealerPlatformUpload && (
          <div className="keyInformationSection__editButton">
            <Button onClick={showTaxonomyModal} size="small" buttonStyle="secondary">
              {t('KeyInformationSection.change_vehicle')}
            </Button>
          </div>
        )}

        <VehicleInfoList list={taxonomyInformation.filter(({ disabled }) => !disabled)} />
        <h2 className="keyInformationSection__heading"> {t('KeyInformationSection.misc')}</h2>
        <VehicleInfoList list={vehicleMiscInfo.filter(({ disabled }) => !disabled)} />
      </PanelSection>

      <Modal
        title={t('KeyInformationSection.vehicle_taxonomy_data_change')}
        onClose={closeTaxonomyModal}
        isOpen={isTaxonomyModalOpen}
        onConfirm={handleTaxonomyChange}
        buttonText={t('KeyInformationSection.change_taxonomy')}
      >
        <div className="keyInformationSection__confirmationModal">
          <p>
            {t(
              'KeyInformationSection.vehicle_taxonomy_or_vehicle_base_details_make_model_derivative_etc_are_based_on_the_unique_vehicle_id_and_set_by_the_manifacturer_these_details_are_important_for_the_correct_vehicle_listing_on_the_website'
            )}
          </p>
          <p>{t('KeyInformationSection.if_you_proceed_you_will_have_to_search_and_select_new_vehicle_taxonomy')}</p>
        </div>
      </Modal>
    </>
  );
};

export default inject(['appStore'])(KeyInformationSection);
