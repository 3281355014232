import { useMemo } from 'react';

import { useVehicleData } from '../../services';

export type MediaCompanyVehicleData = ReturnType<typeof useSortedVehicleData>['mediaCompanyVehicleData'];
export type IVendiCompanyVehicleData = ReturnType<typeof useSortedVehicleData>['iVendiCompanyVehicleData'];
const useSortedVehicleData = ({ vehicleId, dealershipId }: {vehicleId:string, dealershipId:string}) => {
  // @ts-expect-error - isIdle is not recognised but exists in node_modules/@tanstack/query-core/build/lib/types.d.ts
  const { isLoading, isError, data, isIdle } = useVehicleData(dealershipId, vehicleId);
  const loadingState = useMemo(() => {
    if (isIdle) return 'idle';
    if (isLoading) return 'loading';
    if (isError) return 'error';
  }, [isIdle, isLoading, isError]);

  const mediaCompanyVehicleData = useMemo(() => {
    const stockTaxonomyObject = data?.taxonomies?.find((taxonomy) => taxonomy?.origin?.toLowerCase() === 'stock');
    return {
      vrmYear: data?.advertInformation?.registrationDate,
      mileage: data?.advertInformation?.mileage,
      price: data?.advertInformation?.price,
      dealerReference: data?.dealer?.dealerReference,
      ...stockTaxonomyObject, // class, capId,
      ...data?.identity, // vrm, vin, condition
      ...data?.suppliedTaxonomyDetails
    };
  }, [data]);

  const iVendiCompanyVehicleData = useMemo(() => {
    const stockTaxonomyObject = data?.taxonomies?.find((taxonomy) => taxonomy?.origin?.toLowerCase() === 'dealer');
    return {
      ...stockTaxonomyObject,
      ...data?.taxonomyDetails
    };
  }, [data]);

  return {
    mediaCompanyVehicleData,
    iVendiCompanyVehicleData,
    loadingState: loadingState,
    dealerPlatformUpload: undefined,
    taxonomyQuery: data?.taxonomyQuery
  };
};


export default useSortedVehicleData;
