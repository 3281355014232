import { useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import { compose } from 'redux';
import { push } from 'routerHistory';

import { getCustomer } from '../../../selectors/customerSelectors';
import { savedQuoteSelector, vehicleOfInterestSelector } from '../selectors/savedQuoteSelectors';
import * as applicationActions from '../../../redux/application/applicationActions';
import observerForHooks from 'hocs/observerForHooks';

import Breadcrumbs from '../../Common/Breadcrumbs';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import MiniPanel from '../../Common/MiniPanel';
import MiniPanelHeader from '../../Common/MiniPanelHeader';
import VehiclePanel from '../../Reporting/PartialSummarySections/VehiclePanel';
import AccessoriesPanel from '../../Reporting/PartialSummarySections/AccessoriesPanel';
import DealerInfo from '../../Common/DealerInfo';
import FormFooter from '../../Common/Form/FormFooter';
import ErrorApology from '../../Common/ErrorApology';
import QuotePanelV2 from '../../Reporting/PartialSummarySections/QuotePanelV2';
import ConsumerDutyContainer from '../../ConsumerDuty/containers/ConsumerDutyContainer';

import { withParams } from 'hocs/router';
import { printPageByRef } from '~/core/print';
import { findCompanionQuoteByType } from '~/components/QuoteCard/utils/getQuoteDisplaySchema';
import { hasCompanionQuotes } from '~/features';
import { useGetSavedQuoteCtaLabel } from '../hooks';

import { AppStore } from '~/modules/Stock/types/Types';
import { LoanType, QuoteSummaryVariantTypeEnum } from '~/components/QuoteCard/types';
import { CustomerVehicleOfInterest } from '~/types/vehicle';
import { ProceedingStateType } from '~/redux/quote/types';
import { ApplicationQuote } from '~/types/application';
import { Dealership } from '~/types/dealership';
import { FixLater } from '~/types/basic';
import { CustomerEntityStateType } from '~/types/customer';
import { isFnTypeQuote } from '~/components/QuoteCard/utils/quoteFeatures';

export type SavedQuoteDetailsPageProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & { appStore: AppStore };

const SavedQuoteDetailsPage = ({
  consumer,
  dealership,
  vehicle,
  quote: rootQuote,
  appStore,
  proceedingState,
  handleApply,
  handleCheckEligibility
}: SavedQuoteDetailsPageProps) => {
  const { t } = useTranslation('Reporting');
  const [showFairProcessing, setShowFairProcessing] = useState(false);
  const { dealershipId, consumerId } = useParams();

  const ctaLabel = useGetSavedQuoteCtaLabel(rootQuote);
  const printAreaRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    printPageByRef(printAreaRef);
  };

  const handleCloseFairProcessingModal = () => {
    setShowFairProcessing(false);
  };

  const handleSubmitFairProcessingModal = () => {
    handleApply(vehicle, rootQuote, consumer.CustomerType);
  };

  const handleSubmit = () => {
    // When action is Check Eligibility - for a finance navigator quote
    if (isFnTypeQuote(rootQuote)) {
      // Start finance navigator by setting state up and navigate to it
      handleCheckEligibility(rootQuote, vehicle.VehicleId);
    } else {
      // When action is Apply - for a regular application
      setShowFairProcessing(true);
    }
  };

  if (!rootQuote) {
    return <ErrorApology>Quote not found.</ErrorApology>;
  }

  if (!vehicle) {
    return <ErrorApology>Vehicle not found.</ErrorApology>;
  }

  let vehicleLoan;
  let vapsLoan;
  let negativeEquityLoan;
  if (hasCompanionQuotes(rootQuote)) {
    vehicleLoan = findCompanionQuoteByType(rootQuote.CompanionQuotes, LoanType.vehicleLoan);
    vapsLoan = findCompanionQuoteByType(rootQuote.CompanionQuotes, LoanType.vapsLoan);
    negativeEquityLoan = findCompanionQuoteByType(rootQuote.CompanionQuotes, LoanType.negativeEquityLoan);
  }

  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('SavedQuoteDetailsPage.home'),
            path: `/d/${dealershipId}`
          },
          {
            name: t('SavedQuoteDetailsPage.customer_list'),
            path: `/d/${dealershipId}/consumers`
          },
          {
            name: 'Consumer',
            path: `/d/${dealershipId}/consumers/${consumerId}`
          },
          {
            name: t('SavedQuoteDetailsPage.saved_quote')
          }
        ]}
        consumer={consumer}
      />
      {showFairProcessing ? (
        <ConsumerDutyContainer
          dealershipId={dealershipId}
          chosenQuote={rootQuote} // Missing (funder)ProductId and (funder)ProductCode
          customerType={consumer.CustomerType}
          onCancel={handleCloseFairProcessingModal}
          onContinue={handleSubmitFairProcessingModal}
        />
      ) : (
        <Panel noMargin={false}>
          <PanelHeader>{t('SavedQuoteDetailsPage.saved_quote_details')}</PanelHeader>
          <PanelContent>
            <div className="printArea" ref={printAreaRef}>
              <DealerInfo dealership={dealership} />
              <MiniPanel>
                <MiniPanelHeader title={t('SavedQuoteDetailsPage.vehicle')} />
                <VehiclePanel vehicle={vehicle} quote={rootQuote} />
              </MiniPanel>
              {rootQuote.Insurance === 0 &&
              rootQuote.Warranty === 0 &&
              rootQuote.OtherAccessories === 0 &&
              rootQuote.NonVatableItems === 0 ? (
                ''
              ) : (
                <MiniPanel>
                  <MiniPanelHeader title={t('SavedQuoteDetailsPage.vehicle_accessories')} />
                  <AccessoriesPanel quote={rootQuote} />
                </MiniPanel>
              )}
              {/* Standard or Aggregate Quote START */}
              <MiniPanel>
                <MiniPanelHeader title={t('SavedQuoteDetailsPage.quote')} />
                <QuotePanelV2
                  quote={rootQuote}
                  loanType={LoanType.standardLoan}
                  variant={QuoteSummaryVariantTypeEnum.SavedQuoteSummary}
                  hasNegativeEquityLoan={false}
                />
              </MiniPanel>

              {/* Standard or Aggregate Quote END */}

              {/* Companion Quotes START */}
              {vehicleLoan && (vapsLoan || negativeEquityLoan) && (
                <MiniPanel>
                  <h3 className="savedQuoteDetailsPage__multiloansHeader">
                    {t('SavedQuoteDetailsPage.multiple_finance_components')}
                  </h3>
                  <MiniPanelHeader title={t('SavedQuoteDetailsPage.vehicle_loan')} />
                  <QuotePanelV2
                    quote={vehicleLoan}
                    loanType={LoanType.vehicleLoan}
                    variant={QuoteSummaryVariantTypeEnum.SavedQuoteSummary}
                    hasNegativeEquityLoan={negativeEquityLoan !== undefined}
                  />
                </MiniPanel>
              )}
              {negativeEquityLoan && (
                <MiniPanel>
                  <MiniPanelHeader title={t('SavedQuoteDetailsPage.negative_equity_loan')} />
                  <QuotePanelV2
                    quote={negativeEquityLoan}
                    loanType={LoanType.negativeEquityLoan}
                    variant={QuoteSummaryVariantTypeEnum.SavedQuoteSummary}
                    hasNegativeEquityLoan={negativeEquityLoan !== undefined}
                  />
                </MiniPanel>
              )}
              {vapsLoan && (
                <MiniPanel>
                  <MiniPanelHeader title={t('SavedQuoteDetailsPage.value_added_products_loan')} />
                  <QuotePanelV2
                    quote={vapsLoan}
                    loanType={LoanType.vapsLoan}
                    variant={QuoteSummaryVariantTypeEnum.SavedQuoteSummary}
                    hasNegativeEquityLoan={negativeEquityLoan !== undefined}
                  />
                </MiniPanel>
              )}
              {/* Companion Quotes END */}
            </div>
            <FormFooter
              submitLabel={ctaLabel}
              hideSubmit={!appStore.uiState.canCreateApplications}
              onSubmit={handleSubmit}
              submittingState={proceedingState}
              onPrint={handlePrint}
            />
          </PanelContent>
        </Panel>
      )}
    </Page>
  );
};

function mapStateToProps(state: FixLater, ownProps: FixLater) {
  // ownProps.params come from React Router which is available through the withParams HOC
  const { quoteId, consumerId } = ownProps.params;
  const savedQuote = savedQuoteSelector(state, { quoteId, consumerId });
  const customer: CustomerEntityStateType = getCustomer(state, consumerId);

  return {
    proceedingState: state.quotes.proceedingState as ProceedingStateType,
    consumer: customer,
    quote: savedQuote.Quote as ApplicationQuote,
    dealership: (state.dealership as Dealership) || {},
    vehicle: vehicleOfInterestSelector(state, { quoteId, consumerId }) as CustomerVehicleOfInterest
  };
}

function mapDispatchToProps(dispatch: FixLater, ownProps: FixLater) {
  return {
    handleCheckEligibility: async (quote: ApplicationQuote, vehicleId: string) => {
      const { dealershipId, consumerId } = ownProps.params;
      push(`/d/${dealershipId}/consumers/${consumerId}/vehicle/${vehicleId}/quote/${quote.QuoteId}`);
    },
    handleApply: (vehicle: CustomerVehicleOfInterest, quote: ApplicationQuote, customerType: string) =>
      dispatch(applicationActions.createApplication(vehicle, quote, ownProps.params.consumerId, customerType))
  };
}

export default compose(
  withParams,
  connect(mapStateToProps, mapDispatchToProps),
  // @ts-expect-error - No idea why inject complains, it's given exactly what it expects
  inject(['appStore']),
  observerForHooks
)(SavedQuoteDetailsPage);
