import _ from 'lodash';
import { createSelector } from 'reselect';
import appStore from '../../../mobx-stores/appStore';
import { mapToFinanceNavigatorResultQuote } from '~/components/FinanceNavigator/utils/financeNavigatorUtils';
import { FinanceNavigatorResult } from '~/components/FinanceNavigator/types/financeNavigatorResultMessage';

import { ApplicationQuote } from '~/types/application';
import {
  CustomerCfcProspectResultSet,
  CustomerDealerNote,
  CustomerDisplayFinanceApplication,
  CustomerDisplaySelfServiceCodeSet,
  CustomerDisplaySortFilters,
  CustomerEntityStateType,
  CustomerFinanceScanSet,
  CustomerSavedQuoteSet,
  CustomerUiStateType,
  VehicleSortFilterDetails
} from '~/types/customer';
import { FixLater } from '~/types/basic';
import User from '~/mobx-models/User';
import { CustomerVehicleOfInterest } from '~/types/vehicle';

type CustomerDetailsWithoutEntities = Omit<
  CustomerEntityStateType,
  | 'AssignedTo'
  | 'VehiclesOfInterest'
  | 'CfcProspectResultSets'
  | 'FinanceApplications'
  | 'SavedQuotes'
  | 'SelfServiceCodes'
  | 'DealerNotes'
  | 'PushDeals'
  | 'FinanceScans'
> & {};

type CustomerDisplayVehicleOfInterest = CustomerVehicleOfInterest & {
  FinanceApplications: CustomerDisplayFinanceApplication;
  CfcProspects: CustomerCfcProspectResultSet[];
  FinanceScans: CustomerFinanceScanSet[];
  SavedQuotes: CustomerSavedQuoteSet[];
  PushDeals: any[]; // Don't think this is used
  DealerNotes: CustomerDealerNote[] | [];
  SortFilters: CustomerDisplaySortFilters;
  SelfServiceCodes: CustomerDisplaySelfServiceCodeSet[]; // This is the actual push deal array
};
type DenormalizedCustomerType = CustomerDetailsWithoutEntities & {
  VehiclesOfInterest: CustomerDisplayVehicleOfInterest[];
  assignedTo: [] | User[];
};
export const getCustomer = (state: FixLater): CustomerEntityStateType => state.entities.customers[state.ui.customer.Id];
export const getCustomerUi = (state: FixLater) => state.ui.customer;
export const denormaliseCustomer = createSelector(
  // @ts-expect-error - TODO: Fix this later
  [getCustomer, getCustomerUi],
  (customer: CustomerEntityStateType, customerUi: CustomerUiStateType): DenormalizedCustomerType | {} => {
    if (!customer) {
      return {};
    }

    let {
      AssignedTo,
      VehiclesOfInterest,
      CfcProspectResultSets,
      FinanceApplications,
      SavedQuotes,
      SelfServiceCodes,
      DealerNotes,
      PushDeals,
      FinanceScans,
      ...customerDetails
    } = customer;

    const vehicles = _.map(VehiclesOfInterest, (vehicle) => {
      const sortFilters = _.get(customerUi, `VehicleSortFilters[${vehicle.VehicleId}]`, {});

      const financeApplicationFilter = _.get(sortFilters, 'FinanceApplications', {}) as VehicleSortFilterDetails;
      const savedQuickQuoteFilter = _.get(sortFilters, 'SavedQuotes', {}) as VehicleSortFilterDetails;
      const cfcprospectsFilter = _.get(sortFilters, 'CfcProspects', {}) as VehicleSortFilterDetails;
      const pushDealFilter = _.get(sortFilters, 'PushDeals', {}) as VehicleSortFilterDetails;

      let financeApplications = _.chain(FinanceApplications)
        .filter({ VehicleId: vehicle.VehicleId })
        .orderBy(financeApplicationFilter.sortField, financeApplicationFilter.sortDirection)
        .map(
          (application) =>
            Object.assign({}, application, {
              isSelfService: application.ClientApp === 'motonovo-fass-consumer'
            }) as CustomerDisplayFinanceApplication
        )
        .value();

      let PushDeals =
        _.chain(customer.PushDeals)
          .filter({ VehicleId: vehicle.VehicleId })
          .orderBy(pushDealFilter.sortField, pushDealFilter.sortDirection)
          .value() || [];

      let cfcResults = _.chain(CfcProspectResultSets)
        .cloneDeep()
        .filter({ VehicleId: vehicle.VehicleId })
        .orderBy('Created', 'desc')
        .tap((cfcResults) => {
          _.each(cfcResults, (resultSet) => {
            resultSet.Quotes = _.orderBy(
              resultSet.Quotes,
              cfcprospectsFilter.sortField,
              cfcprospectsFilter.sortDirection
            );
          });
        })
        .value();

      let savedQuotes = _.chain(SavedQuotes)
        .filter({ VehicleId: vehicle.VehicleId })
        .orderBy(savedQuickQuoteFilter.sortField, savedQuickQuoteFilter.sortDirection)
        .value();

      let selfServiceCodes = _.chain(SelfServiceCodes)
        .cloneDeep()
        .filter({ VehicleId: vehicle.VehicleId })
        .orderBy('SavedDate', 'desc')
        .map((deal) => {
          let firstQuote = _.get(deal, 'Quotes[0]', {}) as ApplicationQuote;
          return {
            CashPrice: firstQuote.CashPrice,
            CashDeposit: firstQuote.Deposit - firstQuote.PartExchange + firstQuote.OutstandingSettlement,
            PartExchange: firstQuote.PartExchange,
            OutstandingSettlement: firstQuote.OutstandingSettlement,
            BalancePayable: firstQuote.BalancePayable,
            ...deal
          } as CustomerDisplaySelfServiceCodeSet;
        })
        .value();

      let financeNavigatorResults = _.chain(FinanceScans)
        .cloneDeep()
        .filter({ VehicleId: vehicle.VehicleId })
        .orderBy('CreatedDate', 'desc')
        .tap((financeScans) => {
          _.each(financeScans, (resultSet) => {
            resultSet.Results = (resultSet.Results as FinanceNavigatorResult[])
              .filter((x) => x.IsSuccess)
              .map(mapToFinanceNavigatorResultQuote);
          });
        })
        .value();

      return {
        ...vehicle,
        FinanceApplications: financeApplications,
        CfcProspects: cfcResults,
        FinanceScans: financeNavigatorResults,
        SavedQuotes: savedQuotes,
        PushDeals: PushDeals,
        DealerNotes: _.filter(DealerNotes, { VehicleId: vehicle.VehicleId }),
        SortFilters: sortFilters,
        SelfServiceCodes: selfServiceCodes
      };
    });

    return {
      VehiclesOfInterest: vehicles,
      ...customerDetails,
      assignedTo: AssignedTo ? AssignedTo.map((user) => appStore.userStore.updateUserFromJSON(user)) : []
    };
  }
);

export const currentVehicleTabIndex = createSelector(
  // @ts-expect-error - TODO: fix this later
  [getCustomer, getCustomerUi],
  (customer: CustomerEntityStateType, customerUi: CustomerUiStateType) => {
    if (!customer) {
      return 0;
    }

    let index = _.findIndex(customer.VehiclesOfInterest, { VehicleId: customerUi.selectedVehicleId });

    return index < 0 ? 0 : index;
  }
);
