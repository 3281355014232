import { useTranslation } from 'react-i18next';
import s from './advertisedOn.module.scss';
import { AdmiralLogo } from './logos/admiral';
import { ClassicCarsForSale } from './logos/ccfs';
import { McnLogo } from './logos/mcn';

interface AdvertisedOnProps {
  shouldDisplayAdmiralLogo: boolean;
  tagList: string[];
}
export const AdvertisedOn = ({ shouldDisplayAdmiralLogo, tagList }: AdvertisedOnProps) => {
  const { t } = useTranslation('Stock');

  const renderLogos = (tag = '') => {
    switch (tag?.toLowerCase()) {
      case 'marketplace-ccfs':
        return <ClassicCarsForSale />;
      case 'marketplace-mcn':
        return <McnLogo />;
      default:
        return null;
    }
  };
  const logos = tagList?.map((tag) => renderLogos(tag));

  if (logos?.filter((logo) => !!logo)?.length === 0 && !shouldDisplayAdmiralLogo) {
    // nothing to display, including Advertised on: label
    return null;
  }
  return (
    <div className={s['advertisedOn']}>
      <span>{t('StockListItem.advertised_on')}:</span> {shouldDisplayAdmiralLogo && <AdmiralLogo />}
      {logos}
    </div>
  );
};
