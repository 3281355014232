import { Trans, useTranslation } from 'react-i18next';
import Button from '../../../Common/Button/Button';
import EligibilityModalGaugeSection from '../../components/EligibilityModalGaugeSection';
import Modal from '../../../Common/Modal/Modal';
import { FNEligibilityModalContentType } from './types';
import { getFNEligibilityModalContent } from './utils';
import {
  DisplayQuoteFormat,
  EligibilityOffered,
  FinanceNavigatorEligibilityDataType
} from '~/components/QuoteCard/types';
import './financeNavigatorEligibilityModal.scss';
import FormatAPR from '../../components/FormatAPR';
import MoneyFormat from '~/components/Common/MoneyFormat';

export const FinanceNavigatorEligibilityModalContents = (fnModalContents: FNEligibilityModalContentType) => {
  const { t } = useTranslation('Quoting');

  if (!fnModalContents) {
    return null;
  }

  const { message, header, indicativeText, lenderData, valuesNowDifferent } = fnModalContents;

  return (
    <main className="financeNavigatorEligibilityModalContents" data-th="FinanceNavigatorEligibilityModalContents">
      <EligibilityModalGaugeSection
        // lenderData?.Eligibility will only be null on errored scan results which we currently do not display
        fnEligibilityStatus={lenderData?.Eligibility || EligibilityOffered.Unknown}
        header={header && t(`FinanceNavigatorEligibilityModal.${header}`)}
      />

      {valuesNowDifferent.Apr?.hasChanged && (
        <p data-th="financeNavigatorEligibilityModalContents__AprChanged">
          <Trans
            ns="Quoting"
            i18nKey="FinanceNavigatorEligibilityModal.please_note_change"
            components={{
              span: <span key={`importantFigure-APR`} className="quoteCard__importantFigure" />,
              original: <FormatAPR key={`originalFigure-APR`} value={valuesNowDifferent.Apr.original!.toString()} />,
              adjusted: <FormatAPR key={`adjustedFigure-APR`} value={valuesNowDifferent.Apr.current.toString()} />
            }}
          />
        </p>
      )}

      {lenderData?.MaxMonthlyBudget && lenderData?.Eligibility !== 'High' && (
        <p data-th="financeNavigatorEligibilityModalContents__maxMonthlyPayment">
          <Trans
            ns="Quoting"
            i18nKey={'FinanceNavigatorEligibilityModal.max_monthly_payment_text'}
            components={{
              span: <span key={`importantFigure-MonthlyPayment`} className="quoteCard__importantFigure" />,
              maxMonthlyPayment: (
                <MoneyFormat key={`adjustedFigure-MonthlyPayment`} value={lenderData?.MaxMonthlyBudget} />
              )
            }}
          />
        </p>
      )}

      {message && (
        <section className="financeNavigatorEligibilityModalContents__lenderMessages" data-th="lender-messages">
          <p>{message}</p>
        </section>
      )}

      {indicativeText && (
        <p className="financeNavigatorEligibilityModalContents__indicative" data-th="indicative-text">
          {t(`FinanceNavigatorEligibilityModal.${indicativeText}`)}
        </p>
      )}
    </main>
  );
};

type FNEligibilityResultModalPropsType = {
  onClose: () => void;
  isOpen: boolean;
  quote: DisplayQuoteFormat;
  eligibility: FinanceNavigatorEligibilityDataType;
};

const FinanceNavigatorEligibilityModal = ({
  isOpen,
  onClose,
  quote,
  eligibility
}: FNEligibilityResultModalPropsType) => {
  const { t } = useTranslation('Quoting');
  const fnModalContents = getFNEligibilityModalContent(quote, eligibility);
  const modalTitle = fnModalContents?.title ? t(`FinanceNavigatorEligibilityModal.${fnModalContents?.title}`) : null;
  return (
    <Modal isOpen={isOpen} onClose={onClose} id="financeNavigatorEligibility" title={modalTitle}>
      {fnModalContents && <FinanceNavigatorEligibilityModalContents {...fnModalContents} />}
      <footer>
        <Button type="button" onClick={() => onClose()} buttonStyle="cancel">
          {t('FinanceNavigatorEligibilityModal.close')}
        </Button>
      </footer>
    </Modal>
  );
};

export default FinanceNavigatorEligibilityModal;
