import Gauge from '../../Common/Gauge';
import { EligibilityStatusPropsType } from '../modals/FinanceNavigatorEligibilityModal/types';
import './eligibilityModalGaugeSection.scss';

type EligibilityModalGaugeSectionProps = EligibilityStatusPropsType & {
  header: string;
  paragraphOne?: string | React.ReactNode;
  paragraphTwo?: string | React.ReactNode;
  paragraphThree?: string | React.ReactNode;
  paragraphFour?: string | React.ReactNode;
};

const EligibilityModalGaugeSection = ({
  cfcMatchRate = null,
  fnEligibilityStatus = null,
  header,
  paragraphOne,
  paragraphTwo,
  paragraphThree,
  paragraphFour
}: EligibilityModalGaugeSectionProps): JSX.Element | null => {
  if (!cfcMatchRate && !fnEligibilityStatus) {
    return null;
  }
  const gaugeProps: EligibilityStatusPropsType =
    cfcMatchRate !== null
      ? { cfcMatchRate, fnEligibilityStatus: null }
      : { cfcMatchRate: null, fnEligibilityStatus: fnEligibilityStatus! };

  return (
    <section data-th="eligibilityModalGaugeSection" className="eligibilityModalGaugeSection">
      <div className="eligibilityModalGaugeSection__upper">
        <div className="eligibilityModalGaugeSection__gaugeWrapper">
          <Gauge {...gaugeProps} />
        </div>
        <div className="eligibilityModalGaugeSection__initialText">
          <h3 className="eligibilityModalGaugeSection__decisionText">{header}</h3>
          {paragraphOne && <p>{paragraphOne}</p>}
          {paragraphTwo && <p>{paragraphTwo}</p>}
        </div>
      </div>
      <div>
        {paragraphThree && <p>{paragraphThree}</p>}
        {paragraphFour && <p>{paragraphFour}</p>}
      </div>
    </section>
  );
};

export default EligibilityModalGaugeSection;
