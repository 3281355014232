import { RawVisResponse } from '~/api/visVehicles';
import { useVisLookupContext } from '../../context/VisLookupContext';

const useMetaData = () => {
  // TODO: convert /src/components/Common/VisLookup/context/VisLookupContext.js and move types there
  const { state } = useVisLookupContext() as { state: UseVisLookupContext };
  const vehicles = state.searchResults && state.searchResults.errorCode === null ? state.searchResults.response : [];
  const selectedVehicle =
    (state.selectedVehicleId &&
      vehicles.length > 0 &&
      vehicles.filter((vehicle) => vehicle?.objectID === state.selectedVehicleId)) ||
    [];
  const hasResults = vehicles.length > 0 && state.searchState === 'idle';
  const noResults = state.searchResults && state.searchResults.numberOfRecords === 0;

  return {
    hasResults,
    noResults,
    vehicles,
    selectedVehicle,
    touched: state.touched
  };
};

export default useMetaData;


export interface UseVisLookupContext {
  page: number
  query: string
  debouncedQuery: string
  searchState: string
  searchResults: SearchResults
  selectedVehicleId: string
  facetFilters: any[]
  capIds: any[]
  touched: boolean
}

export interface SearchResults {
  numberOfRecords: number
  currentPage: number
  numberOfPages: number
  previousPageUrl: any
  nextPageUrl: string
  errorCode: any
  response: RawVisResponse[]
}


