import React, { ReactElement } from 'react';
import Icon from '~/components/Common/Icon/Icon';
import cn from 'classnames';

import styles from './fixissuesAlertCard.module.scss';

interface FixIssuesAlertCardProps {
  title: string
  paragraph: string
  iconName: string
  className?: string
  children?: ReactElement
}
const FixIssuesAlertCard = ({ title, paragraph, iconName, className, children }:FixIssuesAlertCardProps) => {
  return (
    <div
      className={cn(styles.card, className, {
        [styles['card--warning']]: iconName === 'warning',
        [styles['card--alert']]: iconName === 'alert'
      })}
    >
      <Icon name={iconName} />
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.paragraph}>{paragraph}</p>
        {children}
      </div>
    </div>
  );
};

export default FixIssuesAlertCard;
