import { convertMarkdownToHtml } from '../../core/helpers';

const LEGAL_DOCS_API = '/legal-docs';

// Fair Processing
export const getLenderPrivacyDocs = async (funderCode: string) => {
  try {
    const response = await fetch(`${LEGAL_DOCS_API}/lender/fpn/${funderCode}`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    const result = await response.text();
    const { html, metadata } = convertMarkdownToHtml(result);

    return {
      name: funderCode,
      html,
      metadata
    };
  } catch (e) {
    console.log(e);
  }
};

export const getPrivacyPolicyDocs = async (countryCode: 'gb' | 'de' | 'fr') => {
  const languageCode = countryCode === 'gb' ? 'en' : countryCode;
  const response = await fetch(`${LEGAL_DOCS_API}/dp/${countryCode}/privacy-policy-${languageCode}`, {
    headers: {
      'Content-Type': 'text/plain'
    }
  });
  if (response.ok) {
    const result = await response.text();
    const { html, metadata } = convertMarkdownToHtml(result);
    return {
      html,
      metadata
    };
  }
  throw new Error(response.statusText);
};
