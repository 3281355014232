import { CustomerFinanceApplication } from '~/types/application';
import { QuoteTypes } from '../types';

export type QuoteCardConfig = {
  showCfcStatus: boolean;
  showCheckbox: boolean;
  showCreatedDate: boolean;
  showExpiryDate: boolean;
  showExpired: boolean;
  title: string;
};
export type QuoteCardConfigProps = {
  quote: QuoteTypes;
  application?: CustomerFinanceApplication;
};

const defaultConfig: QuoteCardConfig = {
  showCfcStatus: false,
  showCheckbox: false,
  showCreatedDate: false,
  showExpiryDate: false,
  showExpired: false,
  title: 'QuoteCardTable.personalised_quote'
};

export default defaultConfig;
