import Table from './SummaryTable/Table';
import TableRow from './SummaryTable/TableRow';
import OuterLeft from './SummaryTable/OuterLeft';
import OuterRight from './SummaryTable/OuterRight';
import TableHead from './SummaryTable/TableHead';
import TableCell from './SummaryTable/TableCell';
import QuoteLegalText from '../../Quoting/components/QuoteLegalText/QuoteLegalText';
import { inject } from 'mobx-react';
import { getQuoteDisplaySchema } from 'components/QuoteCard/utils/getQuoteDisplaySchema';
import './quotePanel.scss';

import { QuoteRowLabel } from 'components/QuoteCard/components/QuoteRowInfo/QuoteRowLabel';
import { QuoteRowValue } from 'components/QuoteCard/components/QuoteRowInfo/QuoteRowValue';
import {
  DisplayQuoteFormat,
  FormattedQuoteKey,
  LoanType,
  QuoteSummaryVariantTypeEnum,
  QuoteTypes,
  QuoteVariants
} from '~/components/QuoteCard/types';
import { ApplicationQuote } from '~/types/application';

type QuotePanelProps = {
  variant: QuoteVariants;
  loanType?: LoanType;
  quote: QuoteTypes | ApplicationQuote;
  newCarOfferText?: string;
  appStore?: any;
  hasNegativeEquityLoan: boolean;
  singleColumn?: boolean;
  isPreApproved?: boolean;
};

const QuotePanelV2 = ({
  variant = QuoteSummaryVariantTypeEnum.FinanceApplicationSummary,
  loanType = LoanType.standardLoan,
  quote,
  appStore,
  singleColumn = false,
  newCarOfferText = undefined,
  hasNegativeEquityLoan = false,
  isPreApproved = false
}: QuotePanelProps) => {
  const quoteToDisplay = getQuoteDisplaySchema({
    // @ts-ignore
    quote: { ...quote, LoanType: loanType },
    options: { variant, isBnpp: appStore.uiState.isBnpp, hasNegativeEquityLoan }
  });
  return (
    <>
      <div className="quotePanel">
        {quoteToDisplay && <TableQuoteInformation quote={quoteToDisplay} singleColumn={singleColumn} />}
        {loanType === LoanType.standardLoan && (
          <p className="quotePanel__legal">
            <QuoteLegalText
              quote={quote as QuoteTypes}
              variant={variant as QuoteVariants}
              isPreApproved={isPreApproved}
            />
          </p>
        )}
        {newCarOfferText && <p className="quotePanel__legal">{newCarOfferText}</p>}
      </div>
    </>
  );
};

export const TableQuoteInformation = ({
  quote,
  singleColumn
}: {
  quote: DisplayQuoteFormat;
  singleColumn: boolean;
}) => {
  const quoteTableRows = (Object.keys(quote) as FormattedQuoteKey[]).map((label, index) =>
    quote[label].value !== undefined ? (
      <TableRow key={`${label}-${quote[label].value}`}>
        <TableHead width="50%">
          <QuoteRowLabel label={label} value={quote[label]} />
        </TableHead>
        <TableCell>
          <QuoteRowValue label={label} value={quote[label]} />
        </TableCell>
      </TableRow>
    ) : null
  );

  if (singleColumn) {
    return <Table>{quoteTableRows}</Table>;
  }

  const half = quoteTableRows.length / 2 + 1; // Prioritise having one more item in the first column
  const firstColumn = quoteTableRows.slice(0, half);
  const secondColumn = quoteTableRows.slice(half, quoteTableRows.length);

  return (
    <>
      <OuterLeft>
        <Table>{firstColumn}</Table>
      </OuterLeft>
      <OuterRight>
        <Table>{secondColumn}</Table>
      </OuterRight>
    </>
  );
};
export default inject('appStore')(QuotePanelV2);
