import { useCallback } from 'react';
import { findAlgoliaVehicle } from '~/api/algolia';
import { fetchVehicleVrmLookupV2 } from '~/api/vrmLookup';
import AlgoliaVehicle from '~/mobx-models/AlgoliaVehicle';

type UseVehicleLookupProps = {
  checkAlgolia: boolean;
  checkVrmLookup: boolean;
  dealershipId: string;
  shouldUseQuickQuoteLogic?: boolean;
};

/* Business logic:
  if Stock vehicle data is good(3star)
  or comes from the dealer
  or it's a motorhome vehicle
  THEN we don't need to lookup carweb
*/
const shouldLookupCarweb = ({ lookupName, vehicle }: { lookupName: string; vehicle: AlgoliaVehicle }): boolean => {
  // ignore already carweb lookups
  if (lookupName === 'carweb') {
    return false;
  }
  // else do checks for Algolia Vehicle

  const taxonomyOrigin = vehicle?.PreferredTaxonomy?.origin?.toLowerCase();
  const lowerClass = vehicle?.Class?.toLowerCase();

  switch (true) {
    case taxonomyOrigin === 'vehicle':
      return true;
    case taxonomyOrigin === 'carweb' && vehicle?.AccuracyScore >= 2: // 2 stars or less
      return true;

    case taxonomyOrigin === 'carweb' && vehicle?.AccuracyScore === 1: // 3 stars
      return false;
    case lowerClass === 'motorhomes':
      return false;
    case taxonomyOrigin === 'feed':
      return false;
    case taxonomyOrigin === 'dealer':
      return false;
    default:
      return false;
  }
};

export const useVehicleLookup = ({
  checkAlgolia,
  checkVrmLookup,
  dealershipId,
  shouldUseQuickQuoteLogic = false
}: UseVehicleLookupProps) => {
  // Call the lookups in order until one returns a result
  // and if there are no lookups, throw an error
  const lookupVehicle = useCallback(
    async (vrm: string) => {
      const lookups: (
        | { name: string; callback: typeof findAlgoliaVehicle }
        | { name: string; callback: typeof fetchVehicleVrmLookupV2 }
      )[] = [];
      let tempAlgoliaVehicle;

      if (checkAlgolia) {
        lookups.push({ name: 'algolia', callback: findAlgoliaVehicle });
      }

      if (checkVrmLookup) {
        lookups.push({ name: 'carweb', callback: fetchVehicleVrmLookupV2 });
      }

      for (const lookup of lookups) {
        const result = await lookup.callback(vrm, dealershipId).catch(() => null);

        if (shouldUseQuickQuoteLogic && shouldLookupCarweb({ lookupName: lookup?.name, vehicle: result })) {
          tempAlgoliaVehicle = result; // stored in case next lookup(carweb) returns nothing
          continue; // do the next lookup
        }

        if (result) {
          return result;
        }

        // check if there was an algolia vehicle saved to return before throwing
        if (lookup?.name === 'carweb' && tempAlgoliaVehicle) {
          return tempAlgoliaVehicle;
        }
      }

      throw new Error('No vehicle found in any lookup');
    },
    [dealershipId, checkAlgolia, checkVrmLookup, shouldUseQuickQuoteLogic]
  );

  return {
    lookupVehicle
  };
};
