import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './panelItem.scss';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import MediaQuery from 'react-responsive';
import DateFormat from '../../Common/DateFormat';
import DashboardVehicle from './DashboardVehicle';
import MediaDashboardVehicle from './MediaDashboardVehicle';
import DashboardBusinessTag from './DashboardBusinessTag';
import DashboardEligibilityMark from './DashboardEligibilityMark';
import ConsumerName from '../../Common/ConsumerName';
import AssignLeadContainer from '../..//AssignLead/containers/AssignLeadContainer';
import notesIcon from '../../../styles/icons/notes_tag_small.svg';
import GroupLead from './GroupLead';
import { setUrlAsCloudfront } from '../../../core/helpers';
import { objectToSearchParams } from 'utils/objectToSearchParams';

const PanelItem = ({ item, selectedFinanceTab, index, length, dealershipId }) => {
  const { t } = useTranslation('Dashboard');
  const customerId =
    item.DealershipConsumer && item.DealershipConsumer.Id ? item.DealershipConsumer.Id : item.CorporateCustomer.Id;
  const isCorpCustomer = item.CorporateCustomer && item.CorporateCustomer.Id;
  const customerName = isCorpCustomer
    ? item.CorporateCustomer.TradingName
    : `${item.DealershipConsumer.FirstName} ${item.DealershipConsumer.LastName}`; // const tag = isCorpCustomer ? 'corporate' : '';

  const innerStyles = classnames('panelItem__inner', index !== length);
  const link = {
    pathname: `/d/${dealershipId}/consumers/${customerId}`,
    search: objectToSearchParams({
      vehicleId: item.Vehicle.Id,
      selectedFinanceTab
    }).toString()
  };
  const originalDealershipId = item.OriginalDealership && item.OriginalDealership.Id;
  const imageUrl =
    item.Vehicle.ImageUrl && `${setUrlAsCloudfront(item.Vehicle.ImageUrl)}?width=100&scale=both&format=webp`;

  return (
    <div className="panelItem" data-name={customerName}>
      <Link className="panelItem__link" to={link}>
        <div className={innerStyles}>
          <div className="panelItem__top">
            <div className="panelItem__assignContainer">
              <AssignLeadContainer
                size="small"
                dealershipId={dealershipId}
                customerId={customerId}
                customerName={customerName}
                customerType={isCorpCustomer ? 'corporate' : 'consumer'}
                initialAssignees={item.assignedTo}
              />
            </div>
            <div className="panelItem__dates">
              <DateFormat value={item.Created} format="calendar" parse="utc" />
              <div>
                {item.Created === item.LastModified ? (
                  '(' + t('PanelItem.no_updates') + ')'
                ) : (
                  <>
                    ({t('PanelItem.updated')} <DateFormat value={item.LastModified} format="timeAgo" parse="utc" />)
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="panelItem__main">
            <MediaQuery minWidth={1450}>
              <DashboardVehicle vehicle={item.Vehicle} vehicleImage={imageUrl} imageWidth="100px" imageHeight="70px" />
            </MediaQuery>
            <MediaQuery maxWidth={1449}>
              <MediaDashboardVehicle vehicle={item.Vehicle} vehicleImage={imageUrl} />
            </MediaQuery>
            <div className="panelItem__text">
              <div className="panelItem__name">
                <ConsumerName name={customerName} />
                {item.DealershipConsumer.AliasCount > 0 && (
                  <div className="panelItem__alias" title="Number of Linked Customers">
                    +{item.DealershipConsumer.AliasCount}
                  </div>
                )}
                {item.CorporateCustomer && item.CorporateCustomer.NoteCount > 0 && (
                  <div className="panelItem__notesContainer">
                    <img src={notesIcon} className="panelItem__notesIcon" alt="notes" />
                  </div>
                )}
                {item.DealershipConsumer && item.DealershipConsumer.NoteCount > 0 && (
                  <div className="panelItem__notesContainer">
                    <img src={notesIcon} className="panelItem__notesIcon" alt="notes" />
                  </div>
                )}
                {isCorpCustomer && (
                  <div className="panelItem__flagContainer">
                    <DashboardBusinessTag smallTag={true} />
                  </div>
                )}
              </div>
              <div
                className="panelItem__vehicle panelItem__ellipsis"
                title={item.Vehicle.Make + ' ' + item.Vehicle.Model + ' ' + item.Vehicle.Derivative}
              >
                {item.Vehicle.Make} {item.Vehicle.Model}
              </div>
              <div className="panelItem__derivative panelItem__ellipsis">{item.Vehicle.Derivative}</div>
              <DashboardEligibilityMark
                matchRate={item.CfcProspect.MatchRate}
                source={item.CfcProspect.SourceType === 'FinanceScan' ? 'finance-nav' : 'cfc'}
              />
              {!!originalDealershipId ? (
                <div className="panelItem__groupLead">
                  <GroupLead item={item} />
                </div>
              ) : (
                <div>
                  <div className="panelItem__light" key="origin">
                    {t('PanelItem.lead_created_in')} {item.CreatedSource}
                  </div>
                  <div className="panelItem__light" key="client">
                    {t('PanelItem.updated_in')} {item.UpdatedSource}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

PanelItem.propTypes = {
  item: PropTypes.object,
  selectedFinanceTab: PropTypes.string,
  onClick: PropTypes.func,
  index: PropTypes.number,
  length: PropTypes.number,
  dealershipId: PropTypes.string
};
export default PanelItem;
