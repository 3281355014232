import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './formFooter.scss';
import Button from '../Button/Button';
import Alert from '../Alert';
import { observer, inject } from 'mobx-react';

const FormFooter = (props) => {
  const cancelLabel = props.cancelLabel || props.t('FormFooter.cancel');
  const submitLabel = props.submitLabel || props.t('FormFooter.proceed');
  const saveLabel = props.saveLabel || props.t('FormFooter.save');

  let { submitTabIndex, saveTabIndex, printTabIndex, cancelTabIndex } = props;
  return (
    <div className="formFooter" data-th="formFooter">
      {props.errorMessage && (
        <div className="formFooter__errors">
          <Alert>{props.errorMessage}</Alert>
        </div>
      )}

      <div className="formFooter__button formFooter__right" key="action2">
        {!props.hideSubmit && (
          <Button
            isLoading={props.submittingState === 'loading' || props.isSubmitting}
            hasError={props.submittingState === 'error' || props.hasSubmittingError}
            buttonStyle="primary"
            disabled={props.submitDisabled}
            type={props.onSubmit ? 'button' : 'submit'}
            onClick={props.onSubmit}
            tabIndex={submitTabIndex}
            id={props.submitButtonTag || submitLabel}
            trackingPage={props.trackingPage}
            dataThook={
              (props.submitButtonTag && props.submitButtonTag.replace(/ /g, '')) ||
              (submitLabel && submitLabel.replace(/ /g, ''))
            }
          >
            {submitLabel}
          </Button>
        )}
      </div>

      {props.onSave && (
        <div className="formFooter__button formFooter__right" key="action3">
          <Button
            dataThook="save"
            isLoading={props.savingState === 'loading' || props.isSaving}
            hasError={props.savingState === 'error' || props.hasSavingError}
            buttonStyle="secondary"
            type="button"
            onClick={props.onSave}
            tabIndex={saveTabIndex}
          >
            {saveLabel}
          </Button>
        </div>
      )}

      {props.onPrint && (
        <div className="formFooter__button formFooter__right" key="action3">
          <Button
            dataThook="printButton"
            type="button"
            onClick={props.onPrint}
            buttonStyle="secondary"
            tabIndex={printTabIndex}
          >
            {props.t('FormFooter.print')}
          </Button>
        </div>
      )}

      {props.onCancel && (
        <div className="formFooter__button formFooter__left" key="action1">
          <Button
            dataThook="cancel"
            type="button"
            buttonStyle="cancel"
            onClick={props.onCancel}
            tabIndex={cancelTabIndex}
          >
            {cancelLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

FormFooter.propTypes = {
  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  errorMessage: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onSubmit: PropTypes.func,
  submittingState: PropTypes.string,
  savingState: PropTypes.string,
  onPrint: PropTypes.func,
  submitDisabled: PropTypes.bool,
  hideSubmit: PropTypes.bool,
  submitTabIndex: PropTypes.number,
  cancelTabIndex: PropTypes.number,
  printTabIndex: PropTypes.number,
  saveTabIndex: PropTypes.number,
  isSubmitting: PropTypes.bool,
  hasSubmittingError: PropTypes.bool,
  isSaving: PropTypes.bool,
  hasSavingError: PropTypes.bool,
  submitButtonTag: PropTypes.string,
  trackingPage: PropTypes.string
};
FormFooter.defaultProps = {
  submitDisabled: false
};

export default withTranslation('Common')(inject('appStore')(observer(FormFooter)));
