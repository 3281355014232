import { ConsumerDutyProductDetails } from '../api/contentService';
import { getPageTags } from '../core/pageTracking';
import { MetricType } from '~/components/Insights/types';
import get from 'lodash/get';
import AlgoliaVehicle from '~/mobx-models/AlgoliaVehicle';

import Avo, {
  FinanceProductTypeValueType,
  financeProductSuitabilityContentViewed,
  financeProductSuitabilityVideoViewed,
  financeProductSuitabilityVulnerabilityContentViewed,
  financeProductSuitabilityVulnerabilityOutboundLink,
  financeProductSuitabilityQuestionAndAnswer,
  vehicleFinanceApplicationStarted,
  FinanceEligibilityCheckActivatedProperties,
  FinanceEligibilityCheckSubmittedProperties,
  FinanceEligibilityCheckCompletedProperties,
  FinanceEligibilityCheckFailedProperties,
  financeEligibilityCheckActivated,
  financeEligibilityCheckSubmitted,
  financeEligibilityCheckCompleted,
  financeEligibilityCheckFailed,
  expandMultiScheduleQuote,
  DashboardLenderUpdatesApplicationSelectedProperties,
  dashboardLenderUpdatesApplicationSelected,
  applicationStatusRequoteNewRateSelected,
  ApplicationStatusRequoteNewRateSelectedProperties,
  FinanceQuotesFailedProperties,
  financeQuotesFailed,
  financeQuoteApplySelected,
  FinanceQuoteApplySelectedProperties,
  financeQuotesDisplayed,
  FinanceQuotesDisplayedProperties,
  FinanceQuotesSortedProperties,
  financeQuotesSorted,
  financeQuoteEligibilitySelected,
  FinanceQuoteEligibilitySelectedProperties,
  dealershipUserSwitchedDealership,
  DealershipUserSwitchedDealershipProperties,
  dealershipMetricDateRangeChanged,
  DealershipMetricDateRangeChangedProperties,
  dealershipMetricTileSelected,
  dealershipMetricOriginChanged,
  DealershipMetricOriginChangedProperties,
  applicationEditVehicleSelected,
  applicationSubmitSelected,
  ApplicationSubmitSelectedProperties
} from 'lib/Avo';

// avo expects lowercase financeType
const formatFinanceProductType = (financeProductType: FinanceProductTypeValueType | null | undefined) => {
  if (typeof financeProductType === 'string') {
    return financeProductType.toLowerCase() as FinanceProductTypeValueType;
  }
};

/*
 *
 * Consumer Duty Tracking
 *
 */
interface FinanceProductTrackingProps {
  consumerDutyContent: Pick<
    ConsumerDutyProductDetails,
    'id' | 'version' | 'facilityTypeName' | 'funderProductId' | 'funderCode'
  >;
  dealershipId: string;
  quoteId: string;
}

const getBaseTrackingInfo = ({ consumerDutyContent, dealershipId }: FinanceProductTrackingProps) => {
  const funderCode = get(consumerDutyContent, 'funderCode');
  return {
    dealershipId,

    // The type definitions coming from AVO are inconsistent
    // between methods, so we need to force the type here
    financeProductType: formatFinanceProductType(
      get(consumerDutyContent, 'facilityTypeName')
    ) as FinanceProductTypeValueType,
    financeFunderCode: funderCode || '',
    financeFunderProductId: get(consumerDutyContent, 'funderProductId')
  };
};

const getFinanceContentInfo = ({ consumerDutyContent }: FinanceProductTrackingProps) => {
  return {
    financeContentId: get(consumerDutyContent, 'id'),
    financeContentVersion: get(consumerDutyContent, 'version')
  };
};

export const trackPageViewed = (pageName: string, dealershipId: string) => {
  Avo.pageView({
    customDestinationPageName_: pageName,
    pageTags: [...getPageTags(pageName)],
    dealershipId: dealershipId || undefined,
    vehicleVrm: undefined,
    vehicleVin: undefined,
    vehicleRmid: undefined,
    financeFunderCode: undefined,
    financeQuoteId: undefined,
    consumerId: undefined,
    pageProperties: undefined,
    vehicleImageUrl: undefined,
    quoteeId: undefined,
    errorMessage: undefined,
    ivendiVehicleId: undefined
  });
};

export const trackFinanceProductSuitabilityContentViewed = (settings: FinanceProductTrackingProps) => {
  financeProductSuitabilityContentViewed({
    ...getBaseTrackingInfo(settings),
    ...getFinanceContentInfo(settings),
    financeQuoteId: settings.quoteId
  });
};

export const trackDealershipUserSwitchedDealership = (props: DealershipUserSwitchedDealershipProperties) => {
  dealershipUserSwitchedDealership(props);
};

export const trackFinanceQuoteApplySelected = (props: FinanceQuoteApplySelectedProperties) => {
  financeQuoteApplySelected(props);
};

export const trackFinanceQuotesDisplayed = (props: FinanceQuotesDisplayedProperties) => {
  financeQuotesDisplayed(props);
};

export const trackFinanceQuotesSorted = (props: FinanceQuotesSortedProperties) => {
  financeQuotesSorted(props);
};

export const trackFinanceQuotesFailed = (props: FinanceQuotesFailedProperties) => {
  financeQuotesFailed(props);
};

export const trackFinanceQuoteEligibilitySelected = (props: FinanceQuoteEligibilitySelectedProperties) => {
  financeQuoteEligibilitySelected(props);
};

interface VideoViewedFinanceProductTrackingProps extends FinanceProductTrackingProps {
  timeViewed: number;
}

export const trackFinanceProductSuitabilityVideoViewed = (settings: VideoViewedFinanceProductTrackingProps) => {
  financeProductSuitabilityVideoViewed({
    ...getBaseTrackingInfo(settings),
    timeViewed: settings.timeViewed,
    financeQuoteId: settings.quoteId
  });
};

export const trackFinanceProductSuitabilityVulnerabilityContentViewed = (settings: FinanceProductTrackingProps) => {
  financeProductSuitabilityVulnerabilityContentViewed({
    ...getBaseTrackingInfo(settings),
    ...getFinanceContentInfo(settings),
    financeQuoteId: settings.quoteId
  });
};

interface OutboundLinkClickedFinanceProductTrackingProps extends FinanceProductTrackingProps {
  targetUrl: string;
  targetTags: string[];
}

export const trackFinanceProductSuitabilityVulnerabilityOutboundLink = (
  settings: OutboundLinkClickedFinanceProductTrackingProps
) => {
  financeProductSuitabilityVulnerabilityOutboundLink({
    ...getBaseTrackingInfo(settings),
    targetUrl: settings.targetUrl,
    targetTags: settings.targetTags,
    financeQuoteId: settings.quoteId
  });
};

interface ProductSuitabilityQuestionAndAnswerProps extends FinanceProductTrackingProps {
  productSuitabilityQuestion: string;
  productSuitabilityAnswer: string;
}

export const trackFinanceProductSuitabilityQuestionAndAnswer = (settings: ProductSuitabilityQuestionAndAnswerProps) => {
  // @ts-ignore
  financeProductSuitabilityQuestionAndAnswer({
    ...getBaseTrackingInfo(settings),
    productSuitabilityQuestion: settings.productSuitabilityQuestion,
    productSuitabilityAnswer: settings.productSuitabilityAnswer
  });
};

/*
 *
 * Vehicle Finance Application Tracking
 *
 */
interface StartFinanceApplicationTrackingProps {
  dealershipId: string;
  financeFunderCode: string;
  financeFunderProductId: string;
  financeProductType: FinanceProductTypeValueType;
  financeApplicationId: string;
  quoteId: string;
}

export const trackVehicleFinanceApplicationStarted = (settings: StartFinanceApplicationTrackingProps) => {
  vehicleFinanceApplicationStarted({
    ...getBaseTrackingInfo({
      dealershipId: settings.dealershipId,
      consumerDutyContent: {
        id: '',
        version: 0,
        funderCode: settings.financeFunderCode,
        funderProductId: settings.financeFunderProductId,
        // @ts-ignore - missing product types on the avo type
        facilityTypeName: settings.financeProductType
      },
      quoteId: settings.quoteId
    }),
    financeApplicationId: settings.financeApplicationId,
    financeProductType: settings.financeProductType
  });
};

/*
 *
 * CFC Eligibility Checks Tracking
 *
 */

export const defaultCfcVehicleInformation: FinanceEligibilityCheckSubmittedProperties = {
  vehicleVrm: '',
  vehicleMake: '',
  vehicleModel: '',
  vehicleVin: '',
  ivendiVehicleId: '',
  vehicleTaxonomySource: '',
  vehicleTaxonomyCountry: '',
  vehicleTaxonomyCode: '',
  vehicleClass: '',
  vehicleOemCodes: [''],
  vehicleDerivative: '',
  vehicleFuel: '',
  vehicleDoors: '',
  vehicleCondition: '',
  vehicleImageUrl: '',
  vehicleAccuracyScore: '',
  algoliaObjectId: '',
  vehiclePrice: 0,
  vehicleEngineSize: '',
  vehicleMileage: 0,
  dealershipId: ''
};

export type QuoteVehicleType = {
  CapId: string;
  Class: 'car' | 'lcv' | 'motorbike';
  Condition: 'new' | 'used';
  Derivative: string;
  DerivativeId: string;
  Make: string;
  MakeId: string;
  Mileage: string;
  Model: string;
  ModelId: string;
  RegistrationDate: string;
  Vin: string;
  Vrm: string;
};

export type CfcVehicleInformation = Pick<
  FinanceEligibilityCheckSubmittedProperties,
  | 'vehicleVrm'
  | 'vehicleMake'
  | 'vehicleModel'
  | 'vehicleVin'
  | 'vehicleDerivative'
  | 'vehicleMileage'
  | 'vehicleCondition'
  | 'vehicleClass'
>;

export const formatQuoteVehicleToCfcTracking = (quoteVehicle: QuoteVehicleType): CfcVehicleInformation => ({
  vehicleVrm: quoteVehicle.Vrm,
  vehicleMake: quoteVehicle.Make,
  vehicleModel: quoteVehicle.Model,
  vehicleVin: quoteVehicle.Vin,
  vehicleDerivative: quoteVehicle.Derivative,
  vehicleMileage: parseInt(quoteVehicle.Mileage),
  vehicleCondition: quoteVehicle.Condition,
  vehicleClass: quoteVehicle.Class
});

export type CfcQuoteDecisionType = {
  DecisionMessages: string[];
  MatchRate: number;
  QuoteId: string;
};
export const getMaxEligibilityMatchRate = (eligibilityMatchRates: CfcQuoteDecisionType[]) => {
  return Math.max(...eligibilityMatchRates.map((item) => item.MatchRate));
};

export const trackCfcEligibilityCheckActivated = (
  vehicleInformation: Partial<FinanceEligibilityCheckActivatedProperties>
) => {
  financeEligibilityCheckActivated(Object.assign(defaultCfcVehicleInformation, vehicleInformation));
};
export const trackCfcEligibilityCheckSubmitted = (
  vehicleInformation: Partial<FinanceEligibilityCheckSubmittedProperties>
) => {
  financeEligibilityCheckSubmitted(Object.assign(defaultCfcVehicleInformation, vehicleInformation));
};
export const trackCfcEligibilityCheckCompleted = (
  vehicleInformation: Partial<FinanceEligibilityCheckCompletedProperties>,
  eligibilityHighestMatchRate: string
) => {
  financeEligibilityCheckCompleted(
    Object.assign(defaultCfcVehicleInformation, {
      ...vehicleInformation,
      eligibilityHighestMatchRate
    })
  );
};
export const trackCfcEligibilityCheckFailed = (
  vehicleInformation: Partial<FinanceEligibilityCheckFailedProperties>,
  errorMessage: string
) => {
  financeEligibilityCheckFailed(
    Object.assign(defaultCfcVehicleInformation, { ...vehicleInformation, errorMessage: errorMessage })
  );
};

type BaseQuoteProps = {
  financeQuotePosition: number;
  financeQuotesCount: number;
  financeQuotesSortField: string;
  financeQuotesSortDirection: string;
  dealershipId: string;
  eventTrigger: string;
  eventTriggerText: string;
};

type FormattedExpandMultiScheduleQuoteProps = BaseQuoteProps & {
  financeQuoteId: string | null | undefined;
  financeFunderCode: string;
  financeQuoteProductType: string;
  financeQuoteTerm: number;
  financeQuoteMileage: number;
  financeQuoteProductId: string;
  financeQuoteMonthlyPayment: number;
  financeQuoteTotalDeposit: number;
};

type UnformattedQuoteProps = Omit<
  BaseQuoteProps,
  'financeQuotesSortDirection' | 'eventTrigger' | 'eventTriggerText'
> & {
  QuoteId: string | null | undefined;
  FunderCode: string;
  FinanceType: string;
  Term: number;
  AnnualDistanceQuoted: number;
  ProductId: string;
  PaymentSchedule: number;
  TotalDeposit: number;
};

export const formatExpandMultiScheduleQuote = (data: UnformattedQuoteProps): FormattedExpandMultiScheduleQuoteProps => {
  // Need to confirm what format Dave wants monthly payment data in and deal with the various PaymentSchedule formats

  // const flattenedPaymentsArray = quoteData.PaymentSchedule.map((item: QuotewarePaymentScheduleDetails) =>
  //   Array(item.Number).fill(item.Amount)
  // ).flat();

  return {
    financeQuoteId: data.QuoteId,
    financeQuotePosition: data.financeQuotePosition,
    financeFunderCode: data.FunderCode,
    financeQuoteProductType: data.FinanceType,
    financeQuoteTerm: data.Term,
    financeQuoteMileage: data.AnnualDistanceQuoted,
    financeQuotesSortField: data.financeQuotesSortField,
    financeQuotesCount: data.financeQuotesCount,
    financeQuotesSortDirection: '',
    financeQuoteProductId: data.ProductId,
    financeQuoteMonthlyPayment: data.PaymentSchedule,
    dealershipId: data.dealershipId,
    financeQuoteTotalDeposit: data.TotalDeposit,
    eventTrigger: 'isLoansExpanded',
    eventTriggerText: 'Show Components'
  };
};

export const trackExpandMultiScheduleQuote = (multiScheduleInfo: FormattedExpandMultiScheduleQuoteProps) => {
  expandMultiScheduleQuote(multiScheduleInfo);
};

/*
 *
 * Dashboard Lender Update Tracking
 *
 */

export const trackDashboardLenderUpdateSelected = (properties: DashboardLenderUpdatesApplicationSelectedProperties) => {
  dashboardLenderUpdatesApplicationSelected(properties);
};

/*
 *
 * Requote Tracking
 *
 */

export const trackRequoteNewRateSelected = (props: ApplicationStatusRequoteNewRateSelectedProperties) => {
  applicationStatusRequoteNewRateSelected(props);
};

// Common properties in FinanceQuotesSelected and FinanceQuoteFailed but with unformatted key names
type UnformattedFinanceQuoteCommonProps = {
  Vrm: string | null | undefined;
  Make: string | null | undefined;
  Model: string | null | undefined;
  Vin: string | null | undefined;
  VehicleId: string | null | undefined;
  Class: string | null | undefined;
  Derivative: string | null | undefined;
  Condition: string | null | undefined;
  CdnVehicleImageUrl: string | null | undefined;
  VehiclePrice: number;
  Mileage: number;
  QuoteId: string | null | undefined;
  FunderCode: string | null | undefined;
  FinanceType: string | null | undefined;
  Term: number;
  ProductId: string | null | undefined;
  FollowingPayments: number;
  TotalDeposit: number;
  dealershipId: string;
};

type StockVehicleData = Pick<
  FinanceQuoteApplySelectedProperties,
  | 'vehicleTaxonomySource'
  | 'vehicleTaxonomyCountry'
  | 'vehicleTaxonomyCode'
  | 'vehicleOemCodes'
  | 'vehicleFuel'
  | 'vehicleDoors'
  | 'vehicleAccuracyScore'
  | 'algoliaObjectId'
  | 'vehicleEngineSize'
>;

type UnformattedFinanceQuoteData = BaseQuoteProps &
  UnformattedFinanceQuoteCommonProps &
  StockVehicleData &
  Pick<FinanceQuoteApplySelectedProperties, 'financeQuoteJourney'>;

/*
 * Matches common unformatted key names with the prop names expected by
 * FinanceQuoteApplySelectedProperties and FinanceQuotesFailedProperties
 */
const financeQuoteKeyMapping: Partial<Record<
  keyof FinanceQuoteApplySelectedProperties | keyof FinanceQuotesFailedProperties,
  keyof UnformattedFinanceQuoteData
>> = {
  vehicleVrm: 'Vrm',
  vehicleMake: 'Make',
  vehicleModel: 'Model',
  vehicleVin: 'Vin',
  ivendiVehicleId: 'VehicleId',
  vehicleClass: 'Class',
  vehicleDerivative: 'Derivative',
  vehicleCondition: 'Condition',
  vehicleImageUrl: 'CdnVehicleImageUrl',
  vehiclePrice: 'VehiclePrice',
  vehicleMileage: 'Mileage',
  financeQuoteMileage: 'Mileage',
  financeQuoteId: 'QuoteId',
  financeFunderCode: 'FunderCode',
  financeQuoteProductType: 'FinanceType',
  financeQuoteTerm: 'Term',
  financeQuoteProductId: 'ProductId',
  financeQuoteMonthlyPayment: 'FollowingPayments',
  financeQuoteTotalDeposit: 'TotalDeposit',
  dealershipId: 'dealershipId'
};

// Generic function to map object keys according to keyMapping obj
type MyObject = Record<string, any>;
const mapObjectKeys = <T extends MyObject, U extends MyObject>(
  obj: T,
  keyMapping: Partial<Record<keyof U, keyof T>>
): Partial<U> => {
  const newObject: Partial<U> = {};

  for (const [formattedKey, unformattedKey] of Object.entries(keyMapping)) {
    if (unformattedKey && obj[unformattedKey as keyof T] !== undefined) {
      newObject[formattedKey as keyof U] = (obj[unformattedKey as keyof T] as unknown) as U[keyof U];
    }
  }

  return newObject;
};

// Formats  props for financeQuoteApplySelected
export const formatFinanceQuotesApplySelected = (
  data: UnformattedFinanceQuoteData,
  additionalData: Partial<FinanceQuoteApplySelectedProperties>
): FinanceQuoteApplySelectedProperties => {
  const formattedData: Partial<FinanceQuoteApplySelectedProperties> = {
    ...defaultCfcVehicleInformation,
    ...mapObjectKeys(data, financeQuoteKeyMapping),
    ...additionalData
  };

  return formattedData as FinanceQuoteApplySelectedProperties;
};

export const trackFinanceQuotesApplySelected = (props: FinanceQuoteApplySelectedProperties) => {
  financeQuoteApplySelected(props);
};

// Formats  props for financeQuotesFailed
export const formatFinanceQuotesFailed = (
  data: UnformattedFinanceQuoteData,
  additionalData: Partial<FinanceQuotesFailedProperties>
): FinanceQuotesFailedProperties => {
  const formattedData: Partial<FinanceQuotesFailedProperties> = {
    ...defaultCfcVehicleInformation,
    ...mapObjectKeys(data, financeQuoteKeyMapping),
    ...additionalData
  };

  return formattedData as FinanceQuotesFailedProperties;
};

/*
 *
 * Dealership Metrics Tracking
 *
 */

export const trackDealershipMetricTileSelected = (metric: MetricType) => {
  dealershipMetricTileSelected({ metricName: metric });
};

interface TrackDealershipMetricDateRangeSelectProps {
  metricName: MetricType;
  valueFrom: number;
  valueTo: number;
  selectOptions: {
    value: number;
    label: string;
  }[];
}

export const trackDealershipMetricDateRangeSelect = (props: TrackDealershipMetricDateRangeSelectProps) => {
  const formatSelectData = (
    props: TrackDealershipMetricDateRangeSelectProps
  ): DealershipMetricDateRangeChangedProperties => {
    return {
      metricName: props.metricName,
      metricDateRangeFrom: props.selectOptions.filter((option) => option.value === props.valueFrom)[0].label,
      metricDateRangeTo: props.selectOptions.filter((option) => option.value === props.valueTo)[0].label
    };
  };

  dealershipMetricDateRangeChanged(formatSelectData(props));
};

export const trackDealershipMetricOriginSelect = (props: DealershipMetricOriginChangedProperties) => {
  dealershipMetricOriginChanged(props);
};

type QuoteModuleViews =
  | 'COSTS_VIEW'
  | 'LIST_VIEW'
  | 'COMPARE_VIEW'
  | 'MONTHLY_PAYMENTS_VIEW'
  | 'CHECK_ELIGIBILITY_VIEW'
  | 'LOANS_BREAKDOWN_VIEW';

export const trackQuoteModuleViews = (view: QuoteModuleViews, dealershipId: string) => {
  switch (view) {
    case 'COSTS_VIEW':
      trackPageViewed('Quote Details - Quick Quote', dealershipId);
      break;
    case 'LIST_VIEW':
      trackPageViewed('Quote Results - Quick Quote', dealershipId);
      break;
    case 'COMPARE_VIEW':
      trackPageViewed('Compare Quotes - Quick Quote', dealershipId);
      break;
    case 'MONTHLY_PAYMENTS_VIEW':
      trackPageViewed('Compare Quotes - Quick Quote', dealershipId);
      break;
    case 'CHECK_ELIGIBILITY_VIEW':
      trackPageViewed('CFC Check - Quick Quote', dealershipId);
      break;
    case 'LOANS_BREAKDOWN_VIEW':
      trackPageViewed('Loans Breakdown - Quick Quote', dealershipId);
      break;
    default:
      trackPageViewed('Quote Details - Quick Quote', dealershipId);
      break;
  }
};

type trackDetailsVehicleCardEditProps = {
  algoliaVehicle: AlgoliaVehicle;
  dealershipId: string;
  userId: string;
};

const formatVehicleData = (algoliaVehicle: AlgoliaVehicle) => {
  return {
    vehicleVrm: algoliaVehicle.Vrm,
    vehicleMake: algoliaVehicle.Make,
    vehicleModel: algoliaVehicle.Model,
    vehicleVin: algoliaVehicle.Vin,
    vehicleDerivative: algoliaVehicle.Derivative,
    vehicleMileage: algoliaVehicle.Mileage,
    vehicleCondition: algoliaVehicle.Condition,
    vehicleClass: algoliaVehicle.Class,
    vehicleImageUrl: algoliaVehicle.ImgPth,
    vehiclePrice: algoliaVehicle.Price,
    vehicleEngineSize: algoliaVehicle.BadgeEngineSize,
    vehicleFuel: algoliaVehicle.Fuel,
    vehicleDoors: algoliaVehicle.Doors,
    vehicleAccuracyScore: algoliaVehicle.AccuracyScore,
    algoliaObjectId: algoliaVehicle.objectID,
    ivendiVehicleId: algoliaVehicle.Id,
    vehicleTaxonomySource: algoliaVehicle.PreferredTaxonomy?.provider,
    vehicleTaxonomyCountry: undefined,
    vehicleTaxonomyCode: undefined,
    vehicleOemCodes: undefined
  };
};

export const trackDetailsVehicleCardEdit = (props: trackDetailsVehicleCardEditProps) => {
  const { dealershipId, userId, algoliaVehicle } = props;
  const formattedVehicle = formatVehicleData(algoliaVehicle);

  applicationEditVehicleSelected({
    ...formattedVehicle,
    dealershipId,
    dealershipUserId: userId
  });
};

export const trackApplicationSubmitSelected = (props: ApplicationSubmitSelectedProperties) => {
  applicationSubmitSelected(props);
};
