import {
  EligibilityOffered,
  EligibilityOfferedType,
  FinanceNavigatorEligibilityLenderDataType,
  FormattedQuoteKey
} from '~/components/QuoteCard/types';

export const EligibilityCategories = {
  high: 'high',
  medium: 'medium',
  low: 'low'
} as const;

export type EligibilityCategoriesType = keyof typeof EligibilityCategories;

export type FNEligibilityModalContentType = {
  title: string;
  header: string;
  message: string;
  indicativeText: string;
  lenderData: FinanceNavigatorEligibilityLenderDataType;
  valuesNowDifferent: Partial<
    Record<FormattedQuoteKey, { current: number; original: number | null; hasChanged: boolean }>
  >;
};

// Used in EligibilityModalGaugeSection and Gauge components as either cfcMatchRate or fnEligibilityStatus is required
export type EligibilityStatusPropsType =
  | { cfcMatchRate: number; fnEligibilityStatus?: null }
  | { cfcMatchRate?: null; fnEligibilityStatus: EligibilityOfferedType };
