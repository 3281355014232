import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { push } from 'routerHistory';

import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import QuoteModule from '../../Quoting/containers/QuoteModuleContainer';

import { submitMultipleApplications } from '../../../api/application';
import { withParams } from 'hocs/router';
import observerForHooks from '~/hocs/observerForHooks';
import { getCustomer } from '~/selectors/customerSelectors';

import * as applicationActions from '~/redux/application/applicationActions';
import * as quickQuoteActions from '~/redux/quickQuote/quickQuoteActions';
import * as modalActions from '~/redux/modal/modalActions';
import * as quotesActions from '~/redux/quote/quoteActions';
import * as consumerActions from '~/redux/consumer/consumerActions';
import * as componentActions from '~/redux/component/componentActions';

import { FixLater } from '~/types/basic';
import { QuoteModuleVariantType } from '~/components/Quoting/components/QuoteModule/types';
import { AppStore } from '~/modules/Stock/types/Types';
import { CustomerEntityStateType, CustomerSettlement } from '~/types/customer';
import { CustomerType } from '~/api/contentService/utils';
import { QuoteTypes } from '~/components/QuoteCard/types';
import { CustomerVehicleOfInterest } from '~/types/vehicle';
import { Session } from '~/types/session';
import ErrorApology from '~/components/Common/ErrorApology';

type CustomerQuoteProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & { appStore: AppStore };

export type CustomerQuoteDefaultValues = {
  CashDeposit?: number | null | string;
  Term: number;
  AnnualDistance: number;
  ValueAddedProducts: FixLater[];
  CustomerType: CustomerType | null;
  OutstandingSettlement: CustomerSettlement['Value'] | null | boolean;
  SettlementSource: CustomerSettlement['Source'] | boolean;
};

const CustomerQuote = ({
  appStore,
  consumer,
  session,
  assignees,
  components,
  load,
  unload,
  onCancel,
  createApplication,
  setupQuoteStoreForFinanceNavigator,
  saveCustomerQuote,
  onSendDeal
}: CustomerQuoteProps) => {
  const { t } = useTranslation('CustomerQuote');
  const navigate = useNavigate();
  const { dealershipId, consumerId, vehicleId, quoteId } = useParams();
  const { canUseCfcIvendiv2, canUseSendDeal } = appStore.uiState;
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState<CustomerQuoteDefaultValues | null>(null);

  // We need to memoise these values to prevent unnecessary re-renders
  // because the shallow comparison on Redux state values will always return false and trigger a re-render
  const vehicle = useMemo(() => {
    return consumer?.VehiclesOfInterest?.find((vehicle) => vehicle.VehicleId === vehicleId);
  }, [consumer.VehiclesOfInterest, vehicleId]);
  const savedQuote = useMemo(() => consumer?.SavedQuotes?.find((quote) => quote.Quote.QuoteId === quoteId)?.Quote, [
    consumer.SavedQuotes,
    quoteId
  ]);
  const customerType = useMemo(() => consumer?.CustomerType || null, [consumer.CustomerType]);
  const settlement = useMemo(() => consumer?.Settlement || {}, [consumer.Settlement]);
  const settings = useMemo(
    () => session?.Dealerships?.find((dealership) => dealership.Id === dealershipId)?.Settings || undefined,
    [session.Dealerships, dealershipId]
  );

  const defaultVaps = appStore.vapStore.getVisibleDefaultProductsClassFiltered(vehicle?.Class);

  // Still not exactly sure why we need these but they seem to be used to indicate
  // when a component has completed loading. I've kept them after converting this
  // component to TS and to a function component
  // They might be used for redux Sagas as seen in src/redux/customerQuote/customerQuoteContainerSagas.js
  useEffect(() => {
    load('CustomerQuote');
    return () => {
      unload('CustomerQuote');
    };
  }, []);

  useEffect(() => {
    const initialCosts: CustomerQuoteDefaultValues = {
      CashDeposit: settings?.DepositValue,
      Term: settings?.PreferredTerm || 48,
      AnnualDistance: settings?.DefaultAnnualMileage || 10000,
      ValueAddedProducts: defaultVaps || [],
      CustomerType: customerType,
      OutstandingSettlement: settlement && !settlement?.Expired && settlement.Value,
      SettlementSource: settlement && !settlement?.Expired && settlement.Source
    };
    setDefaultValues(initialCosts);

    // react-hooks/exhaustive-deps - Using it instead of ComponentWillMount
  }, [settings, settlement, customerType]);

  useEffect(() => {
    // Pre-populate the state so we can navigate straight to finance navigator
    if (vehicle && defaultValues && savedQuote) {
      const quotes = savedQuote ? [savedQuote] : [];
      setupQuoteStoreForFinanceNavigator(quotes, vehicle, defaultValues);
    }
  }, [vehicle, savedQuote, defaultValues]);

  const handleProceed = ({ quote }: { quote: QuoteTypes }) => {
    // Combined quote
    if (quote.VehicleLoan) {
      setIsLoading(true);
      let quoteIds = [];

      if (quote.PersonalLoan) {
        quoteIds.push(quote.PersonalLoan.QuoteId);
      }
      if (quote.NegativeEquityLoan) {
        quoteIds.push(quote.NegativeEquityLoan.QuoteId);
      }

      quoteIds.push(quote.VehicleLoan.QuoteId);

      submitMultipleApplications(
        consumerId,
        vehicle?.VehicleId,
        quoteIds,
        consumer.CustomerType,
        null,
        quote,
        dealershipId
      ).then((applicationId) => {
        setIsLoading(false);
        navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicationId[applicationId.length - 1]}`);
      });

      // Single quote
    } else if (vehicle) {
      createApplication(vehicle, quote, consumer.CustomerType);
    }
  };

  if (components.CustomerQuote && components.CustomerQuote.errorMessage) {
    return <ErrorApology>{components.CustomerQuote.errorMessage}</ErrorApology>;
  }

  if (!(components.CustomerQuote && components.CustomerQuote.hasLoaded)) {
    return null;
  }

  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('CustomerQuote.home'),
            path: `/d/${dealershipId}`
          },
          {
            name: t('CustomerQuote.customer_list'),
            path: `/d/${dealershipId}/consumers`
          },
          {
            name: t('CustomerQuote.consumer'),
            path: `/d/${dealershipId}/consumers/${consumerId}`
          },
          {
            name: t('CustomerQuote.add_quote')
          }
        ]}
        consumer={consumer}
      />

      <QuoteModule
        variant={QuoteModuleVariantType.CustomerQuote}
        vehicle={vehicle}
        initialCosts={defaultValues}
        onCancel={onCancel}
        onProceed={handleProceed}
        customerEmail={consumer?.Email}
        enableShowroomCfc={canUseCfcIvendiv2 && consumer?.CustomerType.toLowerCase() === 'consumer'}
        enableSendDeal={canUseSendDeal}
        assignees={assignees}
        onSave={saveCustomerQuote}
        isCustomerQuote={true}
        onSendDeal={onSendDeal}
        customerType={consumer?.CustomerType}
        customerId={consumerId}
        applicationCreationLoading={isLoading}
      />
    </Page>
  );
};

function mapStateToProps(state: FixLater, ownProps: FixLater) {
  const customer: CustomerEntityStateType = getCustomer(state, ownProps.params.consumerId);

  return {
    session: state.session as Session,
    consumer: customer || {},
    customerType: customer ? customer.CustomerType : null,
    assignees: customer ? customer.assignedTo : [],
    components: state.components as FixLater
  };
}

function mapDispatchToProps(dispatch: FixLater, ownProps: FixLater) {
  return {
    load: (componentName: string) => dispatch(componentActions.componentLoad(componentName, ownProps.params)),
    unload: (componentName: string) => dispatch(componentActions.componentUnload(componentName, ownProps.params)),
    createApplication: (vehicle: CustomerVehicleOfInterest, quote: QuoteTypes, customerType: CustomerType) =>
      dispatch(applicationActions.createApplication(vehicle, quote, ownProps.params.consumerId, customerType)),
    onCancel: () => {
      const { dealershipId, consumerId } = ownProps.params;
      push(`/d/${dealershipId}/consumers/${consumerId}`);
    },
    openSaveQuoteModal: (quote: QuoteTypes) => {
      dispatch(quickQuoteActions.changeQuote(quote));
      dispatch(modalActions.open('saveQuickQuote'));
    },
    setupQuoteStoreForFinanceNavigator: (
      quotes: QuoteTypes[],
      vehicle: CustomerVehicleOfInterest,
      quoteRequest: CustomerQuoteDefaultValues
    ) => {
      dispatch(quotesActions.prePopulateQuotesAndVehicle(quotes, vehicle, quoteRequest));
    },
    saveCustomerQuote: (quote: QuoteTypes) => {
      dispatch(consumerActions.saveCustomerQuote(quote, ownProps.params.consumerId));
    },
    onSendDeal: (quotes: QuoteTypes) => {
      dispatch(modalActions.open('sendDeal', { quotes, ...ownProps.params }));
    }
  };
}

export default compose(
  withParams,
  connect(mapStateToProps, mapDispatchToProps),
  // @ts-ignore
  inject(['appStore']),
  observerForHooks
)(CustomerQuote);
