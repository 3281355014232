import { Trans, useTranslation } from 'react-i18next';
import { Tag } from '~/components/Common/Tags';
import { DisplayQuoteFormat, QuoteCardVariantType, QuoteTypes } from '../../types';
import { useGetQuoteChecks } from '../../hooks';
import { CustomerFinanceApplication } from '~/types/application';
import { QuoteCardConfig } from '../../config/default';
import moment from 'moment';
import { isFinanceNavigatorEligibilityQuote } from '../../utils/quoteFeatures';
import DateFormat from '~/components/Common/DateFormat';

export type QuoteTagsProps = {
  quote: QuoteTypes;
  variant: QuoteCardVariantType;
  application?: CustomerFinanceApplication;
  config: QuoteCardConfig;
};
const QuoteTags = ({ quote, variant, application, config }: QuoteTagsProps) => {
  const { t } = useTranslation('QuoteCard');

  const {
    isLegacyPreApprovalQuote,
    isFnPreApprovalQuote,
    isRequote,
    isFinanceApplication,
    isFnEligibilityQuote,
    isFnRateForRiskQuote,
    canShowEligibilityResult,
    isFinanceNavigatorCheck
  } = useGetQuoteChecks({ quote, variant, application });

  const canShowPreApprovalTags =
    (isLegacyPreApprovalQuote || isFnPreApprovalQuote) &&
    !isRequote &&
    !isFinanceApplication &&
    !isFinanceNavigatorCheck;
  const canShowFnEligibilityTags = isFnEligibilityQuote && !isFinanceApplication && !canShowEligibilityResult;
  const canShowFnRateForRiskTags = isFnRateForRiskQuote && !isFinanceApplication && !canShowEligibilityResult;

  const hasTags =
    canShowFnEligibilityTags ||
    canShowFnRateForRiskTags ||
    canShowPreApprovalTags ||
    config.showExpiryDate ||
    config.showExpired;

  return hasTags ? (
    <div className="quoteCard__tags">
      {canShowPreApprovalTags && (
        <>
          <Tag
            dataTh="quoteCard__tags--soft-search"
            text={t('QuoteCardTags.soft_check')}
            startIconName="information"
            level="info"
            bold={true}
          />
          <Tag
            dataTh="quoteCard__tags--pre-approval"
            text={t('QuoteCardTags.pre_approval_available')}
            startIconName="validation-tick"
            level="success"
          />
        </>
      )}
      {canShowFnRateForRiskTags && (
        <Tag
          dataTh="quoteCard__tags--soft-check-fn-r4r"
          text={t('QuoteCardTags.soft_check_fn_r4r')}
          startIconName="information"
          level="info"
          bold={true}
        />
      )}
      {canShowFnEligibilityTags && (
        <Tag
          dataTh="quoteCard__tags--soft-check-fn-eligibility"
          text={t('QuoteCardTags.soft_check_fn_eligibility')}
          startIconName="information"
          level="info"
          bold={true}
        />
      )}
      {config.showExpiryDate && isFinanceNavigatorEligibilityQuote(quote) && (
        <Tag
          dataTh="quoteCard__tags--quote_expires_on"
          text={
            <Trans
              ns="QuoteCard"
              i18nKey="QuoteCardTags.quote_expires_on"
              components={{
                date: <DateFormat value={quote.Eligibility.Data?.ExpiryDate} format="Do MMM [at] HH:mm" />
              }}
            />
          }
          startIconName="warning"
          level="warning"
          bold={true}
        />
      )}
      {config.showExpired && (
        <Tag
          dataTh="quoteCard__tags--expired"
          text={t('QuoteCardTags.expired')}
          startIconName="information"
          bold={true}
        />
      )}
    </div>
  ) : null;
};

export default QuoteTags;
