import {
  DisplayQuoteFormat,
  FinanceNavigatorResultDataType,
  PreApprovalType,
  QuoteRowType,
  QuoteTypes
} from '../../types';
import { isFinanceNavigatorEligibilityQuote } from '../../utils/quoteFeatures';
import { HeaderFigureLabel, HeaderFiguresConfigType, HeaderFiguresDataType } from './types';

export const getHeaderFigures = (quote: QuoteTypes, displayQuote: DisplayQuoteFormat): HeaderFiguresDataType => ({
  [HeaderFigureLabel.MonthlyPayment]: {
    value: quote.FollowingPayments,
    type: QuoteRowType.money
  },
  [HeaderFigureLabel.Apr]: displayQuote.Apr,
  [HeaderFigureLabel.AgreementTerm]: displayQuote.AgreementTerm,
  [HeaderFigureLabel.TotalAmountPayable]: displayQuote.TotalAmountPayable
});

/*
 * Generates the HeaderFigure config obj to help determine if alert Tags, 'View Details' buttons and modals
 * should be displayed depending on the quote type and provided eligibility content.
 */
export const getHeaderFiguresConfig = (quote: QuoteTypes): HeaderFiguresConfigType => {
  // most of the  below variable definitions can be gathered from useGetQuoteChecks when it is more reusable
  const canShowIndicativeAprTag = (!quote?.isRequote && quote?.CreditTier && quote.CreditTier !== 'None') || false;

  /* Specific to Finance Navigator Eligibility Check quotes */
  const hasRateForRiskContent = isFinanceNavigatorEligibilityQuote(quote)
    ? !!quote.Eligibility.OriginalValuesNowDifferent?.RepresentativeApr
    : false;

  return {
    canShowAlertTag: canShowIndicativeAprTag || hasRateForRiskContent,
    canShowIndicativeAprTag,
    hasRateForRiskContent
  };
};
