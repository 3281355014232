import { Dealership } from '~/types/dealership';
import './dealerInfo.scss';

type DealerInfoProps = {
  dealership: Dealership;
};
const DealerInfo = ({ dealership }: DealerInfoProps) => {
  return (
    <div className="dealerInfo">
      <div className="dealerInfo__name">{dealership.TradingName || dealership.CompanyName}</div>
      <div className="dealerInfo__address">
        {[dealership.Address1, dealership.Address2, dealership.PostalTown, dealership.Postcode]
          .filter(Boolean)
          .join(', ')}
      </div>
    </div>
  );
};

export default DealerInfo;
