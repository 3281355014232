import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '~Common/Icon/Icon';
import classes from './fixIssuesVehicleCard.module.scss';

const FixIssuesVehicleCard = ({ vehicle, isSelected, onClick }) => {
  const { t } = useTranslation('Stock');

  const handleClick = () => {
    onClick(vehicle);
  };

  const handleIconName = (vehicleClass) => {
    if (vehicleClass?.toLowerCase() === 'lcv') {
      return 'van';
    } else {
      return vehicleClass.toLowerCase();
    }
  };

  let fuelType = vehicle.FuelType && vehicle.FuelType.replace('/', ' / ');
  let generation = vehicle.Generation && vehicle.Generation.replace('(', ' (');

  return (
    <div className={cn(classes.container, isSelected && classes.containerSelected)} onClick={handleClick}>
      <div className={classes.top}>
        <div className={classes.vehicleTopContainer}>
          <div className={classes.vehicleIcon}>
            <Icon name={handleIconName(vehicle.Class)} />
          </div>
          <div className={classes.vehicleMakeModelText}>
            <strong>{`${vehicle.Make} ${vehicle.Model}`}</strong>
          </div>
          <span className={classes.derivativeTitle}>{vehicle.Derivative || '-'}</span>
        </div>
      </div>

      <div className={classes.bottom}>
        <div className={classes.bottomSection}>
          <span className={classes.vehicleValues}>
            {fuelType || '-'} | {vehicle.Doors || '-'} {t('KeyInformationSection.doors')} |{' '}
            {vehicle.Transmission || '-'} | {generation || '-'} | {vehicle.EnginePowerBHP || '-'} BHP |{' '}
            {vehicle.DriveTrain || '-'} | {vehicle.BodyStyle || '-'} |{' '}
            {vehicle.Class || '-'} | {vehicle.NoofSeats || '-'} {t('KeyInformationSection.seats')}
          </span>
        </div>
      </div>
    </div>
  );
};

FixIssuesVehicleCard.propTypes = {
  vehicle: PropTypes.object,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func
};

export default FixIssuesVehicleCard;
