import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import Page from '../../Common/Page';
import TrackPageView from '../../Common/Tracking/TrackPageView';
import PanelHeader from '../../Common/PanelHeader';
import PanelToolBar from '../../Common/PanelToolBar';
import PanelContent from '../../Common/PanelContent';
import AlignRight from '../../Common/AlignRight';
import Select from '../../Common/Form/Select';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';
import SentDeals from './SentDeals';
import Pagination from '../../Common/Pagination';
import Button from '../../Common/Button/Button';
import Orders from './Orders';
import Tabs from '../../Common/Tabs/Tabs';
import TabList from '../../Common/Tabs/TabList';
import TabContent from '../../Common/Tabs/TabContent';
import QuoteTabItem from '../../Customer/components/QuoteTabItem';
import Modal from '../../Common/Modal/Modal';
import OrdersAndDealsHelpModal from './OrdersAndDealsHelpModal';
import ButtonInfoIcon from '../../Common/Button/ButtonInfoIcon';
import './sentDeals.scss';
import { compose } from 'redux';
import { withNavigate, withParams, withQuery } from '../../../hocs/router';
import { objectToSearchParams } from 'utils/objectToSearchParams';
import { canUseSendDealViaQuoteResultsPage } from '~/features';
class DigitalDeals extends Component {
  dealTabs = ['OnlineOrders', 'DigitalDeals'];

  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.query.activeTab || null,
      dealStatus: this.props.query.dealStatus || 'all',
      sortBy: this.props.query.sortBy || 'sentAt',
      page: parseInt(this.props.query.page, 10) || 1,
      pageSize: parseInt(this.props.query.pageSize, 10) || 5,
      showSentDeals: false,
      isHelpModalOpen: false
    };
  }

  componentDidMount() {
    const sendDealPromise = this.props.appStore.uiState.canUsePushDeal && this.getSentDealsData();
    !this.props.appStore.uiState.canUsePushDeal && this.props.appStore.sentDealsStore.resetSentDeals();
    !this.props.appStore.uiState.hasOrders && this.props.appStore.ordersStore.resetOrders();
    const ordersPromise =
      this.props.appStore.uiState.hasOrders &&
      this.props.appStore.ordersStore.getOrders(this.props.params.dealershipId, this.state.page, this.state.pageSize);
    Promise.all([sendDealPromise, ordersPromise]).then(() => {
      const orders = this.props.appStore.ordersStore.orders;
      const deals = this.props.appStore.sentDealsStore.sentDealsData;

      if (this.state.activeTab === 'DigitalDeals') {
        this.setActiveTab(1);
      }

      if (!this.state.activeTab) {
        if (orders && orders.length > 0) {
          this.setActiveTab(0);
        } else if (deals && deals.totalCustomerCount > 0) {
          this.setActiveTab(1);
        }
      }
    });
  }

  getSentDealsData = () => {
    const {
      params: { dealershipId }
    } = this.props;
    const { dealStatus, page, pageSize, sortBy } = this.state;
    return this.props.appStore.sentDealsStore.getSentDealsData(dealershipId, page, pageSize, dealStatus, sortBy);
  };
  getOrdersData = () => {
    this.props.appStore.ordersStore.getOrders(this.props.params.dealershipId, this.state.page, this.state.pageSize);
  };
  updateQuery = (newParams) => {
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/orders-and-deals`,
      search: `?${objectToSearchParams({ ...this.props.query, ...newParams })}`
    });
  };
  handleSortByFilterChange = (filter) => {
    const { sortBy } = this.state;

    if (filter.currentTarget.value !== sortBy) {
      this.setState(
        {
          sortBy: filter.currentTarget.value,
          page: 1
        },
        () => {
          this.updateQuery({
            sortBy: this.state.sortBy,
            page: 1
          });
          this.getSentDealsData();
        }
      );
    }
  };
  handleStatusFilterChange = (filter) => {
    const { dealStatus } = this.state;

    if (filter.currentTarget.value !== dealStatus) {
      this.setState(
        {
          dealStatus: filter.currentTarget.value,
          page: 1
        },
        this.refreshDealsDataOnFilter
      );
    }
  };
  refreshDealsDataOnFilter = () => {
    this.updateQuery({
      dealStatus: this.state.dealStatus,
      page: 1
    });
    this.getSentDealsData();
    this.getOrdersData();
  };
  handlePageChange = (newPage) => {
    const { page } = this.state;

    if (newPage !== page) {
      this.setState(
        {
          page: newPage
        },
        this.refreshDealsDataOnPageChange
      );
    }
  };
  refreshDealsDataOnPageChange = () => {
    this.updateQuery({
      page: this.state.page
    });
    window.scrollTo(0, 0);

    if (this.state.showSentDeals) {
      this.getSentDealsData();
    } else {
      this.getOrdersData();
    }
  };
  getDealTabIndex = () => {
    let index = this.dealTabs.indexOf(this.state.activeTab);
    return !index || index < 0 ? 0 : index;
  };
  setActiveTab = (index) => {
    this.setState(
      {
        activeTab: this.dealTabs[index],
        showSentDeals: index === 1 ? true : false,
        page: 1
      },
      this.refreshDealsDataOnPageChange
    );
    this.updateQuery({
      activeTab: this.dealTabs[index]
    });
  };
  renderHelpButton = () => {
    return (
      <span className="sentDeals__helpButton">
        <ButtonInfoIcon onClick={this.openHelpModal} />
      </span>
    );
  };
  openHelpModal = () => {
    this.setState({
      isHelpModalOpen: true
    });
  };
  closeHelpModal = () => {
    this.setState({
      isHelpModalOpen: false
    });
  };

  render() {
    const {
      appStore: {
        sentDealsStore,
        ordersStore,
        uiState: { canUseOrders, canUsePushDeal, canGenerateQuotes, isBdk }
      },
      params: { dealershipId }
    } = this.props;
    const { dealStatus, page, pageSize, sortBy, showSentDeals } = this.state;
    const isSentDealsLoading = sentDealsStore.isSentDealsLoading;
    const isOrdersLoading = ordersStore.ordersRequest.isLoading;
    const customersWithDeals = sentDealsStore.sentDealsData.customersWithDeals;
    const totalSentDealsCount = sentDealsStore.sentDealsData.totalCustomerCount;
    const totalOrdersCount = ordersStore.totalOrderCount;
    const totalItemsCurrentSet = showSentDeals ? totalSentDealsCount : totalOrdersCount;
    const totalPageCount = showSentDeals
      ? sentDealsStore.sentDealsData.totalPageCount
      : Math.ceil(totalOrdersCount / pageSize);
    const shouldShowPagination = totalItemsCurrentSet > pageSize;
    const orders = ordersStore.orders;

    return (
      <Page>
        <TrackPageView dealershipId={dealershipId} />
        <Breadcrumbs
          items={[
            {
              name: this.props.t('DigitalDeals.home'),
              path: `/d/${dealershipId}`,
              isHome: true
            },
            {
              name: this.props.t('DigitalDeals.orders_deals')
            }
          ]}
        />
        <Panel>
          <PanelHeader>
            <div className="sentDeals__header">
              <span className="sentDeals__headerText">
                {this.props.t('DigitalDeals.online_orders_and_digital_deals')} {this.renderHelpButton()}
              </span>
              {canUsePushDeal && canGenerateQuotes && !isBdk && !canUseSendDealViaQuoteResultsPage() && (
                <div className="sentDeals__headerButton">
                  <Button to={`/d/${dealershipId}/send-deal`}>{this.props.t('DigitalDeals.send_deal')}</Button>
                </div>
              )}
            </div>
          </PanelHeader>
          <PanelToolBar>
            <AlignRight>
              <div className="sentDeals__dropDowns">
                <div className="sentDeals__sortBy">
                  <span>{this.props.t('DigitalDeals.sort_by')}</span>
                  <Select
                    theme="alt"
                    emptyValue={false}
                    value={sortBy}
                    options="SentDealsSoryByOptions"
                    onChange={this.handleSortByFilterChange}
                    id="sentDealsSortBy"
                  />
                </div>
                <div className="sentDeals__filter">
                  <span>{this.props.t('DigitalDeals.filter_status')}</span>
                  <Select
                    theme="alt"
                    emptyValue={false}
                    value={dealStatus}
                    options="SentDealsFilterOptions"
                    onChange={this.handleStatusFilterChange}
                    id="sentDealsFilter"
                  />
                </div>
              </div>
            </AlignRight>
          </PanelToolBar>
          <PanelContent>
            <Tabs currentTab={this.getDealTabIndex()} setActiveTab={this.setActiveTab}>
              <TabList hasShadowSpace>
                <QuoteTabItem
                  dataThook="onlineOrdersTab"
                  text={this.props.t('DigitalDeals.orders_received')}
                  iconName="online-order"
                  count={totalOrdersCount || 0}
                />

                <QuoteTabItem
                  dataThook="digitalDealsTab"
                  text={this.props.t('DigitalDeals.deals_sent')}
                  iconName="send-deal"
                  count={totalSentDealsCount || 0}
                />
              </TabList>
              <TabContent>
                <div>
                  {isOrdersLoading ? (
                    <div className="sentDeals__loading">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <Orders dealershipId={dealershipId} orders={orders} canUseOrders={canUseOrders} />
                  )}
                </div>
                <div>
                  {isSentDealsLoading ? (
                    <div className="sentDeals__loading">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <SentDeals
                      customersWithDeals={customersWithDeals}
                      dealStatus={dealStatus}
                      dealershipId={dealershipId}
                      canUsePushDeal={canUsePushDeal}
                    />
                  )}
                </div>
              </TabContent>
            </Tabs>

            {shouldShowPagination && (
              <Pagination
                currentPage={page}
                totalItems={totalItemsCurrentSet}
                pageSize={pageSize}
                onChange={this.handlePageChange}
                totalPages={totalPageCount}
              />
            )}
          </PanelContent>
        </Panel>
        <Modal isOpen={this.state.isHelpModalOpen} onClose={this.closeHelpModal}>
          <OrdersAndDealsHelpModal />
        </Modal>
      </Page>
    );
  }
}

DigitalDeals.propTypes = {
  appStore: PropTypes.object
};

export default compose(
  withParams,
  withNavigate,
  withQuery,
  withTranslation('DigitalDeals'),
  inject(['appStore']),
  observer
)(DigitalDeals);
