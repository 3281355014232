export enum QuoteModuleVariantType {
  QuickQuote = 'QuickQuote',
  CustomerQuote = 'CustomerQuote',
  ApplicationRequote = 'ApplicationRequote',
  ApplicationRepropose = 'ApplicationRepropose',
  ApplicationQuotePreSubmission = 'ApplicationQuotePreSubmission',
  EditApplicationQuote = 'EditApplicationQuote'
}

export type QuoteModuleVariants = keyof typeof QuoteModuleVariantType;
