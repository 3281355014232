import * as debug from '~/debug';
import { isBike, transformVehicleClass } from '~/utils/vehicle';



const transformTaxonomyData = (vehicleTaxonomyData) => {
  const klass = transformVehicleClass(vehicleTaxonomyData.Class);
  const isMotorcycle = isBike(klass);

  return {
    class: klass,
    make: vehicleTaxonomyData.Make || '',
    model: vehicleTaxonomyData.Model || '',
    derivative: vehicleTaxonomyData.Derivative || '',
    bodyStyle: vehicleTaxonomyData.BodyStyle || '',
    doors: vehicleTaxonomyData.Doors ? String(vehicleTaxonomyData.Doors) : '',
    seats: isMotorcycle ? 1 : vehicleTaxonomyData.NoofSeats || '',
    transmission: isMotorcycle ? 'Manual' : vehicleTaxonomyData.Transmission || '',
    fuelType: isMotorcycle ? 'Petrol' : vehicleTaxonomyData.FuelType || '',
    cc: vehicleTaxonomyData.CC || '',
    bhp: vehicleTaxonomyData.EnginePowerBHP || ''
  };
};

export default transformTaxonomyData;
