import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import QuoteCard from '~/components/QuoteCard/QuoteCard';
import NoPanelData from '../../Dashboard/components/NoPanelData';
import { QuoteCardVariantTypeJs } from '~/components/QuoteCard/types';

const FinanceApplications = (props) => {
  const { t } = useTranslation('Customer');

  return (
    <div className="financeApplications">
      {props.financeApplications.length > 0 ? (
        props.financeApplications.map((application) => (
          <QuoteCard
            variant={QuoteCardVariantTypeJs.FinanceApplication}
            quote={application.Quote}
            application={application}
            applicantName={application.Name}
            lenderStatus={application.LenderStatus}
            actions={{
              onSave: props.onSave,
              onProceed: props.onProceed,
              onProceedToBreakDown: props.proceedToBreakDown,
              onProductNameClick: props.onProductNameClick,
              onQuoteSelect: props.onQuoteSelect,
              onCombinedQuoteClick: props.showCombinedQuoteClick,
              viewApplication: props.viewApplication,
              editApplication: props.handleEdit
            }}
            isQuickQuote={false} // This was never passed in from the parent, so hard-coding
            vehicleClass={props.vehicleClass}
            isCustomerQuote={false} // This was never passed in from the parent, so hard-coding
            chosenQuoteId={props.chosenQuoteId}
            proceedingState={props.proceedingState}
            financeApplications={props.financeApplications}
            key={`FinanceApplication-${application.Quote.QuoteId}`}
          />
        ))
      ) : (
        <NoPanelData text={t('FinanceApplications.there_are_currently_no_finance_applications_for_this_vehicle')} />
      )}
    </div>
  );
};

FinanceApplications.propTypes = {
  vehicleId: PropTypes.string,
  financeApplications: PropTypes.array,
  onSortFinanceApplications: PropTypes.func,
  viewApplication: PropTypes.func,
  handleEdit: PropTypes.func,
  sortDirection: PropTypes.string,
  sortField: PropTypes.string,
  vehicleClass: PropTypes.string
};

export default FinanceApplications;
