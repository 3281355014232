import React, { ReactNode } from 'react';
import Icon, { IconNames } from '~/components/Common/Icon/Icon';
import cn from 'classnames';
import './tags.scss';

interface Props {
  level?: 'default' | 'info' | 'warning' | 'error' | 'success';
  onClick?: () => void;
  text: string | ReactNode;
  dataTh?: string;
  startIconName?: IconNames;
  endIconName?: IconNames;
  bold?: boolean;
}

export const Tag = ({ dataTh, onClick, startIconName, endIconName, text, level = 'default', bold = false }: Props) => {
  return (
    <div
      data-th={dataTh}
      onClick={onClick}
      className={cn('tagWithIcon', level, onClick && 'clickable', bold && 'bold')}
    >
      {startIconName && <Icon className="iconStart" name={startIconName} />}
      <span className="tagText">{text}</span>
      {endIconName && <Icon className="iconEnd" name={endIconName} />}
    </div>
  );
};

export const RedTag = ({ onClick, text, dataTh = 'redTag' }: Props) => {
  return <Tag onClick={onClick} text={text} dataTh={dataTh} level="error" startIconName="question" />;
};

export const GrayTag = ({ onClick, text, dataTh = 'grayTag' }: Props) => {
  return <Tag onClick={onClick} text={text} dataTh={dataTh} startIconName="bin" endIconName="cross-2" />;
};

export const YellowTag = ({ onClick, text, dataTh = 'yellowTag' }: Props) => {
  return <Tag onClick={onClick} text={text} dataTh={dataTh} level="warning" startIconName="warning" />;
};
