import { z } from 'zod';

const NamesSchema = z.object({
  Title: z.string(),
  Firstname: z.string(),
  Middlenames: z.string().optional(),
  Surname: z.string()
});

const IdentitySchema = z.object({
  Names: NamesSchema,
  Dob: z.date()
});

const ContactInfoSchema = z.object({
  Email: z.object({ Address: z.string() }),
  MobileTelephone: z.string()
});

const AddressHistorySchema = z.object({
  BuildingNumber: z.string(),
  BuildingName: z.string(),
  SubBuilding: z.string(),
  Street: z.string(),
  District: z.string(),
  PostTown: z.string(),
  Postcode: z.string(),
  RawResidency: z.string(),
  MonthsAtAddress: z.number()
});

const EmploymentHistorySchema = z.object({
  RawSkillLevel: z.string(),
  RawStatus: z.string(),
  MonthsInJob: z.number()
});

const AffordabilityDetailsSchema = z.object({
  GrossAnnualIncome: z.string(),
  HasOtherHouseholdIncome: z.boolean(),
  OtherHouseholdIncome: z.string(),
  NetMonthlyIncome: z.string(),
  MortgageOrRentExpenditure: z.string(),
  OtherCommittedMonthlyOutgoings: z.string(),
  ReplacementLoan: z.boolean(),
  ReplacementLoanAmount: z.string(),
  ForeseeFutureDownturnInFinances: z.boolean(),
  DownturnReason: z.string(),
  ForeseenYearlyIncome: z.string()
});

export const FinanceScanSchema = z.object({
  DealershipId: z.string(),
  ConsumerId: z.string(),
  Identity: IdentitySchema,
  ContactInfo: ContactInfoSchema,
  NoOfDependants: z.string(),
  MaritalStatus: z.string(),
  AddressHistory: z.array(AddressHistorySchema),
  EmploymentHistory: z.array(EmploymentHistorySchema),
  AffordabilityDetails: AffordabilityDetailsSchema,
  Status: z.string()
});

export const StartFinanceScanRequestSchema = z.object({
  QuoteIds: z.array(z.string()),
  Identity: IdentitySchema,
  ContactInfo: ContactInfoSchema,
  NoOfDependants: z.string(),
  MaritalStatus: z.string(),
  AddressHistory: z.array(AddressHistorySchema),
  EmploymentHistory: z.array(EmploymentHistorySchema),
  AffordabilityDetails: AffordabilityDetailsSchema
});

export const PreFillFinanceScanForCustomerResponseSchema = z.object({
  Identity: IdentitySchema.extend({
    Dob: z.string().nullable(),
    Names: NamesSchema.extend({
      Title: z.string().nullable(),
      Middlenames: z
        .string()
        .nullable()
        .optional()
    })
  }),
  ContactInfo: ContactInfoSchema.extend({ MobileTelephone: z.string().nullable() }),
  NoOfDependants: z.string().nullable(),
  MaritalStatus: z.string().nullable(),
  AddressHistory: z
    .array(AddressHistorySchema.extend({ RawResidency: z.string().nullable(), MonthsAtAddress: z.number().nullable() }))
    .nullable(),
  EmploymentHistory: z.array(EmploymentHistorySchema).nullable(),
  AffordabilityDetails: AffordabilityDetailsSchema.nullable()
});

export const StartFinanceScanResponseSchema = z.object({ Id: z.string() });

export type StartFinanceScanRequest = z.infer<typeof StartFinanceScanRequestSchema>;
export type StartFinanceScanResponse = z.infer<typeof StartFinanceScanResponseSchema>;
export type FinanceScan = z.infer<typeof FinanceScanSchema>;
export type PreFillFinanceScanForCustomerResponse = z.infer<typeof PreFillFinanceScanForCustomerResponseSchema>;
