const getLender = (country: string) => {
  switch (country.toLowerCase()) {
    case 'de':
      return 'schwacke';
    case 'gb':
    case 'uk':
    default:
      return 'multilender';
  }
};

const normaliseVisCountry = (country: string) => {
  switch (country.toLowerCase()) {
    case 'de':
      return 'de';
    case 'gb':
    case 'uk':
    default:
      return 'uk';
  }
};

interface VisRequestProps {
  query: string;
  klass: string;
  currentPage?: number;
  pageSize?: number;
  country?: string;
}
//  src/mobx-stores/StockStore.js
export const visRequest = ({ query, klass = '', currentPage = 1, pageSize = 5, country = 'uk' }: VisRequestProps) => {
  const params = new URLSearchParams({
    country: normaliseVisCountry(country),
    lender: getLender(country),
    class: klass,
    page: currentPage + '',
    pageSize: pageSize + '',
    query
  });
  const url = `/platform/v1/vis/Search?${params.toString()}`;

  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET'
    })
      .then((response) => response.json())
      .then(resolve)
      .catch(reject);
  });
};

/*
 src/modules/Stock/routes/stock/routes/add/routes/lookup/StockLookupContainer.js
 src/modules/Stock/routes/stock/routes/list/components/PushSidebar/PushSidebar.js
 src/modules/Stock/components/PushSidebar.js
 src/mobx-stores/StockStore.js
*/
export const fetchVisVehicle = (primaryId: string, secondaryId: string, klass = 'car') => {
  const params = new URLSearchParams({
    country: 'uk',
    lender: 'multilender',
    class: klass,
    primaryTaxonomyId: primaryId,
    secondaryTaxonomyId: secondaryId
  });
  const url = `/platform/v1/vis/taxonomy?${params.toString()}`;
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET'
    })
      .then((response) => response.json())
      .then(resolve)
      .catch(reject);
  });
};

interface VisMotorHomeRequestProps {
  query: string;
  currentPage?: number;
  pageSize?: number;
}
export const visMotorhomeRequest = ({ query, currentPage = 1, pageSize = 5 }: VisMotorHomeRequestProps) => {
  const params = new URLSearchParams({
    country: 'uk',
    lender: 'multilender',
    pageSize: pageSize + '',
    page: currentPage + '',
    query
  });
  const url = `/platform/v1/vis/motorhomes-search?${params.toString()}`;

  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET'
    })
      .then((response) => response.json())
      .then(resolve)
      .catch(reject);
  });
};

export interface RawVisResponse {
  Alloys: boolean;
  BodyStyle: string;
  CC: number;
  CatalyticConvertor: boolean;
  Class: string;
  DataSource: string;
  DateDiscontinued: string;
  DateIntroduced: string;
  Derivative: string;
  DimensionsRating: string;
  Doors?: number | string | null;
  DriveTrain?: string;
  EconomyRating: string;
  EnginePowerBHP?: number;
  EnginePowerKW?: number;
  EnginePowerPS: boolean;
  EnginePowerRPM?: number;
  Expr1?: string;
  FuelTankCapacityLitres?: number;
  Gears?: string;
  GenerationId: string;
  HasTo60mphsecs: boolean;
  HistoricGenerationId: string;
  ImportedDate: string;
  InProduction: boolean;
  InsuranceRating: string;
  ListPrice: number;
  LuggageRating: string;
  Make: string;
  MinKerbWeight?: string;
  Model: string;
  PowerRating: string;
  PrimaryTaxonomyId: string;
  SafetyConcerns: boolean;
  SecondaryTaxonomyId: string;
  SpaceSaver: boolean;
  SpeedRating: string;
  TankCapacity?: string;
  Transmission?: string;
  YearsInProduction: number[];
  objectID: string;
  EngineTorqueNM?: number;
  GrossVehicleWeight?: string;
  Height?: string;
  Length?: number;
  Wheelbase?: number;
  Width?: string;
  AltBodyType?: string[];
  AltGeneration?: string;
  BadgeEngineCC?: number;
  ECDirective99?: string;
  FuelDelivery?: string;
  FuelType?: string;
  Generation?: string;
  InsuranceGroup1?: string;
  InsuranceGroup150?: string;
  NCAPFrontSideImpactPreFeb09?: number;
  NCAPPedestrianPreFeb09?: number;
  NVBodyStyle?: string;
  Sector?: string;
  Trim?: string;
  VehicleHomologationClass?: string;
  FrontTyreSize?: string;
  NoofSeats?: number;
  RearTyreSize?: string;
  StandardmanufacturerswarrantyYears?: number;
  TopSpeed?: number;
  BadgePower?: number;
  CO2gkm?: number;
  ElectricRangeRating?: string;
  EngineTorqueLBSFT?: number;
  LuggageCapacitySeatsDown?: number;
  LuggageCapacitySeatsUp?: number;
  ManCorrosionPerforationGuaranteeYears?: number;
  ManufacturersPaintworkGuaranteeYears?: number;
  MaxLoadingWeight?: number;
  SpecialEdition?: boolean;
  SpecialOrder?: boolean;
  StandardmanufacturerswarrantyMileage?: number;
  To62mphsecs?: number;
  TurningCircleKerbtoKerb?: number;
  WheelStyle?: string;
  WheelType?: string;
  ServiceIntervalFrequencyMonths?: number;
  ServiceIntervalMileage?: string;
  AltDateDiscontinued?: string;
  AltDateIntroduced?: string;
}
