import _ from 'lodash';
import { takeEvery } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import { compareMonthlyPayments } from '../../utils/quoteUtils';
import * as quoteActions from './quoteActions';
import { parseViewMonthlyPayments } from '../../core/apiDataParsers/quotesParser';
import { logError } from '../../core/helpers';
import { getVehicleLoanProductId } from '~/components/PushDeal/utils/quoteUtils';
import {
  trackFinanceQuoteEligibilitySelected,
  trackFinanceQuotesFailed,
  trackFinanceQuotesSorted
} from '~/tracking/avoTracking';

export function* watchViewMonthlyPayments() {
  yield call(takeEvery, actionTypes.VIEW_MONTHLY_PAYMENTS, fetchMonthlyPayments);
}

export function* watchChangePaymentTerm() {
  yield call(takeEvery, actionTypes.CHANGE_MONTHLY_PAYMENT_TERM, updateQuotesForCompare);
}

export function* watchAvoTrackedQuotingActions() {
  yield [
    takeEvery(actionTypes.FETCH_QUOTES_ERROR, fireFinanceQuotesFailedEvent),
    takeEvery(actionTypes.SORT_QUOTE_MODULE, fireFinanceQuotesSortedEvent),
    takeEvery(actionTypes.CHANGE_QUOTE_VIEW, fireFinanceQuoteEligibilitySelectedEvent)
  ];
}

export function* fireFinanceQuotesFailedEvent(action) {
  const { quotes, dealership } = yield select((state) => ({
    quotes: state.quotes,
    dealership: state.dealership
  }));
  const { vehicle, quoteRequest } = quotes;
  trackFinanceQuotesFailed({
    financeQuoteCashDeposit: quoteRequest.CashDeposit,
    financeQuoteMileage: quoteRequest.AnnualDistance,
    financeQuoteTerm: quoteRequest.Term,
    dealershipId: dealership.Id,
    consumerEntityType: quotes.customerType,
    vehicleClass: vehicle.Class,
    vehicleCondition: vehicle.Condition,
    vehicleDerivative: vehicle.Derivative,
    vehicleMake: vehicle.Make,
    vehicleModel: vehicle.Model,
    vehicleVin: quoteRequest.VehicleVin ?? '',
    vehicleMileage: vehicle.Mileage,
    vehiclePrice: vehicle.Price,
    vehicleVrm: vehicle.Vrm,
    financeQuoteErrorMessage: action.error?.message ?? ''
  });
}

export function* fireFinanceQuotesSortedEvent() {
  const { quotes, dealership } = yield select((state) => ({
    quotes: state.quotes,
    dealership: state.dealership
  }));
  const { vehicle, quoteRequest, sortField, sortDirection } = quotes;
  trackFinanceQuotesSorted({
    dealershipId: dealership.Id,
    consumerEntityType: quotes.customerType,
    vehicleClass: vehicle.Class,
    vehicleCondition: vehicle.Condition,
    vehicleDerivative: vehicle.Derivative,
    vehicleMake: vehicle.Make,
    vehicleModel: vehicle.Model,
    vehicleVin: quoteRequest.VehicleVin ?? '',
    vehicleMileage: vehicle.Mileage,
    vehiclePrice: vehicle.Price,
    vehicleVrm: vehicle.Vrm,
    financeQuotesSortDirection: sortDirection,
    financeQuotesSortField: sortField
  });
}

export function* fireFinanceQuoteEligibilitySelectedEvent(action) {
  if (action.view === 'CHECK_ELIGIBILITY_VIEW') {
    const { quotes, dealership } = yield select((state) => ({
      quotes: state.quotes,
      dealership: state.dealership
    }));
    const { vehicle, quoteRequest, sortField, sortDirection } = quotes;
    trackFinanceQuoteEligibilitySelected({
      dealershipId: dealership.Id,
      consumerEntityType: quotes.customerType,
      vehicleClass: vehicle.Class,
      vehicleCondition: vehicle.Condition,
      vehicleDerivative: vehicle.Derivative,
      vehicleMake: vehicle.Make,
      vehicleModel: vehicle.Model,
      vehicleVin: quoteRequest.VehicleVin ?? '',
      vehicleMileage: vehicle.Mileage,
      vehiclePrice: vehicle.Price,
      vehicleVrm: vehicle.Vrm,
      financeQuotesSortDirection: sortDirection,
      financeQuotesSortField: sortField
    });
  }
}

export function* fetchMonthlyPayments() {
  const quotesState = yield select(getQuotesState);

  const { quotesForCompare, vehicle, quoteRequest, productSettings } = quotesState;

  const quoteeUid = quotesForCompare[0].QuoteeUid;
  const productSettingsForSelectedProducts = _.map(quotesForCompare, (quote) =>
    _.find(productSettings, { ProductUid: getVehicleLoanProductId(quote) })
  );
  const filteredProductSettingsForSelectedProducts = productSettingsForSelectedProducts.filter(
    (productSettings) => productSettings !== undefined
  );
  const combinedQuote = productSettingsForSelectedProducts.length !== filteredProductSettingsForSelectedProducts.length;

  let response;

  yield put({ type: actionTypes.FETCH_MONTHLY_PAYMENTS });

  try {
    response = yield call(
      compareMonthlyPayments,
      quoteeUid,
      vehicle,
      quoteRequest,
      filteredProductSettingsForSelectedProducts
    );
  } catch (e) {
    logError(e);
    yield put({ type: actionTypes.FETCH_MONTHLY_PAYMENTS_ERROR });
  }

  yield put({
    type: actionTypes.FETCH_MONTHLY_PAYMENTS_SUCCESS,
    response: parseViewMonthlyPayments(response, quotesForCompare, combinedQuote)
  });
}

export function* updateQuotesForCompare(action) {
  const quotesState = yield select(getQuotesState);
  const { quoteRequest, customerType } = quotesState;

  yield put(
    quoteActions.changeCosts({
      CustomerType: customerType,
      ...quoteRequest,
      AnnualDistance: quoteRequest.AnnualDistance,
      Term: action.term
    })
  );
}

const getQuotesState = (state) => state.quotes;
