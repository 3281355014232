import PropTypes from 'prop-types';

import QuoteCard from '~/components/QuoteCard/QuoteCard';

import './productTables.scss';

const ProductsTables = (props) => (
  <>
    {props.quotes.map((quote) => (
      <QuoteCard
        variant="SelfServiceDeals"
        quote={quote}
        vehicleClass={props.vehicleClass}
        consumerId={props.consumer.Id}
        vehicleId={props.vehicleId}
        key={`SelfServiceDeals-${quote.QuoteId}`}
      />
    ))}
  </>
);

ProductsTables.propTypes = {
  quotes: PropTypes.array,
  consumer: PropTypes.object,
  dealershipId: PropTypes.string,
  vehicleId: PropTypes.string,
  vehicleClass: PropTypes.string
};

export default ProductsTables;
