import { connect } from 'react-redux';
import RadioButtonGroup from './RadioButtonGroup';

interface YesNoInputProps {
  id: string;
  value: any | undefined;
  onChange: (id: string, value: any) => void;
  options: any;
  dataThook?: string;
}

const YesNoInput = (props: YesNoInputProps) => {
  const { id, value, onChange, dataThook } = props;
  return (
    <RadioButtonGroup
      name={id}
      dataThook={dataThook}
      options={props.options}
      checkedValue={value === undefined ? undefined : String(value)}
      variant="inline"
      key={id}
      isGroupDisabled={false}
      onChange={(e) => onChange(id, e.target.value === "true")}
    />
  );
};

function mapStateToProps(state: any) {
  return {
    options: state.options['YesOrNo']
  };
}

export default connect(mapStateToProps)(YesNoInput);
