import { useMemo } from 'react';
import { QuoteRowValue, QuoteTypes } from '../../types';

import { HeaderFigureLabelType } from './types';
import { getHeaderFiguresConfig } from './utils';

export type AdjustedFnQuoteFigureComparisonType = {
  [key in HeaderFigureLabelType]?: {
    originalValue: number | null;
    currentValue: QuoteRowValue | null;
  };
};

/*
 * Custom hook to manage the state and config of header figures content.
 * Determines if the quote is a Finance Navigator eligibility quote, formats the eligibility content,
 * and generates the header figures config. Also manages the state of the modal and selected figure.
 */
export const useGetHeaderFiguresContent = (quote: QuoteTypes) => {
  const { headerFiguresConfig } = useMemo(() => {
    const headerFiguresConfig = getHeaderFiguresConfig(quote);
    return { headerFiguresConfig };
  }, [quote]);
  return {
    headerFiguresConfig
  };
};
