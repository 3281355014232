import { withTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { inject } from 'mobx-react';
import { compose } from 'redux';

import { withParams } from 'hocs/router';
import observerForHooks from 'hocs/observerForHooks';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import { trackFinanceQuoteApplySelected } from '~/tracking/avoTracking';
import { trackQuoteModuleViews } from '~/tracking/avoTracking';

import QuoteCosts from '../QuoteCosts';
import QuoteResults from '../QuoteResults';
import ReproposeToSameLenderModal from '../../../Application/modals/ReproposeToSameLenderModal';
import CheckEligibility from '../CheckEligibility';
import QuotingNotAvailable from '../QuotingNotAvailable';
import CompareView from '../CompareView';
import Modal from '../../../Common/Modal/Modal';
import EditQuoteCommissionsForm from '../../forms/EditQuoteCommissionsForm';
import ConsumerVehicleInfoPanel from '~/components/Common/ConsumerVehicleInfoPanel';
import ConsumerDutyContainer from '../../../ConsumerDuty/containers/ConsumerDutyContainer';
import CombinedQuotesModal from '../../modals/CombinedQuotesModal';
import CombinedQuoteMessage from '../../../Common/CombinedQuoteMessage';
import LoansBreakdownView from '../LoansBreakdownView';
import AlertCard from '~/components/Common/AlertCard/AlertCard';
import { FinanceNavigatorContainer } from '~/components/FinanceNavigator/containers/FinanceNavigatorContainer';
import { FinanceNavigatorResultsContainer } from '~/components/FinanceNavigator/containers/financeNavigatorResultsContainer';
import './quoteModule.scss';

const QuoteModule = (props) => {
  const [chosenQuote, setChosenQuote] = useState(null);
  const [productForEditCommissions, setProductForEditCommissions] = useState(null);
  const [isEditQuoteModalOpen, setIsEditQuoteModalOpen] = useState(false);
  const [isReproposeModalOpen, setIsReproposeModalOpen] = useState(false);
  const [isCombinedQuoteModalOpen, setIsCombinedQuoteModalOpen] = useState(false);
  const [combinedQuoteToShow, setCombinedQuoteToShow] = useState({});
  const [customerId, setCustomerId] = useState(null);
  const [vehicleId, setVehicleId] = useState(null);

  const { dealershipId } = useParams();
  const { currentView, isQuickQuote, updateQueryString } = props;

  useEffect(() => {
    updateQueryString();
    props.appStore.quotingStore.setHasViewedOptions(false);
    if (isQuickQuote) {
      // We are tracking these pages specifically for the quick quote journeys
      // but the QuoteModule component is used in other journeys too,
      // so here we isolate the tracking to the quick quote journey only
      trackQuoteModuleViews(currentView, dealershipId);
    }

    return () => {
      props.appStore.quotingStore.setHasViewedOptions(false);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    updateQueryString();

    if (isQuickQuote) {
      // We are tracking these pages specifically for the quick quote journeys
      // but the QuoteModule component is used in other journeys too,
      // so here we isolate the tracking to the quick quote journey only
      trackQuoteModuleViews(currentView, dealershipId);
    }
  }, []);

  const fireFinanceQuoteApplySelectedEvent = (chosenQuote) => {
    const { returnedQuotes, vehicle, sortField, sortDirection } = props;
    const visibleQuotes = returnedQuotes.filter((x) => !x.Errors);
    const quotePosition = visibleQuotes.findIndex((x) => x.QuoteId === chosenQuote.QuoteId);

    trackFinanceQuoteApplySelected({
      dealershipId,
      financeFunderCode: chosenQuote.FunderCode,
      financeQuoteId: chosenQuote.QuoteId,
      financeQuoteMileage: chosenQuote.VehicleMileage,
      financeQuoteMonthlyPayment: chosenQuote.FollowingPayments,
      financeQuotePosition: quotePosition,
      financeQuoteProductId: chosenQuote.ProductId,
      financeQuoteProductType: chosenQuote.FinanceType,
      financeQuotesCount: visibleQuotes.length,
      financeQuotesSortDirection: sortDirection,
      financeQuotesSortField: sortField,
      financeQuoteTerm: chosenQuote.Term,
      financeQuoteTotalDeposit: chosenQuote.TotalDeposit,
      vehicleClass: vehicle.Class,
      vehicleCondition: vehicle.Condition,
      vehicleDerivative: vehicle.Derivative,
      vehicleMake: vehicle.Make,
      vehicleMileage: vehicle.Mileage,
      vehiclePrice: vehicle.Price,
      vehicleModel: vehicle.Model,
      vehicleVrm: vehicle.Vrm
    });
  };

  const handleProceedWithQuote = (quote, { customerId, vehicleId } = {}) => {
    setChosenQuote(quote);
    setIsReproposeModalOpen(props.warnIfLenderIs === quote.FunderCode);
    setCustomerId(customerId);
    setVehicleId(vehicleId);

    if (props.warnIfLenderIs !== quote.FunderCode) {
      props.changeToConsumerDutyView();
    }

    fireFinanceQuoteApplySelectedEvent(quote);
  };

  const handleReproposeToSameLenderModalAgree = () => {
    closeModal();
    props.changeToConsumerDutyView();
  };

  const handleSubmitFairProcessingModal = () => {
    const { quoteRequest } = props.quotes;
    props.onProceed({ quote: chosenQuote, customerId, vehicleId, quoteRequest });
  };

  const handleProductNameClick = (productId) => {
    let productForEditCommissionsFound = _.find(props.quotes.productSettings, {
      ProductUid: productId
    });

    setProductForEditCommissions(productForEditCommissionsFound);
    setIsEditQuoteModalOpen(true);
  };

  const handleProductCommissionsChange = (settings) => {
    props.changeComissions(settings);
    closeModal();
  };

  const handleSave = (chosenQuote) => {
    setChosenQuote(chosenQuote);
    props.onSave(chosenQuote);
  };

  const closeModal = () => {
    setIsEditQuoteModalOpen(false);
    setIsReproposeModalOpen(false);
    setIsCombinedQuoteModalOpen(false);
  };

  const showCombinedQuoteClick = (selectedQuote) => {
    setIsCombinedQuoteModalOpen(true);
    setCombinedQuoteToShow(selectedQuote);
  };

  const showFullBreakdown = (selectedQuote) => {
    closeModal();

    if (selectedQuote) {
      setCombinedQuoteToShow(selectedQuote);
    }

    props.setBreakDownView();
  };

  const showAlertCard = () => {
    const { t, vehicle } = props;

    if (vehicle.IsEdited) {
      return (
        <div className="QuoteModule__alertCard">
          <AlertCard iconName="validation-tick" title={t('QuoteModule.vehicle_information_saved')}>
            {t('QuoteModule.verify_the_vehicle_information_below')}
          </AlertCard>
        </div>
      );
    }

    return (
      <div className="QuoteModule__alertCard">
        <AlertCard iconName="information" title={t('QuoteModule.check_your_information')}>
          {t('QuoteModule.your_quote_will_be_based')}
        </AlertCard>
      </div>
    );
  };

  if (!props.vehicle) {
    return null;
  }

  if (!props.appStore.uiState.canGenerateQuotes) {
    return <QuotingNotAvailable />;
  }

  const numLoans = combinedQuoteToShow.PersonalLoan && combinedQuoteToShow.NegativeEquityLoan ? 3 : 2;

  return (
    <div data-th="QuoteModule">
      <IfFeatureEnabled feature="rtl-findavehicle-page">
        {props.currentView === 'COSTS_VIEW' && props.canEditVehicle && showAlertCard()}
      </IfFeatureEnabled>

      <ConsumerVehicleInfoPanel
        vehicle={props.vehicle}
        quote={props.quote}
        dealershipId={dealershipId}
        canEditVehicle={props.canEditVehicle && props.currentView === 'COSTS_VIEW'}
      />
      {props.currentView === 'COSTS_VIEW' && (
        <QuoteCosts
          defaultValues={props.defaultQuoteDetails}
          onSubmit={props.handleCostsChange}
          isLoading={props.quotes.isFetchingQuotes}
          vehicleClass={props.vehicle.Class}
          vehicleCondition={props.vehicle.Condition}
          onCancel={props.previousView ? props.handleBackToPreviousView : props.onCancel}
          checkingEligibilityState={props.checkingEligibilityState}
          hasCheckedEligibility={props.hasCheckedEligibility}
          isQuickQuote={props.isQuickQuote}
          dealershipId={dealershipId}
          customerId={props.customerId}
          customerType={props.customerType}
          vehicle={props.vehicle}
          quote={props.quote}
          tag={props.tag}
        />
      )}

      {props.currentView === 'CONSUMER_DUTY' && (
        <ConsumerDutyContainer
          dealershipId={dealershipId}
          chosenQuote={chosenQuote}
          customerType={props.customerType}
          onCancel={props.handleReturnToListViewClick}
          onContinue={handleSubmitFairProcessingModal}
          isRequote={!!props.preApprovalData}
        />
      )}

      {props.currentView === 'LIST_VIEW' && (
        <QuoteResults
          quotes={props.returnedQuotes}
          quoteRequest={props.quotes.quoteRequest}
          hasAdjustedBalloon={props.hasAdjustedBalloon}
          quotesForCompare={props.quotes.quotesForCompare}
          isLoading={props.quotes.isFetchingQuotes}
          onCompare={props.changeToMonthlyPaymentsView}
          onProceed={handleProceedWithQuote}
          onQuoteSelect={props.handleQuoteSelect}
          onChangeCosts={props.handleChangeCostsClick}
          onProductNameClick={handleProductNameClick}
          onCheckEligibilityClick={props.handleCheckEligibilityClick}
          onCancel={props.onCancel}
          enableShowroomCfc={props.enableShowroomCfc}
          hasCheckedEligibility={props.hasCheckedEligibility}
          craScorePercentage={props.craScorePercentage}
          personalScorePercentage={props.personalScorePercentage}
          proceedingState={props.proceedingState}
          chosenQuoteId={chosenQuote && chosenQuote.QuoteId}
          onSave={handleSave}
          onEligibilityMatchClick={props.onEligibilityMatchClick}
          onEligibilitySummaryClick={props.onEligibilitySummaryClick}
          isQuickQuote={props.isQuickQuote}
          isRequote={!!props.preApprovalData}
          isCustomerQuote={props.isCustomerQuote}
          enableSendDeal={props.enableSendDeal}
          onSortQuotes={props.onSortQuotes}
          sortField={props.sortField}
          sortDirection={props.sortDirection}
          onSendDeal={props.onSendDeal}
          vehicle={props.vehicle}
          customerType={props.customerType}
          showCombinedQuoteClick={showCombinedQuoteClick}
          proceedToBreakDown={showFullBreakdown}
          canCreateApplications={props.appStore.uiState.canCreateApplications}
          onProceedToFinanceNavigator={props.handleProceedToFinanceNavigator}
        />
      )}

      {(props.currentView === 'COMPARE_VIEW' || props.currentView === 'MONTHLY_PAYMENTS_VIEW') && (
        <CompareView
          title={
            props.currentView === 'MONTHLY_PAYMENTS_VIEW'
              ? props.t('QuoteModule.price_position')
              : props.t('QuoteModule.product_compare')
          }
          onReturnToListClick={props.handleReturnToListViewClick}
          onChangeCostsClick={props.handleChangeCostsClick}
          changeToMonthlyPaymentsView={props.changeToMonthlyPaymentsView}
          changeToCompareView={props.changeToCompareView}
          currentTabIndex={props.currentView === 'MONTHLY_PAYMENTS_VIEW' ? 0 : 1}
          fullQuoteDetails={props.fullQuoteDetails}
          monthlyPayments={props.monthlyPayments}
          isFetchingMonthlyPayments={props.isFetchingMonthlyPayments}
          hasFetchMonthlyPaymentsError={props.hasFetchMonthlyPaymentsError}
          quotes={props.quotes}
          vehicle={props.vehicle}
          handleProceedWithQuote={handleProceedWithQuote}
          proceedingState={props.proceedingState}
          applyingState={props.applyingState}
          savingState={props.savingState}
          handleRemoveQuoteFromCompare={props.handleRemoveQuoteFromCompare}
          customerEmail={props.customerEmail}
          onPrint={props.onPrint}
          assignees={props.assignees}
          chosenQuoteId={chosenQuote && chosenQuote.QuoteId}
          changeMonthlyPaymentTerm={props.changeMonthlyPaymentTerm}
          selectTerm={props.selectTerm}
          selectMonthlyPayment={props.selectMonthlyPayment}
          closeModal={props.closeChangeMonthlyPaymentsTermModal}
          isFetchingQuotes={props.quotes.isFetchingQuotes}
          onSaveQuote={handleSave}
          updateMonthlyPayments={props.updateMonthlyPayments}
          proceedToBreakDown={showFullBreakdown}
        />
      )}

      {props.currentView === 'CHECK_ELIGIBILITY_VIEW' && (
        <CheckEligibility
          vehicle={props.vehicle}
          eligibilityCheckDetails={props.eligibilityCheckDetails}
          isLoadingPreFill={props.isLoadingCfcPreFill}
          onSectionSubmit={props.handleEligibilityFormSectionSubmit}
          onSubmit={props.handleEligibilityFormSubmit}
          customerEmail={props.customerEmail}
          checkingEligibilityState={props.checkingEligibilityState}
          hasAddressError={props.quotes.hasAddressError}
          dealershipId={props.dealershipId}
        />
      )}

      {props.currentView === 'LOANS_BREAKDOWN_VIEW' && (
        <LoansBreakdownView
          quote={combinedQuoteToShow}
          proceedToApplication={handleProceedWithQuote}
          onCancel={props.handleReturnToListViewClick}
          loadingState={props.proceedingState}
          applicationCreationLoading={props.applicationCreationLoading}
        />
      )}

      {props.currentView === 'FINANCE_NAVIGATOR' && (
        <FinanceNavigatorContainer
          {...props.quotes.financeNavigator}
          onCancel={() => {
            props.handleCancelFinanceNavigator();
          }}
          onComplete={(scanId) => {
            props.handleSubmitFinanceNavigator(scanId);
          }}
        />
      )}

      {props.currentView === 'FINANCE_NAVIGATOR_RESULTS' && (
        <FinanceNavigatorResultsContainer
          {...props.quotes.financeNavigator}
          customerType={props.customerType}
          onCompare={props.changeToMonthlyPaymentsView}
          onChangeCosts={props.handleChangeCostsClick}
          onProceed={handleProceedWithQuote}
          onQuoteSelect={props.handleQuoteSelect}
          onProductNameClick={handleProductNameClick}
          onSortQuotes={props.onSortQuotes}
          onSave={handleSave}
          hasAdjustedBalloon={props.hasAdjustedBalloon}
          sortField={props.sortField}
          sortDirection={props.sortDirection}
          proceedToBreakdown={showFullBreakdown}
          chosenQuoteId={chosenQuote && chosenQuote.QuoteId}
          isQuickQuote={props.isQuickQuote}
          isCustomerQuote={props.isCustomerQuote}
          proceedingState={props.proceedingState}
        />
      )}

      <Modal isOpen={isReproposeModalOpen} onClose={closeModal}>
        <ReproposeToSameLenderModal
          onAgree={handleReproposeToSameLenderModalAgree}
          canEdit={props.isEditableOnReproposeJourney}
          onEdit={props.onEditExistingApplication}
          close={closeModal}
        />
      </Modal>

      <Modal isOpen={isEditQuoteModalOpen} onClose={closeModal}>
        <EditQuoteCommissionsForm
          defaultValues={productForEditCommissions}
          onSubmit={handleProductCommissionsChange}
          onCancel={closeModal}
          hasCheckedEligibility={props.hasCheckedEligibility}
        />
      </Modal>

      <Modal
        isOpen={isCombinedQuoteModalOpen}
        onClose={closeModal}
        title={<CombinedQuoteMessage numberOfIcons={numLoans} />}
      >
        <CombinedQuotesModal quote={combinedQuoteToShow} onProceed={showFullBreakdown} />
      </Modal>
    </div>
  );
};

QuoteModule.propTypes = {
  variant: PropTypes.string,
  quotes: PropTypes.object.isRequired,
  hasAdjustedBalloon: PropTypes.bool,
  returnedQuotes: PropTypes.array,
  vehicle: PropTypes.object.isRequired,
  initialCosts: PropTypes.object,
  fullQuoteDetails: PropTypes.object,
  defaultQuoteDetails: PropTypes.object,
  onProceed: PropTypes.func.isRequired,
  proceedingState: PropTypes.string,
  savingState: PropTypes.string,
  applyingState: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  restrictToProductType: PropTypes.string,
  restrictToFunderCode: PropTypes.string,
  warnIfLenderIs: PropTypes.string,
  onEditExistingApplication: PropTypes.func,
  isEditableOnReproposeJourney: PropTypes.bool,
  customerEmail: PropTypes.string,
  eligibilityCheckDetails: PropTypes.object.isRequired,
  isLoadingCfcPreFill: PropTypes.bool,
  checkingEligibilityState: PropTypes.string,
  hasCheckedEligibility: PropTypes.bool,
  currentView: PropTypes.string,
  previousView: PropTypes.string,
  enableShowroomCfc: PropTypes.bool,
  startQuoting: PropTypes.func.isRequired,
  continueQuoting: PropTypes.func.isRequired,
  updateQueryString: PropTypes.func.isRequired,
  changeComissions: PropTypes.func.isRequired,
  handleCostsChange: PropTypes.func.isRequired,
  handleCompareQuotesClick: PropTypes.func,
  handleQuoteSelect: PropTypes.func.isRequired,
  handleChangeCostsClick: PropTypes.func.isRequired,
  handleCheckEligibilityClick: PropTypes.func.isRequired,
  handleReturnToListViewClick: PropTypes.func.isRequired,
  handleRemoveQuoteFromCompare: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  handleEligibilityFormSectionSubmit: PropTypes.func.isRequired,
  handleEligibilityFormSubmit: PropTypes.func.isRequired,
  craScorePercentage: PropTypes.number,
  personalScorePercentage: PropTypes.number,
  handleBackToPreviousView: PropTypes.func,
  isContinuingFromQuickQuote: PropTypes.bool,
  assignees: PropTypes.array,
  onSave: PropTypes.func,
  onEligibilityMatchClick: PropTypes.func,
  onEligibilitySummaryClick: PropTypes.func,
  isQuickQuote: PropTypes.bool,
  isCustomerQuote: PropTypes.bool,
  onSortQuotes: PropTypes.func,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  enableSendDeal: PropTypes.bool,
  onSendDeal: PropTypes.func,
  monthlyPayments: PropTypes.array,
  isFetchingMonthlyPayments: PropTypes.bool,
  hasFetchMonthlyPaymentsError: PropTypes.bool,
  changeToMonthlyPaymentsView: PropTypes.func,
  changeToCompareView: PropTypes.func,
  changeToConsumerDutyView: PropTypes.func,
  changeMonthlyPaymentTerm: PropTypes.func,
  selectTerm: PropTypes.func,
  closeChangeMonthlyPaymentsTermModal: PropTypes.func,
  selectMonthlyPayment: PropTypes.func,
  updateMonthlyPayments: PropTypes.func,
  dealershipId: PropTypes.string,
  appStore: PropTypes.object,
  customerId: PropTypes.string,
  customerType: PropTypes.string,
  params: PropTypes.object,
  location: PropTypes.object,
  restrictQuotesByProduct: PropTypes.string,
  setBreakDownView: PropTypes.func,
  applicationCreationLoading: PropTypes.bool,
  preApprovalData: PropTypes.object,
  handleProceedToFinanceNavigator: PropTypes.func
};
export default compose(withParams, withTranslation('Quoting'), inject(['appStore']), observerForHooks)(QuoteModule);
