import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import DateFormat from '../../Common/DateFormat';
import DashboardVehicle from './DashboardVehicle';
import UpdatedIcon from '../../../styles/icons/updated_icon.svg';
import notesIcon from '../../../styles/icons/notes_tag_small.svg';
import DashboardLinkedUsers from './DashboardLinkedUsers';
import BusinessTag from '../../Common/BusinessTag';
import ConsumerName from '../../Common/ConsumerName';
import DashboardGroupLead from './DashboardGroupLead';
import classnames from 'classnames';
import './dashboardTableItem.scss';
import { setUrlAsCloudfront } from '../../../core/helpers';
import DashboardEligibilityMark from './DashboardEligibilityMark';

const DashboardTableItem = ({ data }) => {
  const { t } = useTranslation('Dashboard');
  const vehicle = data.Vehicle || {};
  const dealershipConsumer = data.DealershipConsumer || {};
  const hasCount = dealershipConsumer.AliasCount > 0 || (data.CorporateCustomer && data.CorporateCustomer.Id);
  const isCorpCustomer = data.CorporateCustomer && data.CorporateCustomer.Id;
  const originalDealershipId = data.OriginalDealership && data.OriginalDealership.Id;
  const tag = isCorpCustomer ? 'corporate' : '';
  const countClassNames = classnames(
    hasCount ? 'dashboardTableItem__topAlias' : 'dashboardTableItem__top',
    'dashboardTableItem__ellipsis'
  );
  const imageUrl = vehicle.ImageUrl && `${setUrlAsCloudfront(vehicle.ImageUrl)}?width=100&scale=both&format=webp`;

  return (
    <div className="dashboardTableItem">
      <ul className="dashboardTableItem__ul">
        <li className="dashboardTableItem__li dashboardTableItem__long">
          <div className="dashboardTableItem__vehicleImage">
            <DashboardVehicle vehicleImage={imageUrl} vehicle={vehicle} imageHeight="70px" imageWidth="100px" />
          </div>
          <div className="dashboardTableItem__content">
            <div className={countClassNames}>
              {data.CorporateCustomer && data.CorporateCustomer.Id ? (
                <span>{data.CorporateCustomer.TradingName}</span>
              ) : (
                <ConsumerName name={dealershipConsumer.FirstName + ' ' + dealershipConsumer.LastName} />
              )}
            </div>
            <DashboardLinkedUsers aliasCount={dealershipConsumer.AliasCount} />
            <div className="dashboardTableItem__vehicle">
              {vehicle.Make} {vehicle.Model} {vehicle.Derivative}
            </div>
            {!!originalDealershipId ? (
              <div className="panelItem__groupLead">
                <DashboardGroupLead leadName={data.OriginalDealership.Name} />
              </div>
            ) : (
              <div>
                <div className="panelItem__light" key="origin">
                  {t('DashboardTableItem.lead_created_in')} {data.CreatedSource}
                </div>
                <div className="panelItem__light" key="client">
                  {t('DashboardTableItem.updated_in')} {data.UpdatedSource}
                </div>
              </div>
            )}
            <BusinessTag userType={tag} />
            {dealershipConsumer && dealershipConsumer.NoteCount > 0 && (
              <div className="dashboardTableItem__notesContainer">
                <img src={notesIcon} />
              </div>
            )}
            {isCorpCustomer && data.CorporateCustomer.NoteCount > 0 && (
              <div className="dashboardTableItem__notesContainer">
                <img src={notesIcon} />
              </div>
            )}
          </div>
        </li>
        <li className="dashboardTableItem__li dashboardTableItem__short">
          <div className="dashboardTableItem__top">{data.CreatedSource}</div>
          <div>
            <div className="dashboardTableItem__updateOuter">
              <img src={UpdatedIcon} />
            </div>
            {data.UpdatedSource}
          </div>
        </li>
        <li className="dashboardTableItem__li dashboardTableItem__short">
          {data.CfcProspect.MatchRate ? (
            <DashboardEligibilityMark
              matchRate={data.CfcProspect.MatchRate}
              source={data.CfcProspect.SourceType === 'FinanceScan' ? 'finance-nav' : 'cfc'}
              showDotIndicator={false}
              variant="column"
            />
          ) : (
            <div className="dashboardTableItem__noCfc">{t('DashboardTableItem.no_cfc')}</div>
          )}
        </li>
        <li className="dashboardTableItem__li dashboardTableItem__short dashboardTableItem__last">
          <div className="dashboardTableItem__top">
            <DateFormat value={data.Created} format="calendar" parse="utc" />
          </div>
          <div>
            <div className="dashboardTableItem__updateOuter">
              <img src={UpdatedIcon} />
            </div>
            {data.LastModified === '2015-10-23T09:21:31.3724198Z' ? (
              <div className="dashboardTableItem__noUpdates">{t('DashboardTableItem.no_updates')}</div>
            ) : (
              <DateFormat value={data.LastModified} format="timeAgo" parse="utc" />
            )}
          </div>
        </li>
      </ul>
    </div>
  );
};

DashboardTableItem.propTypes = {
  data: PropTypes.object
};
export default DashboardTableItem;
