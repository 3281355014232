import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import { inject } from 'mobx-react';
import LargeMenuButton from '../../Application/components/LargeMenuButton';
import LargeButtonLayout from '../../ApplicationStatus/Layout/LargeButtonLayout';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import observerForHooks from 'hocs/observerForHooks';

const Settings = ({ t, ...props }) => {
  const params = useParams();
  const { uiState } = props.appStore;
  const { canBroadcast: broadcastEnabled, canUseIDD, hasDefaultTheme, isDealershipAdminExclusively } = uiState;
  const canOnboardLenders = hasDefaultTheme && isDealershipAdminExclusively;

  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('Settings.home'),
            path: `/d/${params.dealershipId}`
          },
          {
            name: t('Settings.settings'),
            path: `/d/${params.dealershipId}/settings`
          }
        ]}
      />

      <Panel>
        <PanelHeader>{t('Settings.settings')}</PanelHeader>
        <PanelContent>
          <LargeButtonLayout>
            {props.appStore.uiState.canViewGlobalSettings && (
              <LargeMenuButton
                icon="global-settings"
                link={`/d/${params.dealershipId}/settings/dealership`}
                text={t('Settings.global_defaults')}
                dataThook="global_default"
                buttonText={t('Settings.edit')}
              />
            )}
            {props.appStore.uiState.canViewFinanceProducts && (
              <LargeMenuButton
                icon="product-settings"
                link={`/d/${params.dealershipId}/settings/product`}
                text={t('Settings.finance_products')}
                dataThook="finance_products"
                buttonText={t('Settings.edit')}
              />
            )}
            {props.appStore.uiState.canEditVaps && (
              <LargeMenuButton
                icon="vaps"
                link={`/d/${params.dealershipId}/settings/vaps`}
                text={t('Settings.value_added_products')}
                dataThook="value_added_products"
                buttonText={t('Settings.edit')}
              />
            )}
            <LargeMenuButton
              icon="account-settings"
              link={`/d/${params.dealershipId}/settings/account`}
              text={t('Settings.my_account')}
              dataThook="my_account"
              buttonText={t('Settings.edit')}
            />
            {props.appStore.uiState.canManageUsers && (
              <LargeMenuButton
                icon="dealership-user"
                link={`/d/${params.dealershipId}/settings/users`}
                text={t('Settings.manage_users')}
                dataThook="manage_users"
                buttonText={t('Settings.edit')}
              />
            )}
            {props.appStore.uiState.canSetRepExample && (
              <LargeMenuButton
                icon="quick-quote"
                link={`/d/${params.dealershipId}/settings/repexample`}
                text={t('Settings.rep_example')}
                dataThook="rep_example"
                buttonText={t('Settings.edit')}
              />
            )}
            {props.appStore.uiState.isDealershipAdmin && props.appStore.uiState.canViewReservations && (
              <LargeMenuButton
                icon="payment_icon"
                link={`/d/${params.dealershipId}/settings/reserve`}
                text={t('Settings.vehicle_reservation')}
                dataThook="vehicle_reservation"
                buttonText={t('Settings.edit')}
              />
            )}
            {(props.session.IsSysAdmin || props.appStore.uiState.isDealershipAdmin) && canUseIDD && (
              <LargeMenuButton
                icon="application"
                link={`/d/${params.dealershipId}/settings/idd`}
                text={t('Settings.initial_disclosure_document')}
                dataThook="idd"
                buttonText={t('Settings.edit')}
              />
            )}
            {broadcastEnabled && (
              <LargeMenuButton
                icon="broadcast"
                link={`/d/${params.dealershipId}/settings/broadcast`}
                text={t('Settings.broadcast')}
                dataThook="broadcast"
                buttonText={t('Settings.edit')}
              />
            )}
            {props.appStore.uiState.canUseStockSettings && (
              <LargeMenuButton
                icon="stock-settings"
                link={`/d/${props.params.dealershipId}/settings/stocksettings`}
                text={t('StockSettings.stock_settings')}
                buttonText={t('Settings.edit')}
              />
            )}
            {canOnboardLenders && (
              <LargeMenuButton
                icon="handshake"
                link={`/d/${props.params.dealershipId}/settings/lenderonboarding`}
                text="Lender Onboarding"
                buttonText={t('Settings.edit')}
              />
            )}
          </LargeButtonLayout>
        </PanelContent>
      </Panel>
    </Page>
  );
};

Settings.propTypes = {
  session: PropTypes.object.isRequired,
  params: PropTypes.object,
  appStore: PropTypes.object
};

export default compose(withTranslation('Settings'), inject(['appStore']), observerForHooks)(Settings);
