import { z } from 'zod';
import { EligibilityOffered, StandardLoanDataType } from '~/components/QuoteCard/types';

export enum FinanceNavigatorScanStatus {
  NotSubmitted = 'NotSubmitted',
  Submitted = 'Submitted',
  PartiallyComplete = 'PartiallyComplete',
  Complete = 'Complete'
}

export const FinanceNavigatorResultSchema = z.object({
  IsSuccess: z.boolean(),
  Message: z.string(),
  OriginalQuoteId: z.string(),
  PersonalisedQuote: z.custom<StandardLoanDataType>(),
  OriginalValuesNowDifferent: z.record(z.string(), z.number()),
  Data: z
    .object({
      ExternalReference: z.string().optional(),
      IvendiAgreementNumber: z.string(),
      PersonalisedQuoteId: z.string().optional(),
      Eligibility: z.nativeEnum(EligibilityOffered),
      ExpiryDate: z.string().optional(),
      MaxMonthlyBudget: z.number().optional(),
      MaxAdvance: z.number().optional()
    })
    .optional()
});

export const FinanceNavigatorResultMessageSchema = z.object({
  Id: z.string(),
  DealershipId: z.string(),
  ConsumerId: z.string().optional(),
  VehicleId: z.string().optional(),
  Status: z.nativeEnum(FinanceNavigatorScanStatus),
  Results: z.array(FinanceNavigatorResultSchema).optional()
});

export type FinanceNavigatorResultMessage = z.infer<typeof FinanceNavigatorResultMessageSchema>;

// Isn't this the same as FinanceNavigatorEligibilityDataType?
export type FinanceNavigatorResult = z.infer<typeof FinanceNavigatorResultSchema>;
