import { useQuery } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getLenderPrivacyDocs, getPrivacyPolicyDocs } from '.';

export const SERVICE_PREFIX = 'legaldocs';

export const legaldocsQueries = createQueryKeys(SERVICE_PREFIX, {
  detail: (funderCode: Parameters<typeof getLenderPrivacyDocs>['0'] | undefined) => {
    return {
      queryKey: [funderCode ?? ''],
      queryFn: () => {
        if (funderCode) {
          return getLenderPrivacyDocs(funderCode);
        }
      }
    };
  },
  privacyPolicy: (countryCode: Parameters<typeof getPrivacyPolicyDocs>['0'] | undefined | null) => {
    return {
      queryKey: [countryCode ?? 'gb'],
      queryFn: () => {
        if (countryCode) {
          return getPrivacyPolicyDocs(countryCode);
        }
      }
    };
  }
});

// Need to look into mutation factory patterns
export const useGetLenderPrivacyDocs = (funderCode: Parameters<typeof getLenderPrivacyDocs>['0'] | undefined) => {
  return useQuery(legaldocsQueries.detail(funderCode));
};

export const useGetPrivacyPolicyDocs = (
  countryCode: Parameters<typeof getPrivacyPolicyDocs>['0'] | undefined | null
) => {
  return useQuery(legaldocsQueries.privacyPolicy(countryCode));
};
