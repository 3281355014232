import { useQuery, useMutation } from '@tanstack/react-query';
import { getBankDetails, postBankDetails } from './index';

export const useGetBankDetails = (dealershipId: string | undefined) => {
  return useQuery(['bankDetails', dealershipId], () => getBankDetails(dealershipId));
};

export const usePostBankDetails = (dealershipId: string | undefined) => {
  return useMutation({
    mutationFn: (data: any) => {
      return postBankDetails(dealershipId, data);
    }
  });
};
