import { lowercaseFirstLetter } from '~/components/Common/utils';
import {
  DisplayQuoteFormat,
  EligibilityOffered,
  FinanceNavigatorEligibilityDataType,
  FormattedQuoteKey,
  PaymentDetails
} from '~/components/QuoteCard/types';
import { EligibilityCategories, EligibilityCategoriesType, FNEligibilityModalContentType } from './types';

export const calculateValuesNowDifferent = (quote: DisplayQuoteFormat, originalValues: Record<string, number>) => {
  const formattedOriginalFigures: Partial<Record<FormattedQuoteKey, number | null>> = {
    StandardPayments: originalValues?.FollowingPayments || null,
    Apr: originalValues?.RepresentativeApr || null,
    AgreementTerm: originalValues?.Term || null,
    TotalAmountPayable: originalValues?.TotalAmountPayable || null
  };
  return (Object.keys(formattedOriginalFigures) as FormattedQuoteKey[]).reduce((curr, next) => {
    const original = formattedOriginalFigures[next];
    if (!original) return curr;
    const current = next === 'StandardPayments' ? (quote[next].value as PaymentDetails).amount : quote[next].value;
    return {
      ...curr,
      [next]: {
        original,
        current,
        hasChanged: original !== null && original !== current
      }
    };
  }, {});
};

export const getFNEligibilityModalContent = (
  quote: DisplayQuoteFormat,
  eligibility: FinanceNavigatorEligibilityDataType
): FNEligibilityModalContentType => {
  const { Data, OriginalValuesNowDifferent, Message } = eligibility;
  const formattedEligibilityStatus = Data?.Eligibility?.length && lowercaseFirstLetter(Data?.Eligibility);
  return {
    title: `${formattedEligibilityStatus}_eligibility`,
    header: `${formattedEligibilityStatus}_explained`,
    indicativeText: 'indicative_eligibility',
    lenderData: Data,
    message: Message,
    valuesNowDifferent: calculateValuesNowDifferent(quote, OriginalValuesNowDifferent)
  };
};

export const getEligibilityCategoryFromFnStatus = (status: EligibilityOffered): EligibilityCategoriesType | null => {
  switch (status) {
    case EligibilityOffered.PreApproved:
    case EligibilityOffered.VeryHigh:
    case EligibilityOffered.High:
      return EligibilityCategories.high;
    case EligibilityOffered.Medium:
      return EligibilityCategories.medium;
    case EligibilityOffered.Low:
    case EligibilityOffered.Unknown:
    case EligibilityOffered.UnableToAssist:
      return EligibilityCategories.low;
    default:
      return null;
  }
};
