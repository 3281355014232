import { types } from '../schema';

export default {
  Applications: {
    Data: [
      {
        Application: {
          Id: types.String,
          LastUpdatedUtc: types.String,
          Lender: types.String,
          Source: {
            ClientApp: types.String,
            Origin: types.String
          },
          Status: types.String
        },
        AssignedTo: [
          {
            Firstname: types.String,
            Id: types.String,
            Surname: types.String,
            Avatar: {
              Colour: types.Number
            }
          }
        ],
        CfcProspect: {
          ClientApp: types.String,
          Count: types.Number,
          MatchRate: types.String,
          Source: types.String,
          LastUpdatedUtc: types.String,
          SourceType: types.String
        },
        CorporateCustomer: {
          Id: types.String,
          NoteCount: types.Number,
          TradingName: types.String
        },
        Created: types.String,
        CreatedSource: types.String,
        DealershipConsumer: {
          AliasCount: types.Number,
          FirstName: types.String,
          Id: types.String,
          LastName: types.String,
          MiddleNames: types.String,
          NoteCount: types.Number
        },
        LastModified: types.String,
        OriginalDealership: {
          Id: types.String,
          Name: types.String
        },
        UpdatedSource: types.String,
        Source: {
          ClientApp: types.String,
          Origin: types.String
        },
        Vehicle: {
          Class: types.String,
          Derivative: types.String,
          Id: types.String,
          ImageUrl: types.String,
          Make: types.String,
          Model: types.String,
          VehicleCount: types.String,
          Vrm: types.String
        }
      }
    ],
    DealershipId: types.String,
    Facets: {
      Filtered: [
        {
          Items: [
            {
              Count: types.Number,
              Name: types.String
            }
          ],
          Name: types.String
        }
      ],
      Unfiltered: [
        {
          Items: [
            {
              Count: types.Number,
              Name: types.String
            }
          ],
          Name: types.String
        }
      ]
    },
    Page: types.Number,
    PageSize: types.Number,
    SortDirection: types.String,
    SortField: types.String,
    Total: types.Number
  },
  AssignmentTile: {
    Views: [
      {
        Name: types.String,
        Value: types.String
      }
    ]
  },
  CfcProspects: {
    Data: [
      {
        Application: {
          Id: types.String,
          LastUpdatedUtc: types.String,
          Lender: types.String,
          Source: {
            ClientApp: types.String,
            Origin: types.String
          },
          Status: types.String
        },
        AssignedTo: [
          {
            Firstname: types.String,
            Id: types.String,
            Surname: types.String,
            Avatar: {
              Colour: types.Number
            }
          }
        ],
        CfcProspect: {
          ClientApp: types.String,
          Count: types.Number,
          MatchRate: types.String,
          Source: types.String,
          LastUpdatedUtc: types.String,
          SourceType: types.String
        },
        CorporateCustomer: {
          Id: types.String,
          NoteCount: types.Number,
          TradingName: types.String
        },
        Created: types.String,
        CreatedSource: types.String,
        DealershipConsumer: {
          AliasCount: types.Number,
          FirstName: types.String,
          Id: types.String,
          LastName: types.String,
          MiddleNames: types.String,
          NoteCount: types.Number
        },
        LastModified: types.String,
        OriginalDealership: {
          Id: types.String,
          Name: types.String
        },
        Source: {
          ClientApp: types.String,
          Origin: types.String
        },
        UpdatedSource: types.String,
        Vehicle: {
          Class: types.String,
          Derivative: types.String,
          Id: types.String,
          ImageUrl: types.String,
          Make: types.String,
          Model: types.String,
          VehicleCount: types.String,
          Vrm: types.String,
          NoteCount: types.Number
        }
      }
    ],
    DealershipId: types.String,
    Facets: {
      Filtered: [
        {
          Items: [
            {
              Count: types.Number,
              Name: types.String
            }
          ],
          Name: types.String
        }
      ],
      Unfiltered: [
        {
          Items: [
            {
              Count: types.Number,
              Name: types.String
            }
          ],
          Name: types.String
        }
      ]
    },
    Page: types.Number,
    PageSize: types.Number,
    SortDirection: types.String,
    SortField: types.String,
    Total: types.Number
  },
  LenderUpdates: {
    Data: [
      {
        Application: {
          Id: types.String,
          LastUpdatedUtc: types.String,
          Lender: types.String,
          Source: {
            ClientApp: types.String,
            Origin: types.String
          },
          Status: types.String
        },
        AssignedTo: [
          {
            Firstname: types.String,
            Id: types.String,
            Surname: types.String,
            Avatar: {
              Colour: types.Number
            }
          }
        ],
        CfcProspect: {
          ClientApp: types.String,
          Count: types.Number,
          MatchRate: types.String,
          Source: types.String
        },
        CorporateCustomer: {
          Id: types.String,
          NoteCount: types.Number,
          TradingName: types.String
        },
        Created: types.String,
        DealershipConsumer: {
          AliasCount: types.Number,
          FirstName: types.String,
          Id: types.String,
          LastName: types.String,
          MiddleNames: types.String,
          NoteCount: types.Number
        },
        LastModified: types.String,
        Source: {
          ClientApp: types.String,
          Origin: types.String
        },
        Vehicle: {
          Class: types.String,
          Derivative: types.String,
          Id: types.String,
          ImageUrl: types.String,
          Make: types.String,
          Model: types.String,
          VehicleCount: types.String,
          Vrm: types.String,
          NoteCount: types.Number
        }
      }
    ],
    DealershipId: types.String,
    Facets: {
      Filtered: [
        {
          Items: [
            {
              Count: types.Number,
              Name: types.String
            }
          ],
          Name: types.String
        }
      ],
      Unfiltered: [
        {
          Items: [
            {
              Count: types.Number,
              Name: types.String
            }
          ],
          Name: types.String
        }
      ]
    },
    Page: types.Number,
    PageSize: types.Number,
    SortDirection: types.String,
    SortField: types.String,
    Total: types.Number
  },
  Tiles: [
    {
      Count: types.Number,
      Time: types.String,
      Type: types.String
    }
  ]
};
