import { ApplicationVehicle, CustomerVehicleOfInterest } from '~/types/vehicle';
import { CustomerQuoteDefaultValues } from '~/components/CustomerQuote/components/CustomerQuote';
import {
  ApplicationPreApprovalData,
  ApplicationQuote,
  ApplicationQuoteRequest,
  CustomerType
} from '~/types/application';
import { QuoteTypes } from '~/components/QuoteCard/types';
import { QuoteRequest } from '~/core/quoteRequest';
import { QuoteModuleVariantType } from '~/components/Quoting/components/QuoteModule/types';
import { FixLater } from '~/types/basic';
import { QuoteModuleBaseProps } from './QuoteModuleContainer';

export type QuickQuoteResultsDefaultValues = {
  CashDeposit: string;
  Term: number;
  VehiclePrice: number;
  AnnualDistance: number;
  ValueAddedProducts: FixLater;
};
export type QuickQuoteOnProceed = {
  quote: QuoteTypes;
  quoteRequest: typeof QuoteRequest; // TODO: Not sure if this is correct - maybe we need a new type for this
  customerId: string;
  vehicleId: string;
};
/*
 * The common props that can be enough for all the variants are:
 * {
 *   variant: QuoteModuleVariantType;
 *   vehicle: CustomerVehicleOfInterest | ApplicationVehicle | QuickQuoteVehicle;
 *   initialCosts: CustomerQuoteDefaultValues | ApplicationQuoteRequest | QuickQuoteResultsDefaultValues;
 *   customer: CustomerEntityStateType;
 *   application?: Application; // Should container preApprovalData and NewAPR
 * }
 * The actions can be defined within the QuoteModuleContainer based on the variant
 * and just access the respective actions directly from Redux; Same goes for the loading states and
 * enableShowroomCfc and enableSendDeal;
 */
export type QuoteModuleQuickQuoteProps = QuoteModuleBaseProps & {
  variant: QuoteModuleVariantType.QuickQuote;
  vehicle: FixLater; // TODO: Get vehicle for quick quote journey
  initialCosts: QuickQuoteResultsDefaultValues;
  onCancel: () => void; // history - 1
  onProceed: (args: QuickQuoteOnProceed) => void; // createApplication | changeQuote if customer doesn't exist
  onSave: () => void; // quickQuoteActions.createQuickQuoteApplication
  onSendDeal: () => void; // sendDeal > openModal from modalActions
  customerType: CustomerType;
  enableShowroomCfc: boolean; // canUseCfcIvendiv2 && consumer.CustomerType === 'consumer'
  enableSendDeal: boolean; // canUseSendDeal && state.quotes
  isQuickQuote: boolean; // true
};

export type QuoteModuleCustomerQuoteProps = QuoteModuleBaseProps & {
  variant: QuoteModuleVariantType.CustomerQuote;
  vehicle: CustomerVehicleOfInterest;
  initialCosts: CustomerQuoteDefaultValues;
  onCancel: () => void; // Navigates back to the customer page
  onProceed: () => void; // Creates Combined (BNP) applications | Creates a normal application
  onSave: () => void; // consumerActions.saveCustomerQuote
  onSendDeal: () => void; // sendDeal > openModal from modalActions
  customerType: CustomerType;
  customerId: string;
  enableShowroomCfc: boolean; // canUseCfcIvendiv2 && consumer.CustomerType === 'consumer'
  enableSendDeal: boolean; // canUseSendDeal
  isCustomerQuote: boolean; // true
  customerEmail: string; // Email of the customer - comes from the CustomerEntityStateType.Email
  assignees: []; // CustomerEntityStateType.assignedTo | []
  applicationCreationLoading: boolean; // isLoading while creating an application
};

export type ApplicationRequoteQuoteRequest = ApplicationQuoteRequest & {
  CustomerType: CustomerType;
  isRequote: boolean;
};
export type QuoteModuleApplicationRequoteProps = QuoteModuleBaseProps & {
  variant: QuoteModuleVariantType.ApplicationRequote;
  vehicle: ApplicationVehicle;
  initialCosts: ApplicationRequoteQuoteRequest;
  onCancel: () => void; // Navigate to application status page
  onProceed: (args: { quote: QuoteTypes; quoteRequest: ApplicationRequoteQuoteRequest }) => void; // changeApplicationQuotePreSubmission
  onEditExistingApplication: () => void; // Navigate to application edit page
  customerType: CustomerType;
  customerId: string;
  proceedingState: string; // application.applicationCloneState
  currentProductUid: string; // application.Quote.ProductId
  newAPR?: string; // preApprovalData?.NewAPR - Do we need to pass it separately?
  preApprovalData: ApplicationPreApprovalData;
};

export type QuoteModuleApplicationReproposeProps = QuoteModuleBaseProps & {
  variant: QuoteModuleVariantType.ApplicationRepropose;
  vehicle: ApplicationVehicle; // application.Vehicle
  initialCosts: ApplicationQuoteRequest; // application.QuoteRequest
  onCancel: () => void; // Navigate to application status page
  onProceed: (args: { quote: QuoteTypes }) => void; // applicationActions.cloneApplication
  onEditExistingApplication: () => void; // Navigate to application edit page
  customerType: CustomerType;
  customerId: string;
  proceedingState: string; // applicationCloneState
  warnIfLenderIs: string; // application.Quote.FunderCode
  isEditableOnReproposeJourney: boolean; // application.isEditable
};

export type QuoteModuleApplicationQuotePreSubmissionProps = QuoteModuleBaseProps & {
  variant: QuoteModuleVariantType.ApplicationQuotePreSubmission;
  vehicle: ApplicationVehicle; // application.Vehicle
  initialCosts: ApplicationQuoteRequest; // application.QuoteRequest
  onCancel: () => void; // Navigate to customer
  onProceed: (args: { quote: QuoteTypes; quoteRequest: ApplicationQuoteRequest }) => void; // applicationActions.changeApplicationQuotePreSubmission
  customerType: CustomerType;
  customerId: string;
  enableShowroomCfc: boolean; // canUseShowroomCfc && CustomerType.Consumer
  proceedingState: string; // application.submittingState
  customerEmail: string; // consumer.Email
  assignees: []; // CustomerEntityStateType.assignedTo | []
};

export type QuoteModuleEditApplicationQuoteProps = QuoteModuleBaseProps & {
  variant: QuoteModuleVariantType.EditApplicationQuote;
  vehicle: ApplicationVehicle; // application.Vehicle
  initialCosts: ApplicationQuoteRequest; // application.QuoteRequest
  onCancel: () => void; // Navigate to application edit page
  onProceed: (args: { quote: QuoteTypes; quoteRequest: ApplicationQuoteRequest }) => void; // applicationActions.changeApplicationQuote
  customerType: CustomerType;
  customerId: string; // - This is already in the params
  proceedingState: string; // application.submittingState
  restrictToProductType: string | null; // application.Quote.FunderCode === 'MOT' ? application.Quote.FinanceType : null;
  restrictQuotesByProduct: string; // If it's one of 3 products starting with "Rtl - ..."
  restrictToFunderCode: string; // application.Quote.FunderCode
  params: FixLater; // props.params - dealershipId, consumerId, applicationId
  assignees: []; // CustomerEntityStateType.assignedTo | []
  quote: ApplicationQuote; // application.Quote
  tag?: string; // application.Quote.Tag - BNP specific
};

export type QuoteModuleContainerProps =
  | QuoteModuleQuickQuoteProps
  | QuoteModuleCustomerQuoteProps
  | QuoteModuleApplicationRequoteProps
  | QuoteModuleApplicationReproposeProps
  | QuoteModuleApplicationQuotePreSubmissionProps
  | QuoteModuleEditApplicationQuoteProps;

export const getPropsTypeByVariant = (props: QuoteModuleContainerProps) => {
  const { variant } = props;

  if (variant === QuoteModuleVariantType.QuickQuote) {
    return props as QuoteModuleQuickQuoteProps;
  }
  if (variant === QuoteModuleVariantType.CustomerQuote) {
    return props as QuoteModuleCustomerQuoteProps;
  }
  if (variant === QuoteModuleVariantType.ApplicationRequote) {
    return props as QuoteModuleApplicationRequoteProps;
  }
  if (variant === QuoteModuleVariantType.ApplicationRepropose) {
    return props as QuoteModuleApplicationReproposeProps;
  }
  if (variant === QuoteModuleVariantType.ApplicationQuotePreSubmission) {
    return props as QuoteModuleApplicationQuotePreSubmissionProps;
  }
  if (variant === QuoteModuleVariantType.EditApplicationQuote) {
    return props as QuoteModuleEditApplicationQuoteProps;
  }
};
