import produce from 'immer';
import defaultConfig, { QuoteCardConfig, QuoteCardConfigProps } from '../default';
import { isFinanceNavigatorEligibilityQuote } from '../../utils/quoteFeatures';
import moment from 'moment';
import { EligibilityOffered } from '../../types';

export default ({ quote, application }: QuoteCardConfigProps) => {
  return produce(defaultConfig, (draft: QuoteCardConfig) => {
    if (isFinanceNavigatorEligibilityQuote(quote)) {
      const expiryDate = moment(quote.Eligibility.Data?.ExpiryDate);
      draft.showExpiryDate = expiryDate.isValid() && expiryDate.isAfter();
      draft.showExpired = expiryDate.isValid() && expiryDate.isBefore();
      draft.title =
        quote.Eligibility.Data?.Eligibility === EligibilityOffered.PreApproved
          ? 'QuoteCardTable.preapproved_quote'
          : defaultConfig.title;
    }
  });
};
