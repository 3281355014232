import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import Breadcrumbs from '../../Common/Breadcrumbs';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import Input from '../../Common/Form/Input';
import './lenderOnboarding.scss';
import ToggleButtonWithIcon from '~/components/Application/components/ToggleButtonWithIcon';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useRhfValidationResolver } from '~/components/FinanceNavigator/forms/validation';
import { FieldGroupRHF } from '~/components/Common/Form/FieldGroupRHF';
import InformationWarning from '~/components/Common/InformationWarning';
import FormFooter from '~/components/Common/Form/FormFooter';
import { useNavigate } from '~/hooks/useNavigate';
import LoadingDots from '~/components/Common/Loading/LoadingDots';
import { useGetBankDetails, usePostBankDetails } from '~/api/LenderOnboarding/hooks';
import { useQueryClient } from '@tanstack/react-query';

export type BankDetails = {
  AccountNumber: string | number;
  SortCode: string | number;
  LenderCode?: string;
};

const LenderOnboarding = () => {
  const { t } = useTranslation('Settings');
  const navigate = useNavigate();
  const { dealershipId } = useParams<{ dealershipId: string }>();
  const queryClient = useQueryClient();

  const [hideDetails, setHideDetails] = useState(false);
  const [bankDetails, setBankDetails] = useState<BankDetails | undefined>();

  const resolver = useRhfValidationResolver({
    AccountNumber: 'required, number:int, minlength:7, maxlength:8',
    SortCode: 'required, number:int, minlength:6, maxlength:6'
  });

  const methods = useForm<BankDetails>({
    mode: 'all',
    resolver
  });

  const { control, handleSubmit, formState } = methods;
  const { data: bankData, isFetching } = useGetBankDetails(dealershipId);
  const { mutate, isSuccess } = usePostBankDetails(dealershipId);

  useEffect(() => {
    if (isSuccess) {
      setHideDetails(true);
      queryClient.invalidateQueries(['bankDetails', dealershipId]);
    }
  }, [isSuccess, queryClient, dealershipId]);

  useEffect(() => {
    const DealershipBankDetails = bankData?.DealershipBankDetails[0];
    if (DealershipBankDetails) {
      setBankDetails(DealershipBankDetails);
    }
  }, [bankData]);

  const onSubmit = async (data: BankDetails) => {
    // Hard coded "ALL" for now to submit to all Lenders
    data.LenderCode = 'ALL';
    mutate(data);
  };

  const handleBack = () => {
    navigate(`/d/${dealershipId}/settings`);
  };

  return (
    <Page>
      {isFetching ? (
        <LoadingDots />
      ) : (
        <>
          <div className="stockSettings__breadcrumbs">
            <Breadcrumbs
              items={[
                { name: t('StockSettings.home'), path: `/d/${dealershipId}` },
                { name: t('StockSettings.settings'), path: `/d/${dealershipId}/settings` },
                { name: 'Lender Onboarding', path: `/d/${dealershipId}/settings/lenderOnboarding` }
              ]}
            />
          </div>

          <div>
            <Panel>
              <PanelHeader>{t('LenderOnboarding.lender_onboarding')}</PanelHeader>
              <PanelContent>
                <div className="lender__onboarding__description">
                  {t('LenderOnboarding.to_speed_up_your_onboarding')}
                </div>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <section className="lender__onboarding">
                      <div className="lender__onboarding__BankWarning">
                        <InformationWarning>
                          {t('LenderOnboarding.please_ensure_that_the_bank_details')}
                        </InformationWarning>
                      </div>

                      {/* Account Number Input */}
                      <Controller
                        name="AccountNumber"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FieldGroupRHF label={t('LenderOnboarding.account_number')} name={field.name}>
                            <Input
                              {...field}
                              id={field.name}
                              type={bankDetails ? 'text' : hideDetails ? 'password' : 'number'}
                              isInvalid={fieldState.invalid}
                              disabled={hideDetails || bankDetails}
                              value={bankDetails ? bankDetails?.AccountNumber : field.value}
                            />
                          </FieldGroupRHF>
                        )}
                      />

                      {/* Sort Code Input */}
                      <Controller
                        name="SortCode"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FieldGroupRHF label={t('LenderOnboarding.sort_code')} name={field.name}>
                            <Input
                              {...field}
                              id={field.name}
                              type={bankDetails ? 'text' : hideDetails ? 'password' : 'number'}
                              isInvalid={fieldState.invalid}
                              disabled={hideDetails || bankDetails}
                              value={bankDetails ? bankDetails?.SortCode : field.value}
                            />
                          </FieldGroupRHF>
                        )}
                      />
                    </section>

                    {/* Show/Hide Bank Details */}
                    {!bankDetails && !isSuccess && (
                      <div className="lender__onboarding__ShowHide">
                        <ToggleButtonWithIcon
                          onClick={() => setHideDetails(!hideDetails)}
                          hideDetails={hideDetails}
                          icon="eye"
                          text={t('LenderOnboarding.bank_details')}
                        />
                      </div>
                    )}
                    <FormFooter
                      submitLabel={t('LenderOnboarding.save')}
                      submitButtonTag={'button'}
                      submitDisabled={!formState.isValid}
                      hideSubmit={bankDetails || isSuccess}
                      submittingState={formState.isSubmitting}
                      cancelLabel={t('LenderOnboarding.back')}
                      onCancel={() => handleBack()}
                      hasError={formState.errors}
                    />
                  </form>
                </FormProvider>
              </PanelContent>
            </Panel>
          </div>
        </>
      )}
    </Page>
  );
};

export default LenderOnboarding;
