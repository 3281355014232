import { withTranslation } from 'react-i18next';
import { Component } from 'react';
import PropTypes from 'prop-types';
import './vehicleDetailsButtonContainer.scss';
import Button from '../../../Common/Button/Button';
import CommentsButton from '../../../CloseDeal/Comments/CommentsButton';
import { inject, observer } from 'mobx-react';
import TrackEvent from '../../../Common/Tracking/TrackEvent';
import { compose } from 'redux';
import { withNavigate, withParams } from 'hocs/router';
import { canUseSendDealViaQuoteResultsPage } from '~/features';

class VehicleDetailsButtonContainer extends Component {
  onSendDeal = () => {
    this.props.appStore.pushDealStore.setVehicle(this.props.vehicle, this.props.vehicle.LatestPrice);
    window.ga && window.ga('send', 'event', 'PushDealCustomerRecord', 'ButtonPress', `PushDealCustomerRecord__link`);
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/send-deal`,
      query: {
        consumerId: this.props.params.consumerId,
        entry: 'with-vehicle'
      }
    });
  };

  render() {
    const { CustomerType } = this.props.consumer;
    const { uiState } = this.props.appStore;

    const getStatusUrl = (status, canUseFinancing) => {
      if (status) return status.toLowerCase();
      if (canUseFinancing) return 'soldfinance';
      return 'soldcash';
    };

    const urlEnd =
      this.props.status === 'SoldPaidOut'
        ? 'paidout'
        : `close/${getStatusUrl(this.props.status, uiState.canUseFinancing)}`;

    const toLink = `/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/vehicle/${this.props.vehicleId}/${urlEnd}`;
    return (
      <div key="container" className="vehicleDetailsButtonContainer">
        {uiState.canGenerateQuotes && (
          <div className="vehicleDetailsButtonContainer__newQuoteButton" key="newQuoteButton">
            <Button
              buttonStyle="primary"
              to={`/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/vehicle/${this.props.vehicleId}/quote`}
            >
              {this.props.t('VehicleDetailsButtonContainer.build_new_quote')}
            </Button>
          </div>
        )}
        {uiState.canUsePushDeal &&
          uiState.canGenerateQuotes &&
          CustomerType === 'Consumer' &&
          !canUseSendDealViaQuoteResultsPage() && (
            <div className="vehicleDetailsButtonContainer__newQuoteButton" key="pushDealButton">
              <TrackEvent
                featureName="PushDealCustomerRecord"
                interactionName="PushDeal__linkFromCustomerRecord"
                linkTo={`/d/${this.props.params.dealershipId}/send-deal?consumerId=${this.props.params.consumerId}`}
              >
                <Button buttonStyle="secondary" onClick={this.onSendDeal} dataThook="PushDealButton">
                  {this.props.t('VehicleDetailsButtonContainer.send_deal')}
                </Button>
              </TrackEvent>
            </div>
          )}
        <div className="vehicleDetailsButtonContainer__smallSection">
          {uiState.canCloseDeals && (
            <div className="vehicleDetailsButtonContainer__closeDealButton" key="closeDealButton">
              <Button to={toLink} size="small" buttonStyle="secondary">
                {this.props.isClosed
                  ? this.props.t('VehicleDetailsButtonContainer.edit_closed_deal')
                  : this.props.t('VehicleDetailsButtonContainer.close_deal')}
              </Button>
            </div>
          )}

          <div className="vehicleDetailsButtonContainer__comments" key="comments">
            <CommentsButton onClick={this.props.onNotesClick} count={this.props.notesCount} />
          </div>
        </div>
      </div>
    );
  }
}

VehicleDetailsButtonContainer.propTypes = {
  params: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  consumer: PropTypes.object,
  vehicleId: PropTypes.string,
  status: PropTypes.string,
  isClosed: PropTypes.bool,
  onNotesClick: PropTypes.func,
  notesCount: PropTypes.number,
  appStore: PropTypes.object
};
export default compose(
  withParams,
  withNavigate,
  withTranslation('Customer'),
  inject(['appStore']),
  observer
)(VehicleDetailsButtonContainer);
