export const AdmiralLogo = () => {
  return (
    <span style={{ width: '65px' }}>
      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 240 74">
        <path
          d="M229.22 1.59a14.79 14.79 0 0 0-14.79-14.79H6.77A14.79 14.79 0 0 0-8 1.59v65.32A14.79 14.79 0 0 0 6.77 81.7h207.66a14.79 14.79 0 0 0 14.79-14.79Z"
          fill="none"
        />
        <path
          d="m68.14 53.33.21-.29a.22.22 0 0 1 .17-.09h6.38a.21.21 0 0 1 .19.14 20.6 20.6 0 0 1 1.18 5.74.14.14 0 0 1 0 .07c-.61 1.75-2.54 7.81-10.82 12a28.54 28.54 0 0 1-14.5 2.73 47.72 47.72 0 0 1-18.58-5.26 63.81 63.81 0 0 1-17.46-13A58.58 58.58 0 0 1 3.67 38.89a40.14 40.14 0 0 1-3.59-16.1C.08 1.56 19.91.1 24 .06c19-.19 29.72 11.27 29.72 11.27a.23.23 0 0 1 0 .29.21.21 0 0 1-.29 0S40.07-.69 21.84 1.84A19.47 19.47 0 0 0 10.67 7C6.9 10.67 5.42 15.8 6 21.47c.85 8.54 6.34 18.29 15.65 26.2S42 59.9 51.38 60.05c6.23.1 11.73-1.62 15.51-5.33a17.36 17.36 0 0 0 1.23-1.36Z"
          fill="#c20060"
          fill-rule="evenodd"
        />
        <path
          d="m74.63 49.27.37-3.13a10.86 10.86 0 0 1-8.61 4c-3.53 0-6.12-1.26-7.75-3.77S56.48 40.3 57 35.74a22.85 22.85 0 0 1 2.44-8.19 13.48 13.48 0 0 1 4.7-5.2 11.74 11.74 0 0 1 6.39-1.78c3.12 0 5.42 1.1 6.92 3.29L79 11.39h9.6L84 49.27Zm112.09 0 .37-3a14.87 14.87 0 0 1-4.22 2.86 11.9 11.9 0 0 1-4.68.9c-2.9 0-5.09-.75-6.59-2.25a7.05 7.05 0 0 1-1.77-6.13 8.88 8.88 0 0 1 4.17-6.73q3.57-2.31 10.4-2.63l4.21-.21.09-1.08c.3-2.44-.74-3.66-3.1-3.66-2.2 0-3.56.92-4.11 2.76l-8.56-.69q2.5-8.8 13.92-8.81 6.3 0 9 2.81c1.78 1.88 2.44 4.74 2 8.6l-2.13 17.29ZM21.27 42.52 33.74 16a8.18 8.18 0 0 1 7.51-4.7H42c3.57 0 6.1 1.92 7 6 2.4 10.7 7.18 31.97 7.18 31.97H45.62L44.34 42H31.55l-2.49 6a38.54 38.54 0 0 1-7.79-5.48Zm178.92 6.75 4.65-37.88h9.6l-4.65 37.88Zm-65.3 0 3.42-27.85h9.6l-3.42 27.85Zm14.38 0 3.42-27.85H162l-.41 3.4a11 11 0 0 1 3.7-2.92 10.53 10.53 0 0 1 4.57-1 12.06 12.06 0 0 1 2.67.27l-2.07 8.22a10.75 10.75 0 0 0-2.93-.32 8 8 0 0 0-4.48 1.11c-1.36.93-2.17 2.52-2.45 4.78l-1.75 14.27Zm-28.49 0L123 31.6a3.48 3.48 0 0 0-.31-2.38 2.23 2.23 0 0 0-2-.75 4.09 4.09 0 0 0-2.83 1 4.45 4.45 0 0 0-1.42 2.92l-2.07 16.87h-9.61l2.17-17.67a3.48 3.48 0 0 0-.31-2.38 2.23 2.23 0 0 0-2-.75 4.09 4.09 0 0 0-2.83 1 4.45 4.45 0 0 0-1.42 2.92l-2.02 16.89h-9.61l3.42-27.85h9.34l-.4 3.24a10.35 10.35 0 0 1 8.3-4.09 7.9 7.9 0 0 1 4.35 1.12 5.85 5.85 0 0 1 2.44 3.23 13.19 13.19 0 0 1 4.2-3.29 11.24 11.24 0 0 1 4.87-1.06q4.24 0 6.13 2.55t1.29 7.42l-2.3 18.73Zm67.07-11.78-3.1.16a9.68 9.68 0 0 0-4.07.93 3 3 0 0 0-1.53 2.31c-.21 1.69.74 2.54 2.82 2.54a5.76 5.76 0 0 0 3.93-1.35 5.54 5.54 0 0 0 1.83-3.63ZM76.12 35c.3-2.4.14-4.14-.47-5.2a3.45 3.45 0 0 0-3.26-1.59q-4.71 0-5.59 7.11t3.8 7a4.48 4.48 0 0 0 3.72-1.75A9.33 9.33 0 0 0 76 35.79ZM40.57 20.84 35 33.89h7.8Zm98.07-2.5.85-6.95h9.71l-.85 7Z"
          fill="#0045a0"
        />
      </svg>{' '}
    </span>
  );
};
