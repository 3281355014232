import { FinanceNavigatorResult } from "../FinanceNavigator/types/financeNavigatorResultMessage";

export interface FunderType {
  BuildingName: string;
  BuildingNumber: string;
  Code: string;
  Country: string;
  District: string;
  Id: string;
  Name: string;
  Organisation: string;
  PostTown: string;
  Postcode: string;
  Street: string;
  SubBuilding: string;
  Disclaimer: string;
}
// This is the equivalent of the QuoteCardVariantType TS Enum
// but we will use this in JS only files, to avoid passing strings around
export const QuoteCardVariantTypeJs = {
  FinanceQuote: 'FinanceQuote',
  FinanceApplication: 'FinanceApplication',
  FinanceNavigatorCheck: 'FinanceNavigatorCheck',
  SavedFinanceNavigatorCheck: 'SavedFinanceNavigatorCheck',
  SavedQuote: 'SavedQuote',
  CarFinanceCheck: 'CarFinanceCheck',
  SelfServiceDeals: 'SelfServiceDeals',
  SendDeal: 'SendDeal',
  Requote: 'Requote'
}
export enum QuoteCardVariantTypeEnum {
  FinanceQuote = 'FinanceQuote',
  FinanceApplication = 'FinanceApplication',
  FinanceNavigatorCheck = 'FinanceNavigatorCheck',
  SavedFinanceNavigatorCheck = 'SavedFinanceNavigatorCheck',
  SavedQuote = 'SavedQuote',
  CarFinanceCheck = 'CarFinanceCheck',
  SelfServiceDeals = 'SelfServiceDeals',
  SendDeal = 'SendDeal',
  Requote = 'Requote'
}

export type QuoteCardVariantType = keyof typeof QuoteCardVariantTypeEnum;

export const QuoteSummaryVariantTypeJs  = {
  FinanceApplicationSummary: 'FinanceApplicationSummary',
  CarFinanceCheckSummary: 'CarFinanceCheckSummary',
  SavedQuoteSummary: 'SavedQuoteSummary',
  SelfServiceSummary: 'SelfServiceSummary',
  ProductComparePrintSummary: 'ProductComparePrintSummary'
}
export enum QuoteSummaryVariantTypeEnum {
  FinanceApplicationSummary = 'FinanceApplicationSummary',
  CarFinanceCheckSummary = 'CarFinanceCheckSummary',
  SavedQuoteSummary = 'SavedQuoteSummary',
  SelfServiceSummary = 'SelfServiceSummary',
  ProductComparePrintSummary = 'ProductComparePrintSummary'
}
export type QuoteSummaryVariantType = keyof typeof QuoteSummaryVariantTypeEnum;
export type QuoteVariants = QuoteCardVariantType | QuoteSummaryVariantType;


export type BasicPaymentSchedule = {
  FirstPayment: number;
  FinalPayment: number;
}
export type StandardPaymentSchedule = BasicPaymentSchedule & {
  StandardPayments: PaymentDetails;
}

export type AdvancedPaymentSchedule = BasicPaymentSchedule & {
  [key: `PAYMENT__${number}`]: PaymentDetails;
}

export type GetPaymentTableDataResponse =  StandardPaymentSchedule | AdvancedPaymentSchedule;

export type QuotewarePaymentScheduleDetails = {
  Amount: number;
  Number: number;
};

export type PaymentDetails = {
  amount: number;
  duration: number;
};
export type ValueAddedProductType = {
  Description: string;
  Id: string;
  Name: string;
  Price: number;
  ProductTypeCode: string;
  ProductTypeText: string;
  TaxTypeCode: number;
}
export enum CommissionTypes {
  None = "None",
  Fixed = "Fixed",
  PercentageOfAdvance = "PercentageOfAdvance",
  PercentageOfCashPrice = "PercentageOfCashPrice",
  DIC = "DIC",
  FunderSpecific = "FunderSpecific"
}
export type CommissionTypesList = keyof typeof CommissionTypes;
export type Commission = {
  Amount: number;
  Method: string;
  Type: CommissionTypesList;
  Value: number;
  VolumeBonus: number;
}


export type GroupedVaps = Record<string, ValueAddedProductType[]>;
export type VapToDisplay = Record<string, number>;

export enum QuoteRowType {
  default = 'default',
  money = 'money',
  decimals = 'decimals',
  excess = 'excess',
  months = 'months',
  number = 'number',
  paymentSchedule = 'paymentSchedule'
}
export type QuoteRowValue =
  | string
  | number
  | null
  | undefined
  | PaymentDetails
  | ValueAddedProductType[];


// This is the equivalent of the LoanType TS Enum
// but we will use this in JS only files, to avoid passing strings around
export const LoanTypeJs = Object.freeze({
  standardLoan: 'Aggregate',
  vapsLoan: 'ValueAddedProduct',
  vehicleLoan: 'Vehicle',
  negativeEquityLoan: 'NegativeEquity'
})

export enum LoanType {
  standardLoan = 'Aggregate',
  vapsLoan = 'ValueAddedProduct',
  vehicleLoan = 'Vehicle',
  negativeEquityLoan = 'NegativeEquity'
}

export type QuoteRow = {
  value: QuoteRowValue;
  type?: QuoteRowType;
};

export type CompanionQuotes = [ VehicleLoanDataType, VapsLoanDataType?, NegativeEquityLoanDataType? ]
export type BaseQuoteType = {
  Advance?: number;
  TotalAmountOfCredit: number;
  TotalDeposit: number;
  FunderCode: string;
  FunderProductUID: string;
  FinanceType: string;
  Commissions: null | Commission[];
  ChargePerOverDistanceUnit: number;
  CashPrice: number;
  Cashback?: string;
  TotalCashback?: string;
  CashDeposit?: string;
  FinanceDepositAllowance?: number;
  RequestedDeposit?: string;
  PartExchange?: string | number; // It's a number on the customer page for Self Service Quote calculations
  OutstandingSettlement: string;
  TotalAmountPayable: string;
  RepresentativeApr: number;
  TotalFees?: string | null;
  OptionToPurchaseFee?: number | null;
  AcceptanceFeeFirst?: number;
  AcceptanceFeeLast?: number;
  InterestCharges: number;
  AnnualDistanceQuoted: number | null;
  Fees?: string | null;
  ValueAddedProducts: ValueAddedProductType[];
  PaymentSchedule: QuotewarePaymentScheduleDetails[];
  PreApprovalType?: PreApprovalType;
  CompanionQuotes?: CompanionQuotes;
  FirstPayment: number;
  FinalPayment: number;
  FollowedBy: number;
  FollowingPayments: number;
  Term: number;
  AnnualRate: number;
  isRequote?: boolean;
  CreditTier?: string;
  SupportsPreApproval: boolean;

  PersonalLoan?: any;
  NegativeEquityLoan?: any;
  VehicleLoan?: any;

  QuoteId: string;
  QuoteeUid: string;
  QuoteeProductCode?: string;
  WashoutNegativeEquityAmount?: number; // Used on companion quotes to calculate negative equity deposit

  Warranty?: number; // On Saved Quote Details Page
  Insurance?: number; // On Saved Quote Details Page
  OtherAccessories?: number; // On Saved Quote Details Page
  NonVatableItems?: number; // On Saved Quote Details Page
  ProductId: string;
};

export enum EligibilityOffered {
  PreApproved = 'PreApproved',
  VeryHigh = 'VeryHigh',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Unknown = 'Unknown',
  UnableToAssist = 'UnableToAssist',
  NotOffered = 'NotOffered'
}
export type EligibilityOfferedType = typeof EligibilityOffered[keyof typeof EligibilityOffered];

// Should mainly use this type to drive the CTAs
export enum PreApprovalType {
  None = 'None',
  InterceptorPreApproval = 'InterceptorPreApproval',
  PreApproval = 'PreApproval',
  EligibilityCheck = 'EligibilityCheck',
  RateForRisk = 'RateForRisk',
  CFC = 'CFC'
}

export type FinanceNavigatorEligibilityDataType = Omit<FinanceNavigatorResult,"PersonalisedQuote">;

export type FinanceNavigatorEligibilityLenderDataType = FinanceNavigatorEligibilityDataType['Data'];

export type FinanceNavigatorResultDataType = StandardLoanDataType & {
  Eligibility: FinanceNavigatorEligibilityDataType
}

export type QuoteErrorType = { Number: number; Message: string }

export type StandardLoanDataType = BaseQuoteType & {
  LoanType: LoanType.standardLoan;
  ProductName?: string; // On the summary pages this field is present
  VehiclePrice?: number; // On the summary pages this field is present
  ActualCashDeposit?: string;
  Funder?: FunderType;
  Errors?: QuoteErrorType[];
  RequestedCashback?: number;
}

export type VapsLoanDataType = BaseQuoteType & {
  LoanType: LoanType.vapsLoan;
}

export type VehicleLoanDataType = BaseQuoteType & {
  LoanType: LoanType.vehicleLoan;
  ProductName: string;
  VehiclePrice: number;
  OptionToPurchaseFee: number | null;
}

export type NegativeEquityLoanDataType = BaseQuoteType & {
  LoanType: LoanType.negativeEquityLoan;
  NegativeEquity: number;
}

export type CombinedQuoteDataType = StandardLoanDataType & {
  VehicleLoan: VehicleLoanDataType,
  PersonalLoan?: VapsLoanDataType,
  NegativeEquityLoan?: NegativeEquityLoanDataType
}

export type QuoteTypes = StandardLoanDataType | VapsLoanDataType | VehicleLoanDataType | NegativeEquityLoanDataType | FinanceNavigatorResultDataType;
export type DisplayQuoteFormat = Record<FormattedQuoteKey, QuoteRow>;

export type QuoteDataByLoanType = {
  [LoanType.vehicleLoan]: VehicleLoanDataType;
  [LoanType.vapsLoan]?: VapsLoanDataType;
  [LoanType.negativeEquityLoan]?: NegativeEquityLoanDataType;
};
export type ExtendedQuoteDataByLoanType = QuoteDataByLoanType & {
  [LoanType.standardLoan]: BaseQuoteType;
};

export type FormattedQuote = Pick<
  QuoteTypes,
  | 'PartExchange'
  | 'Cashback'
  | 'CashDeposit'
  | 'InterestCharges'
  | 'TotalAmountPayable'
> &
Pick<Partial<QuoteTypes>,  'CashPrice' | 'TotalDeposit' | 'FinanceDepositAllowance'> &
  GetPaymentTableDataResponse & {
    TotalAmountOfCredit?: number;

    // These are keys that we've created in the formatted quote.
    // They rename values from the main quote object
    AnnualMileage?: number;
    OptionToPurchaseFee?: number | null;
    AcceptanceFeeFirst?: number;
    AcceptanceFeeLast?: number;
    ExcessPerMile?: number;
    OutstandingSettlement?: number;
    AnnualFixedInterestRate: number;
    AgreementTerm: number;
    Apr: number;
    ProductName?: string;
    VehiclePrice?: number;
    StandardPayments?: PaymentDetails;
  };
export type FormattedQuoteKey = keyof FormattedQuote;
