import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { inject } from 'mobx-react';
import { compose } from 'redux';
import { useNavigate, useParams } from 'react-router-dom';

import * as quoteActions from '../../../redux/quote/quoteActions';
import * as analyticsActions from '../../../redux/analytics/analyticsActions';
import * as modalActions from '../../../redux/modal/modalActions';
import { VIEW_MONTHLY_PAYMENTS } from '../../../redux/actionTypes';
import { getSortedQuotes, getDefaultQuoteDetails, getFullQuoteDetails } from '../selectors/quotingSelectors';

import { trackCfcEligibilityCheckActivated, formatQuoteVehicleToCfcTracking } from '../../../tracking/avoTracking';

import { withNavigate, withParams, withQuery } from 'hocs/router';
import observerForHooks from 'hocs/observerForHooks';

import QuoteModule from '../components/QuoteModule/QuoteModule';

import { QuoteModuleVariantType } from '~/components/Quoting/components/QuoteModule/types';

import { FixLater } from '~/types/basic';
import { AppStore } from '~/modules/Stock/types/Types';
import { getPropsTypeByVariant, QuoteModuleContainerProps } from './types';

export type QuoteModuleBaseProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {
    appStore: AppStore;
    variant: QuoteModuleVariantType;
  };

const QuoteModuleContainer = (allProps: QuoteModuleContainerProps) => {
  const props = getPropsTypeByVariant(allProps);
  const [shouldRender, setShouldRender] = useState<boolean>(false);
  const { dealershipId, quoteId } = useParams();
  const navigate = useNavigate();
  const { shouldResetQuotingState, setShouldResetQuotingState = () => {} } = props?.appStore?.quotingStore || {};

  useEffect(() => {
    // componentDidUpdate equivalent
    // The scenario this tries to fix is if you have done a QuickQuote via the search bar using a VRM,
    // You've started the quoting journey and while you are on one of the "views", you decide to
    // put a new VRM in the search bar in the header and press enter. Without this fix, it won't do anything, i.e.
    // it won't restart the quoting journey with the new VRM
    if (shouldResetQuotingState) {
      setShouldResetQuotingState(false);
      props?.startQuoting();
    }
  }, [shouldResetQuotingState]);

  // Was in UNSAFE_componentWillMount
  useEffect(() => {
    if (props?.isContinuingFromQuickQuote) {
      // This occurs when doing a old eligibility check (cfc) from quick quote, it finishes submitting the cfc
      // and then redirects to the customer - add quote page and continues quoting from there.
      // See quoteActions.checkEligibility
      props?.continueQuoting();
      // We need to delay trying to show the QuoteModule until the redux actions are done and the state ready
      // because it might have to render a different "view" based on the state
      setShouldRender(true);
    } else {
      props?.appStore.vapStore.fetchValueAddedProducts(dealershipId).then(() => {
        switch (true) {
          // Starting finance navigator from customer profile from a saved quote
          case props?.variant === QuoteModuleVariantType.CustomerQuote && quoteId !== undefined:
            props?.startFinanceNavigatorFromCustomerProfile();
            break;

          // Re-quoting with a new APR for an existing product (interceptor)
          case props?.variant === QuoteModuleVariantType.ApplicationRequote && props?.newAPR !== undefined:
            props?.startRequoting();
            break;

          // Regular quoting journeys - customer, quick quote, application
          default:
            props?.startQuoting();
            break;
        }
        // We need to delay trying to show the QuoteModule until the redux actions are done and the state ready
        // because it might have to render a different "view" based on the state
        setShouldRender(true);
      });
    }
    // Was in componentWillUnmount
    return () => {
      return props?.endQuoting();
    };
  }, []);

  const updateQueryString = () => {
    navigate(`?section=${props?.currentView?.toLowerCase()}`);
  };

  return shouldRender ? <QuoteModule {...props} updateQueryString={updateQueryString} /> : null;
};

function mapStateToProps(state: FixLater, ownProps: FixLater) {
  return {
    // Props passed from the parent container
    canEditVehicle: ownProps.isQuickQuote,
    isContinuingFromQuickQuote: ownProps.query.continue === 'true',
    defaultQuoteDetails: getDefaultQuoteDetails(state, ownProps.quote, ownProps.isQuickQuote),
    // In the application containers this comes from the application store
    // Could we just drive it based off the variant?
    proceedingState: ownProps.hasOwnProperty('proceedingState')
      ? ownProps.proceedingState
      : state.quotes.proceedingState,

    // State derived from the dealership store - is this not in the URL?
    // Looks like it's only passed into the <CheckEligibility /> component inside <QuoteModule />
    dealershipId: state.dealership.Id,
    // State derived from the quotes store - why not pass just the Quotes?
    quotes: state.quotes,
    hasAdjustedBalloon: state.quotes.hasAdjustedBalloon,
    returnedQuotes: getSortedQuotes(state),
    eligibilityCheckDetails: state.quotes.eligibilityCheckDetails,
    isLoadingCfcPreFill: state.quotes.isLoadingCfcPreFill,
    checkingEligibilityState: state.quotes.checkingEligibilityState,
    hasCheckedEligibility: state.quotes.hasCheckedEligibility,
    currentView: state.quotes.currentView,
    previousView: state.quotes.previousView,
    vehicle: state.quotes.vehicle,
    craScorePercentage: state.quotes.craScorePercentage,
    personalScorePercentage: state.quotes.personalScorePercentage,
    applyingState: state.quotes.applyingState,
    savingState: state.quotes.savingState,
    sortField: state.quotes.sortField,
    sortDirection: state.quotes.sortDirection,
    fullQuoteDetails: getFullQuoteDetails(state),
    monthlyPayments: state.quotes.monthlyPayments,
    isFetchingMonthlyPayments: state.quotes.isFetchingMonthlyPayments,
    hasFetchMonthlyPaymentsError: state.quotes.hasFetchMonthlyPaymentsError
  };
}

function mapDispatchToProps(dispatch: FixLater, ownProps: FixLater) {
  return {
    startQuoting: () => {
      dispatch(
        quoteActions.startQuoting(
          ownProps.params.consumerId,
          ownProps.customerEmail,
          ownProps.vehicle,
          ownProps.initialCosts,
          ownProps.restrictToProductType,
          ownProps.restrictToFunderCode,
          ownProps.customerType,
          ownProps.restrictQuotesByProduct
        )
      );
    },
    startRequoting: () => {
      dispatch(
        quoteActions.startRequoting(
          ownProps.params.consumerId,
          ownProps.customerEmail,
          ownProps.vehicle,
          ownProps.initialCosts,
          ownProps.customerType,
          ownProps.currentProductUid,
          ownProps.newAPR,
          ownProps.preApprovalData
        )
      );
    },

    startFinanceNavigatorFromCustomerProfile: () => {
      // Depends on the quotesActions.prePopulateQuotesAndVehicle being ran in the parent container
      dispatch(quoteActions.startFinanceNavigatorFromCustomerProfile());
    },

    handleCostsChange: (newCosts: FixLater) => {
      dispatch(quoteActions.changeCosts(newCosts));
    },

    handleChangeCostsClick: () => {
      dispatch(quoteActions.changeView('COSTS_VIEW'));
    },

    handleProceedToFinanceNavigator: (quoteIds: string[]) => {
      dispatch(quoteActions.startFinanceNavigator(quoteIds));
    },

    handleSubmitFinanceNavigator: (scanId: string) => {
      dispatch(quoteActions.submitFinanceNavigator(scanId));
    },

    handleCancelFinanceNavigator: () => {
      dispatch(quoteActions.cancelFinanceNavigator());
    },

    handleQuoteSelect: (quoteId: string, checked: boolean) => {
      if (checked) {
        dispatch(quoteActions.addQuoteForCompare(quoteId));
      } else {
        dispatch(quoteActions.removeQuoteForCompare(quoteId));
      }
    },

    handleRemoveQuoteFromCompare: (quoteId: string) => {
      dispatch(quoteActions.removeQuoteForCompare(quoteId));
    },

    handleCheckEligibilityClick: () => {
      trackCfcEligibilityCheckActivated(formatQuoteVehicleToCfcTracking(ownProps.vehicle));
      dispatch(quoteActions.preFillEligibilityFormDetails());
      dispatch(quoteActions.changeView('CHECK_ELIGIBILITY_VIEW'));
    },

    handleReturnToListViewClick: () => {
      dispatch(quoteActions.changeView('LIST_VIEW'));
    },

    handleBackToPreviousView: () => {
      dispatch(quoteActions.backToPreviousView());
    },

    onPrint: () => {
      dispatch(analyticsActions.trackPageInteraction('QuoteComparePrint', ownProps.params.dealershipId));
    },

    changeComissions: (settings: FixLater) => {
      dispatch(quoteActions.changeCommissions(settings));
    },

    handleEligibilityFormSectionSubmit: (sectionName: string, formData: FixLater) => {
      dispatch(quoteActions.updateEligibilityFormData(sectionName, formData));
    },

    handleEligibilityFormSubmit: () => {
      dispatch(quoteActions.checkEligibility());
    },

    continueQuoting: () => {
      dispatch(quoteActions.changeView('LIST_VIEW'));
    },
    onEligibilityMatchClick: (matchRate: FixLater, decisionMessages: FixLater) => {
      dispatch(modalActions.open('eligibilityModal', { matchRate, decisionMessages }));
    },
    onEligibilitySummaryClick: (personalScore: FixLater, creditScore: FixLater) => {
      dispatch(modalActions.open('eligibilitySummary', { personalScore, creditScore }));
    },
    onSortQuotes: (field: FixLater) => {
      dispatch(quoteActions.onSortQuotes(field));
    },
    endQuoting: () => {
      dispatch(quoteActions.endQuoting());
    },
    changeToMonthlyPaymentsView: () => {
      dispatch(quoteActions.changeView('MONTHLY_PAYMENTS_VIEW'));
    },
    changeToConsumerDutyView: () => {
      dispatch(quoteActions.changeView('CONSUMER_DUTY'));
    },
    changeToCompareView: () => {
      dispatch(quoteActions.changeView('COMPARE_VIEW'));
    },
    changeMonthlyPaymentTerm: (term: FixLater) => {
      dispatch(quoteActions.changeTerm(term));
      dispatch(modalActions.close('confirmChangeMonthlyPaymentsTerm'));
      window.ga && window.ga('send', 'event', 'MonthlyPriceView', 'ButtonPress', 'ConfirmCompare');
    },
    selectTerm: (selectedTerm: FixLater) => {
      dispatch(modalActions.open('confirmChangeMonthlyPaymentsTerm', { selectedTerm }));
    },
    closeChangeMonthlyPaymentsTermModal: () => {
      dispatch(modalActions.close('confirmChangeMonthlyPaymentsTerm'));
    },
    selectMonthlyPayment: (quote: FixLater) => {
      dispatch(modalActions.open('selectMonthlyPayment', { quote }));
    },
    updateMonthlyPayments: () => {
      dispatch({ type: VIEW_MONTHLY_PAYMENTS });
    },
    setBreakDownView: () => {
      dispatch(quoteActions.changeView('LOANS_BREAKDOWN_VIEW'));
    }
  };
}

export default compose(
  withQuery,
  withNavigate,
  withParams,
  connect(mapStateToProps, mapDispatchToProps),
  inject('appStore'),
  observerForHooks
)(QuoteModuleContainer);
