import platformApiUtils from '@ivendi/platform-api-utils';
import * as actionTypes from '../actionTypes';
import * as sessionActions from '../session/sessionActions';
import { schemaMap } from '../../core/schema';
import dealershipSettingsSchema from '../../core/schemas/dealershipSettingsSchema';
import { push } from '../../routerHistory';

export function fetchDealershipSettings(dealershipId) {
  return {
    types: [
      actionTypes.FETCH_DEALERSHIP_SETTINGS,
      actionTypes.FETCH_DEALERSHIP_SETTINGS_SUCCESS,
      actionTypes.FETCH_DEALERSHIP_SETTINGS_ERROR
    ],
    callAPI: () => platformApiUtils.get('v1/dealershipsettings/' + dealershipId),
    parseResponse: (data) => schemaMap(data, dealershipSettingsSchema)
  };
}

export function cleanupDealershipSettings() {
  return {
    type: actionTypes.CLEANUP_DEALERSHIP_SETTINGS
  };
}
export const saveDealershipSettings = (dealershipId, data) => {
  const toNumber = (value) => {
    if (value == null) {
      return undefined;
    }
    return Number(value);
  };
  const formattedData = {
    // Required values
    DepositType: data.DepositType ?? '',
    UsageType: data.UsageType ?? '',
    VehicleClass: data.VehicleClass ?? '',
    VehicleType: data.VehicleType ?? '',

    // Optional values
    DepositValue: toNumber(data.DepositValue),
    Insurance: toNumber(data.Insurance),
    Other: toNumber(data.Other),
    Scheme: data.Scheme,
    SchemeValue: toNumber(data.SchemeValue),
    Warranty: toNumber(data.Warranty),

    DisabledEditing: data.DisabledEditing, // bool
    FourSquareEnabled: data.FourSquareEnabled, // bool

    PreferredTerm: toNumber(data.PreferredTerm),
    DefaultAnnualMileage: toNumber(data.DefaultAnnualMileage)
  };
  return (dispatch) => {
    dispatch({
      types: [
        actionTypes.SAVE_DEALERSHIP_SETTINGS,
        actionTypes.SAVE_DEALERSHIP_SETTINGS_SUCCESS,
        actionTypes.SAVE_DEALERSHIP_SETTINGS_ERROR
      ],
      callAPI: () => platformApiUtils.post('v1/dealershipsettings/' + dealershipId, { ...formattedData }),
      onSuccess: () => {
        dispatch(sessionActions.updateWhoAmI());
        window.setTimeout(() => {
          push(`/d/${dealershipId}/settings`);
        }, 300);
      }
    });
  };
};
