import Icon from '../Icon/Icon';
import cn from 'classnames';

import styles from './alertCard.module.scss';
import { ReactElement, ReactNode } from 'react';

type AlertCardProps = {
  styles?: string;
  title?: string | null | ReactNode;
  paragraph?: ReactElement | ReactNode;
  children?: ReactNode;
  iconName?: string;
  className?: string;
  linkText?: null | string;
  onClick?: () => void;
  dataTh?: string;
};
const AlertCard = ({
  title = null,
  paragraph = <></>,
  iconName = 'alert',
  className = '',
  linkText = null,
  children = null,
  onClick = () => {},
  dataTh = 'alert'
}: AlertCardProps) => {
  return (
    <div
      className={cn(styles.card, className, {
        [styles['card--warning']]: iconName === 'warning',
        [styles['card--information']]: iconName === 'information',
        [styles['card--success']]: iconName === 'tick',
        [styles['card--saved']]: iconName === 'validation-tick',
        [styles['card--alert']]: iconName === 'alert'
      })}
      data-th={dataTh}
    >
      <Icon name={iconName} />
      <div className={styles.content}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {paragraph && <div className={styles.paragraph}>{paragraph}</div>}
        {children && children}
        {linkText && (
          <span className={styles.link} onClick={onClick}>
            {linkText}
          </span>
        )}
      </div>
    </div>
  );
};

export default AlertCard;
