import React, { useCallback, useEffect, useMemo, useState } from 'react';

import * as debug from '../../../debug';
import { connect } from 'react-redux';
import appStore from '../../../mobx-stores/appStore';
import { cloneDeep } from 'lodash';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import { useTranslation, withTranslation } from 'react-i18next';
import Panel from '../../Common/Panel';
import PanelContent from '../../Common/PanelContent';
import BroadcastTable from '../components/BroadcastTable';
import BroadcastForm from '../components/BroadcastForm';
import Button from '../../Common/Button/Button';
import Icon from '../../Common/Icon/Icon';
import ToastNotification from '../../../modules/Stock/routes/stock/components/ToastNotification';
import {
  createNewBroadcast,
  deleteBroadcast,
  getBroadcastSubscription,
  getBroadcastSubscriptions,
  updateBroadcastStatus,
  updateBroadcastSubscription
} from '../../../api/broadcast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Modal from '../../Common/Modal/Modal';

import classes from './broadcastSettingsContainer.module.scss';
import { startsWithHttpOrHttps, formatCountryCurrency } from './helpers';

const yearRange = new Array(12).fill(null).map((_, i) => ({
  value: i + 1,
  tag: i + 1
}));

const initFormState = {
  ageFilter: { min: null, max: null },
  classFilter: {
    includeCar: false,
    includeLcv: false,
    includeMotorbike: false,
    includeMotorhome: false,
    includeUnknown: false
  },
  dealerId: '',
  description: '',
  hasVehicleFilter: false,
  name: '',
  priceFilter: { min: null, max: null },
  recipientClassFilter: null,
  recipientState: '', // Disabled Enabled
  subscriptionId: '',
  subscriptionState: '', // Disabled Enabled
  vehicleFilter: [],
  fpaUrlTemplate: undefined // undefined or string when interacted
};
const initVehicleSelectState = { additions: [], removals: [] };

const BroadcastSettingsContainer = () => {
  const { t } = useTranslation('BroadcastSettings');
  const navigate = useNavigate();
  const { dealershipId, subscriptionId } = useParams();
  const location = useLocation();
  const [selected, setSelected] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [formData, setFormData] = useState(() => cloneDeep(initFormState));
  const [data, setData] = useState([]);
  const [recipientOptions, setRecipientOptions] = useState([]);
  const [messageShown, setMessageShown] = useState(false);
  const { pushDangerNotification, pushSuccessNotification } = appStore.notificationStore;
  const countryCode = appStore.uiState.countryCode;
  const { canBroadcast } = appStore.uiState;
  const [hasVehicleFilter, setHasVehicleFilter] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState(() => cloneDeep(initVehicleSelectState));
  const [hasInputErrors, setHasInputErrors] = useState({ ageFilter: false, priceFilter: false });

  useEffect(() => {
    if (!canBroadcast) {
      navigate(`/d/${dealershipId}/settings`);
    }
  }, [canBroadcast, dealershipId, navigate]);

  const priceRange = useMemo(() => {
    const range = Array.from({ length: 29 }, (_, i) => {
      const value = (i + 1) * 500;
      return {
        value,
        tag: t(formatCountryCurrency(countryCode), {
          price: value
        })
      };
    });

    const highValues = [
      {
        value: 250000,
        tag: t(formatCountryCurrency(countryCode), {
          price: 250000
        })
      },
      {
        value: 500000,
        tag: t(formatCountryCurrency(countryCode), {
          price: 500000
        })
      },
      {
        value: 99999999,
        tag: `${t(formatCountryCurrency(countryCode), { price: 1000000 })}+`
      }
    ];

    range.push(...highValues);

    return range;
  }, [t]);

  const getData = useCallback(async () => {
    try {
      const res = await getBroadcastSubscriptions(dealershipId);
      setRecipientOptions(res.availableRecipients);
      setData(res.subscribedRecipients);
    } catch {
      pushDangerNotification(
        `${t('BroadcastSettings.we_are_unable_to_get_broadcast_list')} ${t(
          'BroadcastSettings.please_try_again_if_the_problem_continues_please_contact_iVendi_support'
        )}`,
        t('BroadcastSettings.warning')
      );
    }
  }, [dealershipId, pushDangerNotification, t]);

  useEffect(() => {
    getData();
  }, [dealershipId, getData]);

  useEffect(() => {
    if (!formData.hasVehicleFilter) {
      const formatNumber = (value) => {
        return Number(value || NaN);
      };
      const ageFilter = formatNumber(formData.ageFilter?.min) > formatNumber(formData.ageFilter?.max);
      const priceFilter = formatNumber(formData.priceFilter?.min) > formatNumber(formData.priceFilter?.max);
      setHasInputErrors((prevState) => ({ ...prevState, ageFilter, priceFilter }));
    } else {
      setHasInputErrors({ ageFilter: false, priceFilter: false });
    }
    if (formData.format === 'GVAtab') {
      const inputValue = formData.fpaUrlTemplate;

      // input has not been touched yet otherwise it would be ''
      if (inputValue === undefined) {
        return;
      }
      if (inputValue?.length <= 0) {
        setHasInputErrors((prevState) => ({ ...prevState, fpaUrlTemplate: t('BroadcastSettings.is_required') }));
        return;
      }
      if (!startsWithHttpOrHttps(inputValue)) {
        setHasInputErrors((prevState) => ({
          ...prevState,
          fpaUrlTemplate: t('BroadcastSettings.must_start_with_http')
        }));
        return;
      }
      setHasInputErrors((prevState) => ({ ...prevState, fpaUrlTemplate: false }));
    }
  }, [formData, t]);

  useEffect(() => {
    if (data.length) {
      if (!subscriptionId) {
        setSelectedVehicles(cloneDeep(initVehicleSelectState));
        setFormData(cloneDeep(initFormState));
        setEdit(false);
      } else {
        if (subscriptionId !== 'new') {
          const item = data.find((item) => item.subscriptionId === subscriptionId);

          const getSubscription = async () => {
            let vehicleFilter;
            if (item.hasVehicleFilter) {
              // To avoid waiting for the same data we already got in item from getBroadcastSubscriptions
              // We fetch the missing data on demand to avoid unnecessary render lag.
              try {
                const subscription = await getBroadcastSubscription(dealershipId, subscriptionId);
                vehicleFilter = subscription.vehicleFilter;
              } catch {
                pushDangerNotification(
                  `${t('BroadcastSettings.we_are_unable_to_get_broadcast_vehicle_list')} ${t(
                    'BroadcastSettings.please_try_again_if_the_problem_continues_please_contact_iVendi_support'
                  )}`,
                  t('BroadcastSettings.warning')
                );
              }
              setHasVehicleFilter(true);
            } else {
              setHasVehicleFilter(false);
            }

            setFormData((prevState) => ({
              ageFilter: item.ageFilter || prevState.ageFilter,
              classFilter: item.classFilter || prevState.classFilter,
              dealerId: item.dealerId || prevState.dealerId,
              description: item.description || prevState.description,
              hasVehicleFilter: item.hasVehicleFilter,
              name: item.name || prevState.name,
              priceFilter: item.priceFilter || prevState.priceFilter,
              recipientClassFilter: item.recipientClassFilter || prevState.recipientClassFilter,
              recipientState: item.recipientState || prevState.recipientState,
              subscriptionId: item.subscriptionId || prevState.subscriptionId,
              subscriptionState: item.subscriptionState || prevState.subscriptionState,
              vehicleFilter: vehicleFilter || prevState.vehicleFilter,
              fpaUrlTemplate: item.fpaUrlTemplate || prevState.fpaUrlTemplate,
              format: item.format || prevState.format,
              dealerDownloadLocation: item?.dealerDownloadLocation
            }));
          };
          getSubscription();
        }
        setEdit(true);
      }
    }
  }, [dealershipId, subscriptionId, data, getData, pushDangerNotification, t]);
  const handleEditClick = (id) => {
    const item = data.find((item) => item.subscriptionId === id);

    setEdit(true);
    navigate(`${location.pathname}/${item.subscriptionId}`);
  };

  const handleOnSelectAll = (selected) => {
    if (selected) {
      setSelected(data.map((item) => item.subscriptionId));
    } else {
      setSelected([]);
    }
  };

  const handleOnSelect = (subscriptionId) => {
    const exists = selected.includes(subscriptionId);

    if (exists) {
      setSelected((prevState) => prevState.filter((id) => id !== subscriptionId));
    } else {
      setSelected((prevState) => [...prevState, subscriptionId]);
    }
  };

  const handleOnNew = () => {
    setEdit(true);
    setFormData({
      ...cloneDeep(initFormState),
      recipient: recipientOptions[0].broadcastRecipientId,
      vehicleFilter: [],
      format: recipientOptions[0]?.format
    });
    setSelectedVehicles(cloneDeep(initVehicleSelectState));
    navigate(`${location.pathname}/new`);
  };

  const handleOnCancel = () => {
    navigate(`/d/${dealershipId}/settings/broadcast`);
  };

  const breadcrumbs = useMemo(() => {
    const items = [
      {
        name: t('BroadcastSettings.home'),
        path: `/d/${dealershipId}`
      },
      {
        name: t('BroadcastSettings.settings'),
        path: `/d/${dealershipId}/settings`
      },
      {
        name: t('BroadcastSettings.broadcast'),
        path: `/d/${dealershipId}/settings/broadcast`
      }
    ];

    if (isEdit) {
      items.push({
        name: formData.name ? formData.name : 'New',
        path: `/d/${dealershipId}/settings/broadcast`
      });
    }

    return items;
  }, [dealershipId, formData, isEdit, t]);

  const handleInputChange = (e) => {
    e.persist();
    setFormData((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSelectUpdate = (e) => {
    e.persist();
    setFormData((prevState) => {
      const recipient = recipientOptions.find((option) => option.broadcastRecipientId === e.target.value);
      return {
        ...prevState,
        [e.target.name]: recipient.broadcastRecipientId,
        format: recipient?.format
      };
    });
  };

  const handleFilterUpdate = async (e) => {
    e.persist();

    const value = e.target.value;
    switch (value) {
      case 'Delete':
        handleDeleteClick();
        break;
      case 'Enabled':
      case 'Disabled':
        setData((prevState) => {
          const newState = [...prevState];

          selected.forEach((subId) => {
            const index = prevState.findIndex((item) => item.subscriptionId === subId);

            newState[index] = {
              ...newState[index],
              subscriptionState: e.target.value
            };
          });

          return newState;
        });

        try {
          await Promise.all(
            selected.map((subscriptionId) => updateBroadcastStatus(dealershipId, subscriptionId, value.toLowerCase()))
          );
          setSelected([]);
        } catch {
          pushDangerNotification(
            `${t('BroadcastSettings.we_are_unable_to_update_broadcast_status')} ${t(
              'BroadcastSettings.please_try_again_if_the_problem_continues_please_contact_iVendi_support'
            )}`,
            t('BroadcastSettings.warning')
          );
        }
        break;
      default:
        debug.error(`${value} is not one of the available action options`);
        break;
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (formData.format === 'GVAtab') {
      !formData.fpaUrlTemplate && setFormData((prevState) => ({ ...prevState, fpaUrlTemplate: '' }));
    }
    const cannotSubmit =
      hasInputErrors.ageFilter ||
      hasInputErrors.priceFilter ||
      (formData.format === 'GVAtab' && hasInputErrors.urlFpaTemplate);

    if (cannotSubmit) {
      return;
    }

    let data;
    // Use Stock Filter
    if (formData.hasVehicleFilter) {
      /*
        BE Accepts:
          ageFilter, priceFilter => null, {min: 1}, {min: 1, max: null} {min: null, max: 1}
        BE Rejects:
          ageFilter, priceFilter => {min: null, max: null}
      */
      data = {
        broadcastRecipientId: formData.recipient,
        classFilter: null,
        ageFilter: null,
        priceFilter: null,
        vehicleFilter: formData.vehicleFilter.length ? selectedVehicles : null,
        fpaUrlTemplate: formData.fpaUrlTemplate,
        subscriptionId
      };
    }

    // Use Class filter, Age filter, Price Filter
    if (!formData.hasVehicleFilter) {
      const hasValues = (filter = {}) => {
        return Object.values(filter).some((x) => x) ? filter : null;
      };

      data = {
        broadcastRecipientId: formData.recipient,
        classFilter: formData.classFilter ? hasValues(formData.classFilter) : null,
        ageFilter: formData.ageFilter ? hasValues(formData.ageFilter) : null,
        priceFilter: formData.priceFilter ? hasValues(formData.priceFilter) : null,
        vehicleFilter: null,
        fpaUrlTemplate: formData.fpaUrlTemplate,
        subscriptionId
      };
    }

    if (subscriptionId === 'new') {
      try {
        await createNewBroadcast(dealershipId, data);
        navigate(`/d/${dealershipId}/settings/broadcast`);
        getData();
      } catch {
        pushDangerNotification(
          `${t('BroadcastSettings.we_are_unable_to_create_this_broadcast')} ${t(
            'BroadcastSettings.please_try_again_if_the_problem_continues_please_contact_iVendi_support'
          )}`,
          t('BroadcastSettings.warning')
        );
      }
    }

    if (subscriptionId !== 'new') {
      try {
        await updateBroadcastSubscription(dealershipId, subscriptionId, data);
        navigate(`/d/${dealershipId}/settings/broadcast`);
        getData();
      } catch {
        pushDangerNotification(
          `${t('BroadcastSettings.we_are_unable_to_update_filters')} ${t(
            'BroadcastSettings.please_try_again_if_the_problem_continues_please_contact_iVendi_support'
          )}`,
          t('BroadcastSettings.warning')
        );
      }
    }
  };

  const multiSelectStatusValue = useMemo(() => {
    if (selected.length === 0) return '';
    if (selected.every((index) => data.find((item) => index === item.subscriptionId).subscriptionState === 'Enabled')) {
      return 'Enabled';
    }
    if (
      selected.every((index) => data.find((item) => index === item.subscriptionId).subscriptionState === 'Disabled')
    ) {
      return 'Disabled';
    }
    return 'Multiple';
  }, [data, selected]);

  const handleUpdateState = async (state, subscriptionId) => {
    try {
      await updateBroadcastStatus(dealershipId, subscriptionId, state ? 'enabled' : 'disabled');
      getData();
    } catch (err) {
      pushDangerNotification(
        `${t('BroadcastSettings.we_are_unable_to_update_filters')} ${t(
          'BroadcastSettings.please_try_again_if_the_problem_continues_please_contact_iVendi_support'
        )}`,
        t('BroadcastSettings.warning')
      );
    }
  };

  const handleClassUpdate = (name) => (value) => {
    setFormData((prevState) => {
      const newState = { ...prevState };
      newState.classFilter[name] = value;

      return newState;
    });
  };

  const handleFilterSelectUpdate = ({ target }) => {
    const value = !(!isNaN(parseFloat(target.value, 10)) && isFinite(target.value)) ? null : target.value;
    const name = target.name;
    setFormData((prevState) => {
      const newState = { ...prevState };
      switch (name) {
        case 'min_price':
          newState.priceFilter.min = value;
          return newState;
        case 'max_price':
          newState.priceFilter.max = value;
          return newState;
        case 'min_age':
          newState.ageFilter.min = value;
          return newState;
        case 'max_age':
          newState.ageFilter.max = value;
          return newState;
        default:
          throw Error(`unknown property ${name}`);
      }
    });
  };

  const handleToggleVehicleFilter = (state) => {
    setFormData((prevState) => ({ ...prevState, hasVehicleFilter: state }));
    setHasVehicleFilter(state);
  };

  const handleSelectAllVehicles = (state, idArray) => {
    const includes = state ? 'additions' : 'removals';
    const push = state ? 'removals' : 'additions';

    setSelectedVehicles((prevState) => {
      const newState = {
        ...prevState
      };

      idArray.forEach((id) => {
        if (newState[includes].includes(id)) {
          newState[includes] = newState[includes].filter((item) => item !== id);
        } else {
          newState[push].push(id);
        }
      });

      return newState;
    });

    setFormData((prevState) => ({ ...prevState, vehicleFilter: state ? [] : idArray }));
  };

  const handleDeselectAllVehicles = () => {
    setSelectedVehicles((prevState) => ({
      ...prevState,
      additions: [],
      removals: []
    }));

    setFormData((prevState) => ({ ...prevState, vehicleFilter: [] }));
  };

  const handleSelectVehicle = (state, id) => {
    const includes = state ? 'removals' : 'additions';
    const push = state ? 'additions' : 'removals';

    setSelectedVehicles((prevState) => {
      const newState = {
        ...prevState
      };

      if (newState[includes].includes(id)) {
        newState[includes] = newState[includes].filter((removal) => removal !== id);
      } else {
        newState[push].push(id);
      }
      return newState;
    });

    setFormData((prevState) => {
      let newState = { ...prevState };
      if (state) {
        newState.vehicleFilter.push(id);
      } else {
        newState.vehicleFilter = newState.vehicleFilter.filter((item) => item !== id);
      }
      return newState;
    });
  };

  const handleMouseEnter = () => {
    setMessageShown(true);
  };
  const handleMouseLeave = () => {
    setMessageShown(false);
  };

  const cannotSubmit = hasInputErrors.priceFilter || hasInputErrors.ageFilter;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLoadingState, setModalLoadingState] = useState('idle'); // idle|submitting|error

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setModalLoadingState('loading');

    try {
      await Promise.all(selected.map((id) => deleteBroadcast(dealershipId, id)));

      setModalLoadingState('idle');
      setIsModalOpen(false);
      setSelected([]);
      getData();
      pushSuccessNotification(t('BroadcastSettings.subscriptions_succesfully_deleted'), t('BroadcastSettings.success'));
    } catch {
      setModalLoadingState('error');
      pushDangerNotification(
        t('BroadcastSettings.something_went_wrong_while_deleting_subscriptions_please_try_again_later'),
        t('BroadcastSettings.warning')
      );
    }
  };
  return (
    <Page className={classes.page}>
      <ToastNotification />
      <Modal
        title={t('BroadcastSettings.are_you_sure')}
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        onConfirm={handleConfirmDelete}
        buttonText={t('BroadcastSettings.delete')}
        buttonStyle="cancel"
        isLoading={modalLoadingState === 'loading'}
        hasError={modalLoadingState === 'error'}
      >
        <div className={classes.confirmationModal}>
          {t('BroadcastSettings.this_action_is_irreversible_it_may_take_several_minutes_to_delete_subscriptions')}
        </div>
      </Modal>
      <div>
        <div>
          <Breadcrumbs items={breadcrumbs} />
        </div>
        <Panel className={classes.panel}>
          <div className={classes.headerWrapper}>
            <div className={classes.headerTitle}>
              <div className={classes.headerContent}>
                {t('BroadcastSettings.title')}
                {isEdit && formData ? ` - ${formData.name ? formData.name : t('BroadcastSettings.new')}` : null}
                <div className={classes.headerButtonGroup}>
                  {!isEdit && (
                    <select
                      value={multiSelectStatusValue}
                      className={classes.selectInput}
                      name="subscriptionState"
                      onChange={handleFilterUpdate}
                      disabled={selected.length === 0}
                    >
                      <option disabled value={''}>
                        {t('BroadcastSettings.select_a_broadcast_status')}
                      </option>
                      <option disabled value={'Multiple'}>
                        {t('BroadcastSettings.multiple_statuses_in_selected')}
                      </option>
                      <option value={'Enabled'}>{t('BroadcastSettings.enabled')}</option>
                      <option value={'Disabled'}>{t('BroadcastSettings.disabled')}</option>
                      <option value={'Delete'}>{t('BroadcastSettings.delete')}</option>
                    </select>
                  )}
                  {isEdit && (
                    <div className={classes.headerButtonGroup}>
                      <Button
                        className={classes.button}
                        buttonStyle="cancel"
                        type="button"
                        name="cross"
                        onClick={handleOnCancel}
                      >
                        {t('BroadcastSettings.cancel')}
                      </Button>
                      <Button
                        className={[classes.button, classes.headerSaveButton]}
                        onClick={handleOnSubmit}
                        disabled={cannotSubmit}
                      >
                        {t('BroadcastSettings.save')}
                      </Button>
                    </div>
                  )}
                  {recipientOptions.length === 0 && !isEdit && (
                    <div
                      className={classes.disabledButtonWrapper}
                      onMouseOver={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <Button className={[classes.button, classes.newButton]} disabled type="button">
                        <Icon name="plus" />
                        {t('BroadcastSettings.new')}
                      </Button>
                    </div>
                  )}
                  {messageShown && (
                    <div className={classes.hoverMessageContainer} role="tooltip" aria-hidden>
                      <p className={classes.hoverMessage}>{t('BroadcastSettings.no_recipients_available')}</p>
                    </div>
                  )}
                  {recipientOptions.length > 0 && !isEdit && (
                    <Button
                      className={[classes.button, classes.newButton]}
                      type="button"
                      name="plus"
                      onClick={handleOnNew}
                      buttonStyle="primary"
                    >
                      <Icon name="plus" /> {t('BroadcastSettings.new')}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <PanelContent>
            {!isEdit ? (
              <BroadcastTable
                data={data}
                selected={selected}
                handleOnSelect={handleOnSelect}
                handleOnSelectAll={handleOnSelectAll}
                handleEditClick={handleEditClick}
                handleUpdateState={handleUpdateState}
              />
            ) : (
              <BroadcastForm
                dealershipId={dealershipId}
                formData={formData}
                handleClassUpdate={handleClassUpdate}
                handleDeselectAllVehicles={handleDeselectAllVehicles}
                handleFilterSelectUpdate={handleFilterSelectUpdate}
                handleInputChange={handleInputChange}
                handleOnCancel={handleOnCancel}
                handleOnSubmit={handleOnSubmit}
                handleSelectAllVehicles={handleSelectAllVehicles}
                handleSelectUpdate={handleSelectUpdate}
                handleSelectVehicle={handleSelectVehicle}
                handleToggleVehicleFilter={handleToggleVehicleFilter}
                hasInputErrors={hasInputErrors}
                hasVehicleFilter={hasVehicleFilter}
                priceRange={priceRange}
                recipientOptions={recipientOptions}
                selectedVehicles={formData.vehicleFilter}
                subscriptionId={subscriptionId}
                yearRange={yearRange}
              />
            )}
          </PanelContent>
        </Panel>
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    components: state.components
  };
};

export default connect(mapStateToProps, null)(withTranslation('BroadcastSettings')(BroadcastSettingsContainer));
