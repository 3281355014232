import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import classnames from 'classnames';
import './dashboardEligibilityMark.scss';
import CfcCheck from '~/styles/icons/cfc-check.svg';
import FinanceNavCheck from '~/styles/icons/finance-nav-check.svg';

interface DashboardEligibilityMarkProps {
  matchRate: string;
  source: 'cfc' | 'finance-nav';
  showDotIndicator?: boolean;
  variant?: 'row' | 'column';
}

const DashboardEligibilityMark = ({
  matchRate = 'unknown',
  source = 'cfc',
  showDotIndicator = true,
  variant = 'row'
}: DashboardEligibilityMarkProps) => {
  const { t } = useTranslation('Dashboard');

  const loweredMatchRate = matchRate?.toLowerCase() || 'unknown';
  const eligibilityMatchRates = ['veryhigh', 'high', 'medium', 'low', 'unknown'];
  const matchRates = [...eligibilityMatchRates, 'preapproved', 'unabletoassist', 'notoffered'];

  const status = matchRates.includes(loweredMatchRate) ? loweredMatchRate : 'unknown';

  const classNames = classnames(
    'dashboardEligibilityMark',
    `dashboardEligibilityMark--${status}`,
    `dashboardEligibilityMark--${variant}`
  );

  return (
    <div className={classNames}>
      {showDotIndicator && <div className="dashboardEligibilityMark__dot" />}
      <span className="dashboardEligibilityMark__status">
        {t(`DashboardEligibilityMark.${status}`).toUpperCase()}{' '}
        {eligibilityMatchRates.includes(status) && (
          <span className="dashboardEligibilityMark__text">{t('DashboardEligibilityMark.eligibility')}</span>
        )}
      </span>
      <span className="dashboardEligibilityMark__source">
        <img src={source === 'cfc' ? CfcCheck : FinanceNavCheck} />
        {source === 'cfc' ? t('DashboardEligibilityMark.cfc_check') : t('DashboardEligibilityMark.fn_check')}
      </span>
    </div>
  );
};

export default DashboardEligibilityMark;
