import moment from 'moment';
import _ from 'lodash';
import { toJS } from 'mobx';
import pubNubPost from './pubNubPost';
import { roundNumber } from '../core/helpers';
import appStore from '../mobx-stores/appStore';
import * as debug from '../debug';
import { isMotorhomeOrTouringCaravan } from '~/types/vehicle';
import { canLogVehicleData } from '~/features';
import { log } from '~/debug';

export const getIdentity = (vehicle) => {
  if (appStore.uiState.countryCode === 'de') {
    return vehicle.Make?.toUpperCase();
  }

  return vehicle.CapId && vehicle.CapId !== -1 ? vehicle.CapId : vehicle.GlassId;
};

export function fetchQuotes(quoteeUid, vehicle, quoteRequest, productSettings, loanType, isSendDeal) {
  return new Promise(function(resolve, reject) {
    let vehicleClass;

    if (vehicle.Class === 'bike') {
      vehicleClass = 'motorbike';
    } else if (vehicle.Class && vehicle.Class.toLowerCase() === 'van') {
      vehicleClass = 'lcv';
    } else {
      vehicleClass = vehicle.Class;
    }

    let vatIncluded;

    if (quoteRequest.VatAmount && quoteRequest.VatAmount > 0) {
      vatIncluded = quoteRequest.VehiclePrice !== quoteRequest.BasicPrice;
    }

    let quoteRoute =
      appStore.uiState.canUseLender('CRE') && !loanType
        ? '/v2/quoteware/dealerplatform02'
        : '/v2/quoteware/dealerplatform01';

    if (isSendDeal === true) {
      quoteRoute = '/v2/quoteware/dealerplatform01';
    }

    const vehiclePrice = loanType === 'vapLoan' || loanType === 'negativeEquityLoan' ? 0 : quoteRequest.VehiclePrice;

    let EntityType = null;
    if (quoteRequest.CustomerType && quoteRequest.CustomerType?.toLowerCase() === 'consumer') {
      EntityType = 'PrivateIndividual';
    } else if (quoteRequest.CustomerType && quoteRequest.CustomerType?.toLowerCase() === 'corporate') {
      EntityType = 'Business';
    }

    let CreditTier;

    if (quoteRequest.CustomerCreditScore === undefined) {
      CreditTier = '';
    } else {
      CreditTier = quoteRequest.CustomerCreditScore;
    }

    if (canLogVehicleData()) {
      const vehicleData = Object.assign({}, toJS(vehicle));
      if (isSendDeal) {
        // Delete unnecessary properties when coming from the Customer Profile send deal
        // Otherwise Rapid7 does not accept the massive object
        delete vehicleData.FinanceApplications;
        delete vehicleData.CfcProspects;
        delete vehicleData.FinanceScans;
        delete vehicleData.SavedQuotes;
        delete vehicleData.PushDeals;
        delete vehicleData.DealerNotes;
        delete vehicleData.SortFilters;
        delete vehicleData.SelfServiceCodes;
        // Note: Send deal can also come from stock and then the data looks different
        delete vehicleData.ImagesMetadata;
        delete vehicleData.ImgUrls;
        delete vehicleData.Taxonomies;
        delete vehicleData.ValidationErrorCodes;
        delete vehicleData.VideosMetadata;
        delete vehicleData.PreferredTaxonomy;
      }
      log({
        event: 'VehicleDebugLog-QuoteUtils-FetchQuotes-Vehicle-BeforeFetchingQuotes',
        quoteeUid,
        isSendDeal,
        vehicle: vehicleData,
        identity: getIdentity(vehicle)
      });
    }

    fetch(quoteRoute, {
      body: JSON.stringify({
        Username: 'iVendi',
        QuoteeUid: quoteeUid,
        ProductSettings: productSettings,
        AllowAdjustments: true,
        Class: vehicleClass,
        Condition: vehicle.Condition,
        CurrentOdometerReading: vehicle.Mileage,
        Identity: getIdentity(vehicle),
        Vin: vehicle.Vin,
        PlatformMeta: {},
        IdentityType: 'RVC',
        RegistrationDate: vehicle.RegistrationDate ? moment.utc(vehicle.RegistrationDate, 'DD/MM/YYYY').format() : '',

        RegistrationMark: vehicle.Vrm,
        ResidualValue: quoteRequest.ResidualValue,
        CashDeposit: quoteRequest.CashDeposit,
        Cashback: quoteRequest.Cashback,
        VehiclePrice: vehiclePrice,
        BasicPrice: quoteRequest.BasicPrice,
        VATPercent: quoteRequest.VatPercent,
        VATAmount: quoteRequest.VatAmount,
        VatAddedToDeposit: quoteRequest.VatAddedToDeposit,
        NonVatableItems: quoteRequest.NonVatableItems,
        PartExchange: quoteRequest.PartExchange,
        AnnualDistance: quoteRequest.AnnualDistance,
        Term: quoteRequest.Term,
        OutstandingSettlement: quoteRequest.OutstandingSettlement,
        OutstandingSource: quoteRequest.SettlementSource,
        Insurance: quoteRequest.Insurance,
        Warranty: quoteRequest.Warranty,
        Other: quoteRequest.Other,
        VATincluded: vatIncluded,
        VATQualifying: quoteRequest.VatQualifying,
        ValueAddedProducts: Array.isArray(quoteRequest.LenderVaps)
          ? [...quoteRequest.ValueAddedProducts, ...quoteRequest.LenderVaps]
          : quoteRequest.ValueAddedProducts,
        ExcludeVatAsDeposit: !quoteRequest.VatAddedToDeposit,
        EntityType,
        CreditTier
      }),
      headers: {
        'content-type': 'application/json'
      },
      method: 'POST'
    })
      .then((response) => response.json())
      .then(resolve)
      .catch((e) => {
        debug.error(`fetchQuotes ${e}`);
        reject(e);
      });
  });
}

export function checkEligibility(dealershipId, formData, quotes) {
  return new Promise(function(resolve, reject) {
    let quoteIds = _.chain(quotes)
      .filter((quote) => !quote.Errors)
      .map((quote) => quote.QuoteId)
      .value();

    const addresses = _.map(formData.AddressHistory.Items, (address) => {
      const yearsAtAddress = parseInt(_.get(address, 'TimeAtAddress.Years') || 0, 10);
      const monthsAtAddress = parseInt(_.get(address, 'TimeAtAddress.Months') || 0, 10);

      return {
        Residency: address.Residency,
        BuildingName: address.BuildingName,
        BuildingNumber: address.BuildingNumber,
        Country: address.Country,
        County: address.County,
        District: address.District,
        Postcode: address.Postcode,
        PostTown: address.PostTown,
        Street: address.Street,
        SubBuilding: address.SubBuilding,
        MonthsAtAddress: yearsAtAddress * 12 + monthsAtAddress
      };
    });

    const employments = _.map(formData.EmploymentHistory.Items, (employment) => {
      const yearsAtEmployment = parseInt(_.get(employment, 'TimeAtEmployment.Years') || 0, 10);
      const monthsAtEmployment = parseInt(_.get(employment, 'TimeAtEmployment.Months') || 0, 10);

      return {
        EmploymentBasis: employment.OccupationBasis,
        EmploymentType: employment.OccupationType,
        MonthsAtJob: yearsAtEmployment * 12 + monthsAtEmployment
      };
    });

    const postData = {
      QuoteIds: quoteIds,
      DateOfBirth: formData.PersonalDetails.DateOfBirth,
      Dependants: formData.PersonalDetails.NoOfDependants,
      Firstname: formData.PersonalDetails.FirstName,
      Licence: formData.PersonalDetails.DrivingLicence,
      DrivingLicenceNumber: formData.PersonalDetails.DrivingLicenceNumber,
      MaritalStatus: formData.PersonalDetails.MaritalStatus,
      Surname: formData.PersonalDetails.LastName,
      Title: formData.PersonalDetails.Title,
      Email: formData.PersonalDetails.Email,
      Phone: formData.PersonalDetails.Telephone,
      Employments: employments,
      Addresses: addresses
    };

    pubNubPost(
      `v1/cfcprospect/showroom-check/${dealershipId}`,
      postData,
      'ShowroomCfcEventHandlers/CfcProspectv2Created'
    ).then(resolve, reject);
  });
}

export function compareMonthlyPayments(quoteeUid, vehicle, quoteRequest, productSettingsForSelectedProducts) {
  return new Promise(function(resolve, reject) {
    let vehicleClass;
    if (vehicle.Class === 'bike') {
      vehicleClass = 'motorbike';
    } else {
      vehicleClass = vehicle.Class;
    }

    let vatIncluded;

    if (quoteRequest.VatAmount && quoteRequest.VatAmount > 0) {
      vatIncluded = quoteRequest.VehiclePrice !== quoteRequest.BasicPrice;
    }

    let EntityType = null;
    if (quoteRequest.CustomerType?.toLowerCase() === 'consumer') {
      EntityType = 'PrivateIndividual';
    } else if (quoteRequest.CustomerType?.toLowerCase() === 'corporate') {
      EntityType = 'Business';
    }

    fetch('/v2/quoteware/pricecomparison02', {
      body: JSON.stringify({
        PlatformRequestArgs: {
          Username: 'iVendi',
          QuoteeUid: quoteeUid,
          ProductSettings: productSettingsForSelectedProducts,
          AllowAdjustments: true,
          Class: vehicleClass,
          Condition: vehicle.Condition,
          CurrentOdometerReading: vehicle.Mileage,
          Identity: getIdentity(vehicle),
          Vin: vehicle.Vin,
          PlatformMeta: {},
          IdentityType: 'RVC',
          RegistrationDate: vehicle.RegistrationDate
            ? moment(vehicle.RegistrationDate, 'DD/MM/YYYY').format()
            : moment().format(),
          RegistrationMark: vehicle.Vrm,
          ResidualValue: quoteRequest.ResidualValue,
          CashDeposit: quoteRequest.CashDeposit,
          VehiclePrice: quoteRequest.VehiclePrice,
          BasicPrice: quoteRequest.BasicPrice,
          VATPercent: quoteRequest.VatPercent,
          VATAmount: quoteRequest.VatAmount,
          VatAddedToDeposit: quoteRequest.VatAddedToDeposit,
          NonVatableItems: quoteRequest.NonVatableItems,
          PartExchange: quoteRequest.PartExchange,
          AnnualDistance: quoteRequest.AnnualDistance,
          Term: quoteRequest.Term,
          OutstandingSettlement: quoteRequest.OutstandingSettlement,
          OutstandingSource: quoteRequest.SettlementSource,
          Insurance: quoteRequest.Insurance,
          Warranty: quoteRequest.Warranty,
          Other: quoteRequest.Other,
          VATincluded: vatIncluded,
          VATQualifying: quoteRequest.VatQualifying,
          ValueAddedProducts: quoteRequest.ValueAddedProducts,
          ExcludeVatAsDeposit: !quoteRequest.VatAddedToDeposit,
          EntityType
        },
        Terms: [12, 18, 24, 27, 30, 33, 36, 39, 42, 45, 48, 51, 54, 57, 60],
        Deposits: [parseInt(roundNumber(quoteRequest.CashDeposit, 0), 10)],
        AnnualMileages: [quoteRequest.AnnualDistance]
      }),
      headers: {
        'content-type': 'application/json'
      },
      method: 'POST'
    })
      .then((response) => response.json())
      .then((productsArray) => {
        const result = {};

        _.each(productsArray, (product) => {
          if (product) {
            const payments = {};
            _.each(product.Payments, function(payment, term) {
              payments[term] = payment;
            });

            result[product.ProductId] = payments;
          }
        });
        resolve(result);
      })
      .catch(reject);
  });
}

export const isBalloonAdjusted = (quoteRequest, quotes) => {
  const balloonResponse = quotes?.find((quote) => quote.Balloon)?.Balloon;
  return balloonResponse && balloonResponse !== quoteRequest.ResidualValue;
};

export const getLenderVapValue = (lenderVaps, name) => {
  if (name === 'GAP') {
    return lenderVaps?.find((vap) => vap.Name === 'GAP' || vap.Name === 'GAPPlus')?.Name ?? '';
  }

  if (name === 'RSV') {
    return lenderVaps?.find((vap) => vap.Name === 'RSV' || vap.Name === 'RSVPlus')?.Name ?? '';
  }

  throw new Error(`Unknown VAP name: ${name}`);
};

export const hasExtendedTermLength = (vehicleClass) => isMotorhomeOrTouringCaravan(vehicleClass);
