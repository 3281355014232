import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import QuoteModuleContainer from '../../Quoting/containers/QuoteModuleContainer';
import { QuoteModuleVariantType } from '~/components/Quoting/components/QuoteModule/types';
import { withOutletContextProps, withParams, withNavigate } from '../../../hocs/router';

class EditApplicationQuotePreSubmission extends React.Component {
  handleCancel = () => {
    this.props.navigate(`/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}`);
  };

  render() {
    const { dealershipId, consumerId } = this.props.params;
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('EditApplicationQuotePreSubmission.home'),
              path: `/d/${dealershipId}`
            },
            {
              name: this.props.t('EditApplicationQuotePreSubmission.customer_list'),
              path: `/d/${dealershipId}/consumers`
            },
            {
              name: this.props.t('EditApplicationQuotePreSubmission.consumer'),
              path: `/d/${dealershipId}/consumers/${consumerId}`
            },
            {
              name: this.props.t('EditApplicationQuotePreSubmission.edit_quote')
            }
          ]}
          consumer={this.props.consumer}
        />
        <QuoteModuleContainer
          variant={QuoteModuleVariantType.ApplicationQuotePreSubmission}
          vehicle={this.props.application.Vehicle}
          initialCosts={this.props.application.QuoteRequest}
          onCancel={this.handleCancel}
          onProceed={this.props.changeApplicationQuotePreSubmission}
          proceedingState={this.props.application.submittingState}
          customerEmail={this.props.consumer.Email}
          enableShowroomCfc={this.props.enableShowroomCfc}
          assignees={this.props.assignees}
          customerType={this.props.application.CustomerType}
          customerId={consumerId}
        />
      </Page>
    );
  }
}

EditApplicationQuotePreSubmission.propTypes = {
  params: PropTypes.object,
  application: PropTypes.object,
  consumer: PropTypes.object,
  vehicle: PropTypes.object,
  changeApplicationQuotePreSubmission: PropTypes.func,
  enableShowroomCfc: PropTypes.bool,
  assignees: PropTypes.array
};

export default compose(
  withParams,
  withNavigate,
  withOutletContextProps,
  withTranslation('Application')
)(EditApplicationQuotePreSubmission);
