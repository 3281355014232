import { CustomerFinanceApplication } from '~/types/application';
import { QuoteCardVariantType, QuoteTypes } from '../types';
import configs from './variants';
import defaultConfig from './default';

type GetQuoteCardConfigProps = {
  quote: QuoteTypes;
  variant: QuoteCardVariantType;
  application?: CustomerFinanceApplication;
};
export function getQuoteCardConfig({ variant, quote, application }: GetQuoteCardConfigProps) {
  try {
    // This handles a situation in tests where we don't have a variant
    return configs[variant]({ quote, application });
  } catch (e) {
    return defaultConfig;
  }
}
