import objectValidation from '~/core/objectValidation';
import {
  FinanceNavigatorForm,
  FinanceNavigatorFormAddressHistory,
  FinanceNavigatorFormAffordabilityDetails,
  FinanceNavigatorFormEmploymentHistory,
  FinanceNavigatorFormPersonalDetails
} from '../types/financeNavigatorForm';
import { useCallback } from 'react';

export type ResolverResult = {
  values: Record<string, any>;
  errors: Record<string, any>;
  isValid: boolean;
};

export const rhfValidationResolver = (data: any, validationRules: Record<string, string>): ResolverResult => {
  const errors = objectValidation(data, validationRules);
  return !errors ? { values: data, errors: {}, isValid: true } : { errors, values: {}, isValid: false };
};

export const useRhfValidationResolver = (validationRules: Record<string, string>) =>
  useCallback(async (data) => rhfValidationResolver(data, validationRules), [validationRules]);

export type FinanceNavigatorValidator<T> = Partial<Record<keyof T, string | any>>;

export const personalDetailsValidator: FinanceNavigatorValidator<FinanceNavigatorFormPersonalDetails> = {
  Title: 'required',
  FirstName: 'required, alphanumericWithDigitsPunctuationAndSpaces, minlength:2, maxlength:25',
  MiddleNames: 'alphanumericWithDigitsPunctuationAndSpaces, minlength:2, maxlength:40',
  LastName: 'required, alphanumericWithDigitsPunctuationAndSpaces, minlength:2, maxlength:40',
  Email: 'required, email, minlength:6, maxlength:100',
  MobileTelephone: 'required, mobile',
  DateOfBirth: 'required, date, youngerThan99, olderThan18',
  MaritalStatus: 'required',
  NoOfDependants: 'required'
};

export const addressHistoryValidator: FinanceNavigatorValidator<FinanceNavigatorFormAddressHistory> = {
  Items: [
    {
      BuildingName:
        'required:unless:BuildingNumber, doesNotStartWithSpecialCharacter, extendedLenderAlphanumeric, doesNotStartOrEndWithSpace, maxlength:100',
      BuildingNumber:
        'required:unless:BuildingName, doesNotStartWithSpecialCharacter, extendedLenderAlphanumeric, doesNotStartOrEndWithSpace, maxlength:100',
      Street:
        'required, doesNotStartWithSpecialCharacter, lenderAlphanumeric, doesNotStartOrEndWithSpace, maxlength:100',
      District: 'alphaAndWhitespace, maxlength:100',
      PostTown: 'required, lenderAlphanumeric, maxlength:100',
      Postcode: 'required, alphaNumSpace, maxlength:8',
      Residency: 'required',
      TimeAtAddress: {
        Years: 'required, number:int, maxlength:3',
        Months: 'required, number:int, max:12'
      }
    }
  ]
};

export const employmentHistoryValidator: FinanceNavigatorValidator<FinanceNavigatorFormEmploymentHistory> = {
  Items: [
    {
      OccupationBasis: 'required',
      OccupationType: 'required',
      TimeAtEmployment: {
        Years: 'required, number:int, maxlength:3',
        Months: 'required, number:int, max:12'
      }
    }
  ]
};

export const affordabilityDetailsValidator: FinanceNavigatorValidator<FinanceNavigatorFormAffordabilityDetails> = {
  Income: {
    GrossAnnual: 'required, currency, minlength:1, maxlength:37',
    NetMonthly: 'required, currency',
    HasOtherHouseholdIncome: 'optional',
    OtherHouseholdIncome: 'required:iftrue:HasOtherHouseholdIncome, currency'
  },
  PersonalCircumstances: {
    ForeseeFutureDownturnInFinances: 'required',
    DownturnReason: 'required:iftrue:ForeseeFutureDownturnInFinances',
    ForeseenYearlyIncome: 'required:iftrue:ForeseeFutureDownturnInFinances, currency'
  },
  ReplacementLoan: {
    IsReplacementLoan: 'required',
    DetailsOfReplacementLoan: 'required:iftrue:IsReplacementLoan, currency'
  },
  MonthlyExpenditure: {
    MortgageOrRental: 'required, currency, minlength:1, maxlength:37',
    Other: 'required, currency, minlength:1, maxlength:37'
  }
};

export const applicationValidator: FinanceNavigatorValidator<FinanceNavigatorForm> = {
  PersonalDetails: personalDetailsValidator,
  AddressHistory: addressHistoryValidator,
  EmploymentHistory: employmentHistoryValidator,
  AffordabilityDetails: affordabilityDetailsValidator
};
