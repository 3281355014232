export const isBike = (klass:string) => {
  return klass && transformVehicleClass(klass) === 'motorbike';
};
export const isVan = (klass: string) => {
  return klass && transformVehicleClass(klass) === 'lcv';
};

// vrm lookup, edit vehicle endpoints return different vehicle classes for the same vehicle.
export const transformVehicleClass = (_klass = '') => {
  if (!_klass) return 'unknown';

  const klass = _klass.toLowerCase();

  switch (klass) {
    case 'bike':
    case 'motorbike':
      return 'motorbike';
    case 'lcv':
    case 'van':
      return 'lcv';
    case 'car':
      return 'car';
    case 'motorhome':
      return 'motorhome';
    case 'unknown':
      return 'unknown';
    default:
      console.error(`Bad vehicle class received: ${klass}`);
      return 'unknown';
  }
};

