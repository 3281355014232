import platformApiUtils from '@ivendi/platform-api-utils';
import mitt from 'mitt';
import appStore from '../mobx-stores/appStore';
import { getSessionId } from '../sessionId';
import { useEffect } from 'react';
import Pubnub, { CryptoModule, MessageEvent } from 'pubnub';
import { FixLater } from '~/types/basic';

let dealershipEventEmitter = mitt();
let debugCallback: (msg: FixLater) => void;
let pubNubConnection: Pubnub;
let sessionId = getSessionId();

function subscribe(authKey: string, dealership_id: string, UserId: string) {
  platformApiUtils
    .get<{ PublishKey: string; SubscribeKey: string }>('v1/pubnub/public-keys')
    .then((keys) => {
      log({ type: 'PubNubInit', authKey, sessionId, level: 'Info' });
      pubNubConnection = new Pubnub({
        publishKey: keys.PublishKey,
        subscribeKey: keys.SubscribeKey,
        cipherKey: getCipherKeyForEnv(),
        authKey: authKey,
        ssl: true,
        userId: UserId,
        logVerbosity: true,
        cryptoModule: CryptoModule.legacyCryptoModule({ cipherKey: getCipherKeyForEnv(), useRandomIVs: false })
      });

      const onSubscribe = () => {
        get_convert_quote_history(keys.SubscribeKey, dealership_id);
      };

      subscribeToChannels([dealership_id, 'd.analytics.' + dealership_id], onSubscribe);
      appStore.dashboardQuotesStore.initFetchData();
    })
    .catch((_) => {
      log({
        type: 'PubNubError',
        error: 'Failed to fetch public keys',
        sessionId,
        level: 'Error'
      });
    });
}

function getCipherKeyForEnv() {
  switch (appStore.uiState.countryCode!) {
    case 'de':
      return 'cKNEQzuN79Y-nu4*33a!DXubJpMZVU.h84okECwwy_6mn-TCzd';
    default:
      return '7fchKvxhAGDy8/]4z)yCuBxFdu*eY}cPjGZhEjnJYQT9Y3rV@M';
  }
}

async function get_convert_quote_history(subscribe_key: string, dealership_id: string) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  log({
    type: 'RequestConvertQuoteHistory',
    sessionId,
    level: 'Info'
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ dealership_id: dealership_id }),
    redirect: 'follow'
  };
  const res = await fetch(
    'https://ivendi.pubnub.com/v1/blocks/sub-key/' + subscribe_key + '/convert_quote_history',
    requestOptions
  );

  try {
    if (!res.ok) {
      throw new Error(res.status.toString());
    }
  } catch (err) {
    console.error(err);
  }
}

function subscribeToChannels(channels: string[], subscribeCallback: () => void) {
  log({ type: 'PubNubSubscribe', channel: channels.toString(), sessionId, level: 'Info' });

  pubNubConnection.addListener({
    message: (message) => onMessage(message, channels),
    status: (status) => {
      switch (status.operation) {
        case 'PNSubscribeOperation':
        case 'PNUnsubscribeOperation':
          // note: subscribe statuses never have traditional
          // errors, they just have categories to represent the
          // different issues or successes that occur as part of subscribe
          switch (status.category) {
            case 'PNConnectedCategory':
              // this is expected for a subscribe, this means there is no error or issue whatsoever
              log({
                type: 'PubNubConnected',
                channel: status.affectedChannels,
                sessionId,
                level: 'Info'
              });
              subscribeCallback();
              break;
            case 'PNReconnectedCategory':
              // this usually occurs if subscribe temporarily fails but reconnects. This means
              // there was an error but there is no longer any issue
              log({
                type: 'PubNubReconnected',
                channel: status.affectedChannels,
                sessionId,
                level: 'Info'
              });
              break;
            case 'PNDisconnectedCategory':
              // this is the expected category for an unsubscribe. This means there
              // was no error in unsubscribing from everything
              log({
                type: 'PubNubDisconnected',
                channel: status.affectedChannels,
                sessionId,
                level: 'Info'
              });
              break;
            case 'PNUnexpectedDisconnectCategory':
              // this is usually an issue with the internet connection, this is an error, handle appropriately
              log({
                type: 'PubNubDisconnected',
                channel: status.affectedChannels,
                sessionId,
                level: 'Info'
              });
              break;
            case 'PNAccessDeniedCategory':
              // this means that PAM does allow this client to subscribe to this
              // channel and channel group configuration. This is another explicit error
              log({
                type: 'PubNubError',
                channel: status.affectedChannels,
                error: status.category,
                sessionId,
                level: 'Info'
              });
              dealershipEventEmitter.emit('PubNubError', status.category);
              break;
            default:
              // More errors can be directly specified by creating explicit cases for other
              // error categories of `PNStatusCategory` such as `PNTimeoutCategory` or `PNMalformedFilterExpressionCategory` or `PNDecryptionErrorCategory`
              dealershipEventEmitter.emit('PubNubError', status.category);
              log({
                type: 'PubNubError',
                channel: status.affectedChannels,
                error: status.category,
                sessionId,
                level: 'Error'
              });
          }
          break;
        default:
          return null;
      }
    }
  });

  pubNubConnection.subscribe({
    channels: channels
  });
}

function onMessage(rawMessage: MessageEvent, defaultChannels: string[]) {
  const message = parseMessage(rawMessage);

  if (message && message.Header) {
    let payload;

    try {
      payload = JSON.stringify(message.Payload);
    } catch (e) {
      payload = message.Payload;
    }

    log({
      rawMessage: message,
      type: 'PubNubMessage',
      channel: rawMessage.channel,
      header: message.Header,
      cid: message.CorrelationId,
      payload,
      sessionId,
      level: 'Info'
    });

    if (defaultChannels.includes(rawMessage.channel)) {
      dealershipEventEmitter.emit(message.Header, message);
    } else {
      dealershipEventEmitter.emit(rawMessage.channel, message);
    }
  } else {
    log({
      type: 'PubNubMessageError',
      channel: rawMessage.channel,
      error: 'Missing message header!',
      message,
      sessionId,
      level: 'Error'
    });

    dealershipEventEmitter.emit('error', new Error('Missing message header!'));
  }
}

function parseMessage(rawMessage: MessageEvent) {
  let parsedMessage;

  if (typeof rawMessage.message === 'string') {
    try {
      parsedMessage = JSON.parse(rawMessage.message);
    } catch (e) {
      parsedMessage = rawMessage.message;
    }
  } else {
    parsedMessage = rawMessage.message;
  }

  return parsedMessage;
}

function unsubscribe() {
  log({
    type: 'PubNubUnsubscribeAll',
    sessionId,
    level: 'Info'
  });

  dealershipEventEmitter.all.clear();

  if (pubNubConnection) {
    pubNubConnection.unsubscribeAll();
  }
}

function debug(callback: (msg: FixLater) => void) {
  debugCallback = callback;
}

function log(logObject: FixLater) {
  if (debugCallback) {
    debugCallback(logObject);
  }
}

export const usePubnubChannel = (channel: string, onMessage: (message: unknown) => void) => {
  useEffect(() => {
    if (!channel) return;
    const handler = (msg: FixLater) => {
      onMessage(msg.Payload);
    };
    pubNubConnection.subscribe({ channels: [channel] });
    dealershipEventEmitter.on(channel, handler);
    return () => {
      dealershipEventEmitter.off(channel, handler);
      pubNubConnection.unsubscribe({ channels: [channel] });
    };
  }, [channel, onMessage]);
};

export default { ...dealershipEventEmitter, log, debug, subscribe, unsubscribe };
